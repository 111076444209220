import { IStage } from "../../model/models"
import { IconButton } from "../Icon"

interface StageRowProps {
    stage: IStage
    index: number
    onClick?: () => void
    onDelete?: () => void
}

export function StageRow({ stage, index, onClick, onDelete }: StageRowProps) {
    return (
        <div className="flex flex-row items-center itemrow" 
            onClick={onClick}>
            <div className="flex flex-col flex-auto" >
                <span className="text-lg">#{index + 1} {stage.title}</span>
                <span className="line-clamp-3 text-disabled">{stage.description}</span>
            </div>
            { onDelete &&
            <IconButton name="delete" onClick={onDelete}/>
            }
        </div>
    )
}