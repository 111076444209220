import { useNavigate } from "react-router-dom"
import { IContest, IStage, dbid } from "../../model/models"
import { useState } from "react"
import { useModal } from "../../hooks/useModal"
import strings from "../../strings"
import DataService from "../../services/DataService"
import { AxiosError } from "axios"
import paths from "../../paths"
import { EditImageField } from "../../comp/fields/EditImageField"
import { TextField } from "../../comp/TextField"
import { LabelWith } from "../../comp/Label"
import { DescrView } from "../../comp/DescrView"
import { CreateContest } from "../../comp/forms/CreateContest"
import { Modal } from "../../comp/Modal"
import { CreateStageForm } from "../../comp/forms/CreateStageForm"
import { StageRow } from "../../comp/rows/StageRow"
import { ListItemsReorder } from "../../comp/ListItems"

// const PRELIMINARY_STAGE = { 
//     title: "Предварительная стадия",
//     description: ""
// } as IStage

interface ContestDescrPageProps {
    contest: IContest
    contestId: dbid
    setContest: (nextContest: IContest) => void
}

export function ContestDescrPage({ contest, contestId, setContest }: ContestDescrPageProps) {
    const navigate = useNavigate()
    const [error, setError] = useState<string>()
    const editModal = useModal<IContest>()
    const stageModal = useModal<IStage>()
    
    async function togglePublishedHandler() {
        if (!contest || !contest.isAdmin)
            return
        if (!global.confirm(contest.isPublished ? strings.state_unpublish : strings.state_publish + "?"))
            return
        try {
            const data =  { isPublished: !contest.isPublished } as IContest
            const resp = await DataService.saveContest(contest.id, data)
            setContest(resp.data)
        } catch (e) {
            console.error(e)
            const err = e as AxiosError
            setError(err.message || "Can't delete contest!")
        }        
    }

    function saveHandler(nextContest: IContest) {
        setContest(nextContest)
        editModal.closeModal()
    }
    async function updateStages(nextStages: IStage[]) {
        try {
            const resp = await DataService.updateStages(contestId, nextStages)
            setContest(resp.data)
        } catch (e) {
            console.error(e)
        }
    }
    function reorderStagesHandler(nextStages: IStage[]) {
        if (global.confirm("Сохранить новый порядок стадий конкурса?"))
            updateStages(nextStages)
    }
    async function saveStageHandler(nextStage: IStage) {
        stageModal.closeModal()
        if (nextStage.id) {
            const nextStages = contest.stages.map( stage => stage.id === nextStage.id ? nextStage : stage)
            updateStages(nextStages)
        } else {
            try {
                const resp = await DataService.createStage(contestId, nextStage)
                let nextStages = contest.stages ? [...contest.stages, resp.data] : [resp.data]
                let nextContest = {...contest, stages: nextStages } as IContest
                setContest(nextContest)
            } catch(e) {
                console.error(e)
            }
        }
    }
    
    function deleteStageHandler(deleteStage: IStage) {
        if (!global.confirm("Удалить стадию конкурса?"))
            return
        updateStages(contest.stages.filter( stage => stage.id !== deleteStage.id))
    }

    async function uploadLogo(nextLogo: string) {
        if (!contest) {
            return console.error("Empty contextId")            
        }
        try {
            const data = {...contest, logo: nextLogo } as IContest
            const resp = await DataService.saveContest(data.id, data)
            setContest(resp.data)
        } catch(e) {
            console.error(e)
        }
    }

    async function deleteHandler() {
        if (global.confirm(strings.confirm_delete_contest)) {
            try {
                setError("")
                const resp = await DataService.deleteContest(contestId)
                if (resp.statusText === 'OK') {
                    navigate(paths.dashboard)
                } else {
                    setError(resp.data?.message || "Can't delete contest!")
                }    
            } catch(e) {
                console.error(e)
                const err = e as AxiosError
                setError(err.message || "Can't delete contest!")
            }
        }
    }
    async function setEmailHandler() {
        if (contest.email) {
            if (global.confirm("Удалить email адрес для примема заявок?")) {
                try {
                    const resp = await DataService.deleteEmail(contest.id)
                    if (resp.statusText === "OK") {
                        setContest({...contest, email: undefined})
                    }
                } catch(e) {

                }
            }
        } else {
            const email = global.prompt("Введите желаемый email адрес для примема заявок. Длиной более 4 символов содержащий только цифры, латинские буквы или символы \"-\",\"_\"\".\"",
                contest.email || ""
            )
            if (email) {
                try {
                    const emailFixed = email.trim().toLowerCase()
                    const resp = await DataService.setEmail(contest.id, emailFixed)
                    if (resp.statusText === "OK") {
                        setContest({...contest, email: emailFixed})
                    }
                } catch(e) {
                    
                }
            }
        }
    }
    return (
        <>
        <div className="flex flex-row gap-x-2">
            <EditImageField label={strings.lab_logo} 
                className="w-1/2"
                contestId={contestId}
                value={contest.logo}
                onChange={uploadLogo}
            />
            <div className="flex flex-col w-1/2">
                <TextField label={strings.lab_title} text={contest.title}/>

                <LabelWith text={strings.lab_descr}>
                    <DescrView className="line-clamp-10 textfield" body={contest.description}/>
                </LabelWith>

                <TextField className="flex-auto"
                    label={strings.lab_contest_email} 
                    text={contest.email ? contest.email + "@cityawards.ru" : undefined} 
                    emptyPlaceholder={strings.msg_missing}
                    buttonIcon="edit"
                    buttonOnClick={setEmailHandler}/>
                
                <LabelWith text={strings.lab_user_status}>
                { contest.isAdmin
                ? <div className="textfield">{strings.lab_user_status_admin}</div> 
                : contest.isParticipating 
                ? <div className="textfield">{strings.lab_user_status_contestant}</div>
                : <div className="textfield">{strings.lab_user_status_none}</div>
                }
                </LabelWith>
            </div>
        </div>

        { contest.isAdmin && 
        <div className="flex flex-wrap gap-2 pt-2 justify-end">
            <button className="textbutton" onClick={() => navigate(paths.edit_presentation(contestId))}>
                {strings.nav_presentation}
            </button>
            <button className="textbutton" onClick={() => editModal.openModal(contest)}>
                {strings.mi_edit}
            </button>
            <button className="greenbutton" onClick={togglePublishedHandler} >
                { contest.isPublished ? strings.state_unpublish : strings.state_publish }
            </button>
            <button className="redbutton" onClick={deleteHandler}>
                {strings.mi_delete}
            </button>
        </div>
        }
        <LabelWith text={strings.lab_stages} className="py-big ">
        { contest.isAdmin &&
        <div className="flex flex-row justify-end">
            <button className="textbutton" 
                onClick={() => stageModal.openModal({} as IStage)}
                >{strings.button_add_stage}</button>
        </div>
        }
        <ListItemsReorder items={contest.stages} 
            onReorder={reorderStagesHandler}
            getKey={ item => item.id }
            render={ (stage, index) => 
                <StageRow key={stage.id} 
                    stage={stage} index={index}
                    onClick={() => stageModal.openModal(stage)}
                    onDelete={() => deleteStageHandler(stage)}/>
            }/>
        
        </LabelWith>
        { editModal.modal && 
        <Modal title={strings.dlg_title_edit_contest} onClose={editModal.closeModal}>
            <CreateContest contest={editModal.modal} 
                onSave={saveHandler} 
                onCancel={editModal.closeModal}
                />
        </Modal>
        }
        { stageModal.modal && 
        <Modal title="" onClose={stageModal.closeModal}>
            <CreateStageForm stage={stageModal.modal} 
                onSave={saveStageHandler}
                onCancel={stageModal.closeModal}
                />
        </Modal>
        }
        </>
    )
}