import { useCallback, useState } from "react";
import { ContextMenu, ContextMenu2, IContextMenu, getContextMenuPtFromEvent } from "../../ContextMenu";
import { indexToAbc } from "../models";
import useResizeObserver from "../../../hooks/useResizeObserver";
import { IPoint } from "../../../model/common";

interface FixedColCellProps {
    column?: number
    width?: number
    menu?: IContextMenu<number>
    className?: string
    onClick?: () => void
    onResize?: (column: number, width: number) => void
}

export function FixedColCell({ column, menu, width, className, onClick, onResize }: FixedColCellProps) {
    // const [ showMenu, setShowMenu ] = useState(false)
    const [ showMenuPt, setShowMenuPt ] = useState<IPoint>()

    const onResizeCallback = useCallback((target: HTMLTableCellElement) => {
        // Handle the resize event
        // console.log("resize: ", target.offsetWidth)
        if (column !== undefined && onResize && target.offsetWidth !== width) {
            console.log("Resize col:" + column)
            onResize(column, target.offsetWidth)
        }
      }, []);
    const ref = useResizeObserver(onResizeCallback)

    function onContextMenuHandler(e: React.MouseEvent<HTMLTableCellElement>) {
        console.log(`contextmenu for column ${column}`)
        e.stopPropagation()
        e.preventDefault()
        let pt = getContextMenuPtFromEvent(e)
        setShowMenuPt(pt)
        // setShowMenu(!showMenu)
    }
    if (column === undefined) {
        return (
            <th className={"excel-td-fixed excel-first-row excel-first-col box-border " + (className || "")}
                style={{ width: width || "40px" }}
            ></th>
        );
    } else {
        return (
            <th className={"excel-td-fixed excel-first-row excel-other-col box-border " + (className || "")}
                ref={ref}
                style={{
                    width: width,
                    // minWidth: "40px"
                }}
                onContextMenu={onContextMenuHandler}
                onClick={onClick}>
                {indexToAbc(column)}
                { menu && showMenuPt && 
                <ContextMenu2 menu={menu} item={column} pt={showMenuPt}
                    onClose={() => setShowMenuPt(undefined)}/>
                }
            </th>
        );
    }
}
