import { useState } from "react"

export interface IUseModal<T> {
    modal: T | null
    openModal: (item: T) => void
    closeModal: () => void 
}

export function useModal<T>(): IUseModal<T> {
    const [modal, setModal] = useState<T | null>(null)

    function openModal(next: T) {
        setModal(next)
    }

    function closeModal() {
        setModal(null)
    }

    return {
        modal, openModal, closeModal
    } as IUseModal<T>
}