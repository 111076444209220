export type Color = string// | number 

export interface IPoint {
    x: number
    y: number
}

export interface IRect {
    x: number
    y: number
    w: number
    h: number
}

export interface ISizes<T> {
    left?: T
    top?: T
    right?: T
    bottom?: T
}

export type IPadding = ISizes<number>
export type IColors = ISizes<Color>

export enum AlignTextField {
    left = "left", 
    center = "center", 
    right = "right", 
    justify = "justify"
}

export enum VerticalAlignField {
    start = "start",
    center = "center",
    end = "end"
}

export function verticalAlignCss(verticalAlign?: VerticalAlignField) {
    switch(verticalAlign) {
        case VerticalAlignField.center: return "middle"
        case VerticalAlignField.end: return "bottom"
        case VerticalAlignField.start:
        default: return "top"
    }
}

export enum BorderLineStyle {
    solid = "solid",
    dots = "dotted",
    dash = "dashed"
}

export interface IBorder {
    style?: BorderLineStyle
    color?: Color
    width?: number
}

export enum ImageScale {
    auto = "auto",
    cover = "cover",
    contain = "contain",
    fit = "fit",
    // crop = "crop"
}

export function imageScaleToCssBackgroundSize(scale: ImageScale) {
    return scale === ImageScale.fit ? "100% 100%" : scale
}

export enum ImageAlign {
    center = "center",
    left = "left", 
    right = "right",
    top = "top", 
    bottom = "bottom",
    topLeft = "topLeft",
    topRight = "topRight",
    bottomLeft = "bottomLeft",
    bottomRight = "bottomRight",
    custom = "custom"
}
export function imageAlignToCssBackgroundPosition(align: ImageAlign, pos: IPoint | undefined): string {
    switch (align) {
        case ImageAlign.center:
        case ImageAlign.left:
        case ImageAlign.right:
        case ImageAlign.top:
        case ImageAlign.bottom:
            return align
        case ImageAlign.topLeft:
            return "top left"
        case ImageAlign.topRight:
            return "top right"
        case ImageAlign.bottomLeft:
            return "bottom left"
        case ImageAlign.bottomRight:
            return "bottom right"
        case ImageAlign.custom: 
            return pos ? `${pos.x}% ${pos.y}%` : "top left"
            // return pos ? `${pos.x}px ${pos.y}px` : "top_left"
    }
}