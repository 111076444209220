import { Label } from "../comp/Label";
import strings from "../strings";
import { Icon } from "./Icon";

interface TextFieldProps {
    label: string
    text: string | undefined
    emptyPlaceholder?: string 
    multiline?: boolean
    className?: string
    buttonIcon?: string
    buttonOnClick?: () => void
}

export function TextField({ label, text, multiline, className, emptyPlaceholder, buttonIcon, buttonOnClick }: TextFieldProps) {
    // const el = multiline ? p : span
    return (
        <div className={ "relative flex flex-col " + (className || "")}>
            <Label text={label}/>
            
            {multiline 
            
            ? text 
                ? <p style={{ whiteSpace: "pre-line" }}>{text}</p>
                : emptyPlaceholder 
                ? <p className="textfield text-disabled select-none">{emptyPlaceholder}</p>
                : <p className="textfield text-disabled select-none">{`${strings.msg_missing} ${label.toLowerCase()}`}</p>

            : text 
                ? <span className="textfield">{text}</span>
                : emptyPlaceholder 
                ? <span className="textfield text-disabled select-none">{emptyPlaceholder}</span>
                : <span className="textfield text-disabled select-none">{`${strings.msg_missing} ${label.toLowerCase()}`}</span>
            }

            { buttonIcon && 
            <div className={"absolute right-[4px] bottom-[2px] flex flex-row justify-center items-center py-2 " + (buttonOnClick ? "cursor-pointer" : "")}
                onClick={buttonOnClick}>
                <Icon name={buttonIcon} className="bg-transparent border-0"/>
            </div>
            }
        </div>
    )
}
