import { IContextMenuItem } from "../comp/ContextMenu"
import strings from "../strings"
import { IVoteResult, TemplateFieldType } from "./models"
import { IDataIDs, IVoteData, TYPE_CONTEST, TYPE_ENTITY, TYPE_NOMINATION, TocData, scopes } from "./query"

export interface DependField {
    name: string
    image: string
}

export class DependsDataContext {
    name: string
    toc: TocData
    text = new Array<DependField>()
    images = new Array<DependField>()
    gallery = new Array<DependField>()

    constructor(name: string, toc: TocData) {
        this.name = name
        this.toc = toc
    }

    addText(name: string, s: any) {
        if (s && typeof s === "string")
            this.text.push({name, image: s })
    }

    addImage(name: string, image: any) {
        if (image && typeof image === "string") {
            this.images.push({ name, image })
        }
    }

    addGallery(name: string, image: any) {
        if (image && typeof image === "string") {
            this.gallery.push({ name, image })
        }
    }

    depend(id: string) {
        let obj = this.toc.getById(id)
        if (!obj)
            return
        this.addText(strings.depend_filename, this.name)
        if (id.startsWith(TYPE_CONTEST)) {
            this.addText(strings.depend_context_title, obj.title)
            this.addText(strings.depend_context_descr, obj.description)
            this.addImage(strings.depend_context_logo, obj.logo)
        } else if (id.startsWith(TYPE_NOMINATION)) {
            this.addText(strings.depend_nomination_title, obj.title)
            this.addText(strings.depend_nomination_descr, obj.description)
        } else if (id.startsWith(TYPE_ENTITY)) {
            this.addText(strings.depend_entity_title, obj.title)
            this.addText(strings.depend_entity_descr, obj.description)

            let fields = obj.fields
            fields && this.toc.contest.template?.fields?.forEach( fld => {
                let type = fld.type || TemplateFieldType.TEXT
                if (type === TemplateFieldType.TEXT
                    || type === TemplateFieldType.ENUM) {
                    this.addText(fld.label, fields[fld.name])
                } else if (type === TemplateFieldType.IMAGE) {
                    this.addImage(fld.label, fields[fld.name])
                } else if (type === TemplateFieldType.IMAGES) {
                    let images = fields[fld.name]
                    if (typeof images === "string") {
                        this.addGallery(fld.label, images)
                        images?.split("\n")?.forEach( (img, n) => 
                            this.addImage(`${fld.label} ${n + 1}`, img)
                        )
                    }
                }
            })
        }
    }

    depends(depends: IDataIDs) {
        Object.values(depends).forEach( m => {
            m && this.depend(m)
        })
    } 

    dependType(type: string) {
        this.addText(strings.depend_filename, `{title}`)
        if (type === TYPE_CONTEST) {
            this.addText(strings.depend_context_title, `{${type}.title}`)
            this.addText(strings.depend_context_descr, `{${type}.description}`)

        } else if (type === TYPE_NOMINATION) {
            this.addText(strings.depend_nomination_title, `{${type}.title}`)
            this.addText(strings.depend_nomination_descr, `{${type}.description}`)

        } else if (type === TYPE_ENTITY) {
            this.addText(strings.depend_entity_title, `{${type}.title}`)
            this.addText(strings.depend_entity_descr, `{${type}.description}`)
            this.toc.contest.template?.fields?.forEach( fld => {
                let fldType = fld.type || TemplateFieldType.TEXT
                if (fldType === TemplateFieldType.TEXT || fldType === TemplateFieldType.ENUM) {
                    this.addText(fld.label, `{${type}.fields.${fld.name}}`)
                } else if (fldType === TemplateFieldType.IMAGE) {
                    this.addImage(fld.label, `{${type}.fields.${fld.name}}`)
                } else if (fldType === TemplateFieldType.IMAGES) {
                    this.addImage(fld.label, `{${type}.fields.${fld.name}}`)
                    this.addGallery(fld.label, `{${type}.fields.${fld.name}}`)
                }
            })            
        }
    }

    dependsType(types: string[]) {
        types.forEach( type => 
            this.dependType(type)
        )
    }

    dependsVoteResult(voteData: IVoteData, withResult: boolean) {
        this.addText(`Результат голосования`, "{totalVotes}")
        voteData.jury.forEach( item => {
            let scopeId = scopes.fromJuryId(item.id)
            this.addText(`Имя "${item.name}"`, `{votes.${scopeId}.name}`)
            withResult && this.addText(`Голос "${item.name}"`, `{votes.${scopeId}.value}`)
        })
    }

    toMenu(items: DependField[]) {
        return items.map(img => {return { id: img.image, title: img.name } as IContextMenuItem})
    }
}
