import { useState } from "react"
import { EditBorderTab } from "./EditBorderTab"
import { TabsView } from "../../../comp/TabsView"
import { ISlide, ISlideField } from "../../../model/pptx"
import strings from "../../../strings"
import { EditGalleryTab } from "./EditGalleryTab"
import { EditImageTab } from "./EditImageTab"
import { EditTextFieldTab } from "./EditTextFieldTab"
import { EditPaddingTab } from "./EditPaddingTab"
import { DependsDataContext } from "../../../model/depends"

interface EditFieldFormProps {
    field: ISlideField
    isTemplate: boolean
    context?: DependsDataContext
    onSubmit: (nextField: ISlideField) => void
    onDelete: (field: ISlideField) => void
    onBreakImages?: () => void
    onClose: () => void
}

export function EditFieldForm({ field, isTemplate, context, onClose, onSubmit, onDelete, onBreakImages }: EditFieldFormProps) {
    const [data, setData] = useState(field)

    function deleteHandler() {
        if (global.confirm(strings.confirm_remove_object)) {
            onDelete(field)
            onClose()
        }
    }

    function okHandler() {
        onSubmit(data)
        onClose()
    }

    return (
        <div className="w-full flex flex-col">
            <TabsView>
                { data.text && 
                <div title={strings.lab_edit_field_text}>
                    <EditTextFieldTab 
                        data={data.text} context={context}
                        onChange={nextData => setData({...data, text: nextData})}/>
                </div>
                }
                { data.image && 
                <div title={strings.lab_edit_field_image}>
                    <EditImageTab 
                        size={field.size} 
                        data={data.image} 
                        context={context}
                        isTemplate={isTemplate}
                        onChange={nextData => setData({...data, image: nextData})}/>
                </div>
                }
                { data.gallery &&
                <div title={strings.lab_edit_field_gallery}>
                    <EditGalleryTab 
                        data={data.gallery} isTemplate={isTemplate}
                        context={context}
                        onChange={nextData => setData({...data, gallery: nextData})}
                        onBreakImages={onBreakImages}
                        onClose={onClose}/>
                </div>
                }
                <div title={strings.lab_edit_field_padding}>
                    <EditPaddingTab padding={data.padding} 
                        onChange={(next) => setData({...data, padding: next})}/>
                </div>
                <div title={strings.lab_edit_field_border} className="flex flex-col">
                    <EditBorderTab
                        border={data.border}
                        setBorder={(next) => setData({...data, border: next})}/>
                </div>
            </TabsView>

            <div className="flex flex-row justify-end gap-x-2 pt-4">
                <button className="redbutton" onClick={deleteHandler}>{strings.mi_delete}</button>
                <div className="flex-auto"></div>
                <button className="textbutton" onClick={onClose}>{strings.button_cancel}</button>
                <button className="textbutton" onClick={okHandler}>{strings.button_save}</button>
            </div>
        </div>
    )
}