import { INomination } from "../../model/models";
import strings from "../../strings";

export function NominationSlide({ item }: { item: INomination }) {
    return (
        <div className="flex flex-col justify-center items-center py-8">
            <div className="absolute text-3xl text-disabled self-end px-8 right-0 bottom-0">{strings.msg_presentation_nomination}</div>
            <div className="text-2xl px-4 text-center">{item.title}</div>
        </div>
    )
}