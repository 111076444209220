import { CSSProperties } from "react"
import { Icon } from "../../../comp/Icon"
import { extractImageSrc } from "../../../comp/ImageFB"
import { PhotoSize } from "../../../model/models"
import { ISlideImageField } from "../../../model/pptx"
import { ImageAlign, ImageScale, imageAlignToCssBackgroundPosition, imageScaleToCssBackgroundSize } from "../../../model/common"

interface SlideImageBoxProps {
    data: ISlideImageField
    isTemplate?: boolean
    // className?: string
    // onDlbClick?: () => void
}

export function imageBoxStyle(data: ISlideImageField, photoSize: PhotoSize): CSSProperties {
    var imageUri = extractImageSrc(data.src, photoSize)
    let style: CSSProperties = {
        backgroundImage: `url(${imageUri})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: imageAlignToCssBackgroundPosition(data.align || ImageAlign.center, data.pos),
        backgroundSize: imageScaleToCssBackgroundSize(data.scale),
    }
    return style
}

export function SlideImageBox({ data, isTemplate }: SlideImageBoxProps) {
    if (!isTemplate && data.src) {
        let style = imageBoxStyle(data, PhotoSize.large)
        return <div style={style} ></div>
    } else
        return <>
            <div className="w-full h-full bg-success/50"></div>
            <Icon name="image" className="absolute-center" size="20px"/>
        </>
}