import { isAxiosError } from "axios";
import { useEffect, useState } from "react";
import { IContest, IContestUser, IVoteResult, dbid } from "../model/models";
import { TocSection, TocData, IVoteData } from "../model/query";
import DataService from "../services/DataService";
import DocService from "../services/DocService";
import VoteService from "../services/VoteService";
import JuryService from "../services/JuryService";

const LIMIT = 15

export function useVoteResult(contestId: dbid, stageId: dbid) {
    const [ loadingVoteResult, setLoadingVoteResult ] = useState<boolean>()
    const [ voteResult, setVoteResult ] = useState<IVoteResult[]>()
    const [ loadingJury, setLoadingJury ] = useState<boolean>()
    const [ jury, setJury ] = useState<IContestUser[]>()

    async function loadVoteResult(contestId: dbid, stageId: dbid) {
        setLoadingVoteResult(true)
        try {
            let offset = 0
            let limit = 50
            let canMore = true
            let res = new Array<IVoteResult>()
            while (canMore) {
                const resp = await VoteService.getVotesBy(contestId, stageId, undefined, offset, limit)
                resp.data.forEach(o => res.push(o))
                canMore = resp.data.length === limit
                offset += limit
            }
            setVoteResult(res)
        } catch(e) {
            console.error(e)
        } finally {
            setLoadingVoteResult(false)
        }
    }
    async function loadJury(contestId: dbid, stageId: dbid) {
        setLoadingJury(true)
        try {
            let offset = 0
            let limit = 50
            let canMore = true
            let res = new Array<IContestUser>()
            while (canMore) {
                const resp = await JuryService.getList(contestId, stageId, offset, limit)
                resp.data.forEach(o => res.push(o))
                canMore = resp.data.length === limit
                offset += limit
            }
            setJury(res)
        } catch(e) {
            console.error(e)
        } finally {
            setLoadingJury(false)
        }
    }
    useEffect(() => {
        if (contestId && stageId) {
            loadVoteResult(contestId, stageId)
            loadJury(contestId, stageId)
        }
    }, [contestId, stageId])
    return { data: voteResult && jury && { stageId, result: voteResult, jury } as IVoteData, loading: loadingVoteResult || loadingJury }
}

interface TocOptions {
    stageId?: dbid
    onlySection?: number
    // voteResultStage?: dbid
}

export function useTocData(contestId: dbid, options?: TocOptions) {
    const [ error, setError ] = useState<string>()
    const [ loadingToc, setLoadingToc ] = useState<boolean>(true)
    const [ loadingSection, setLoadingSection ] = useState<boolean>() 
    const [ contest, setContest ] = useState<IContest>()
    const [ toc, setToc ] = useState<TocData>()

    async function loadSection(section: TocSection, toc: TocData) {
        setLoadingSection(true)
        try {
            const resp = await DocService.getTocItems(contestId, section.nomination.id, 0, LIMIT)
            section.append(resp.data)
            toc.updateIndex(section)
            if (options?.onlySection != null) {
                setToc(toc)
            }
        } catch (e) {
            console.error(e)
            if (isAxiosError(e)) {
                setError(e.message)
            } else {
                setError(e + "")
            }
        } finally {
            setLoadingSection(false)
        }
    }

    async function fetchToc() {
        setLoadingToc(true)
        try {
            const resp1 = await DocService.getToc(contestId)
            const sections = resp1.data.items.map( m => new TocSection(m))
            const resp0 = await DataService.getContest(contestId)
            const nextContest =  resp0.data
            setContest(nextContest)
            const nextToc = new TocData(nextContest, sections)
            setToc(nextToc)

            if (options?.onlySection == null) {
                for (let index = 0; index < sections.length; index++) {
                    const section = sections[index]
                    await loadSection(section, nextToc)
                }
            } else {
                const section = sections[options?.onlySection]
                await loadSection(section, nextToc)
            }
        } catch(e) {
            console.error(e)
            if (isAxiosError(e))
                setError(e.message)
            else 
                setError(e + "")
        } finally {
            // console.log("Loading toc finish")
            setLoadingToc(false)//null)
        }
    }
    function reload() {
        if (contestId && contestId !== toc?.contest?.id) {
            fetchToc()
        }
    }
    useEffect(reload, [contestId])
    return { contest, toc, loadSection, reload, loading: loadingToc, loadingSection, error }
}