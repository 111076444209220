import { Link } from "react-router-dom";
import { Icon } from "./Icon";

interface BackLinkProps {
    to: string
    text: string
    current?: string
}

export function BackLink({ to, text, current }: BackLinkProps) {
    return (
        <div className="flex flex-row items-center pt-2 pb-8 gap-2">
            <Link to={to} 
                className="flex flex-row items-center gap-2">
                <Icon name="arrow_back" className="pt-1"/>
                <span>{text}</span>
            </Link>
            {current && 
            <span className="text-disabled">/ {current}</span>
            }
        </div>
    )
}