import { useState } from "react"
import { Icon } from "../../comp/Icon"
import { EmptyView } from "../../comp/Loading"
import { LogoView } from "../../comp/LogoView"
import strings from "../../strings"
import { IVoteItem, VotePlaceItems } from "../../model/pptx"

const VOTE_PLACE_SIZE = 50

function usedPlaces<T>(places: VotePlaceItems<T>): T[] {
    return places.map( m => m.place)
}

interface VoteCheckItemsProps<T> {
    items: IVoteItem[]
    placeKinds: T[]
    initResult?: VotePlaceItems<T>
    title: string
    className?: string
    renderItem: (item: IVoteItem, index: number) => React.ReactElement
    onChange?: (result: VotePlaceItems<T>) => void
    onSubmit: (result: VotePlaceItems<T>) => void
}

export function VoteCheckItems<T>({ items, placeKinds, initResult, title, className, renderItem, onChange, onSubmit }: VoteCheckItemsProps<T>) {

    const [ places, setPlaces ] = useState<VotePlaceItems<T>>(initResult || [])

    function sendVoteHandler() {
        onSubmit(places)
    }

    function placeItemId(itemId: string, place: T) {
        const nextPlaces = [...places.filter(m => 
            m.itemId !== itemId && m.place !== place
        ), { itemId, place }]
        setPlaces(nextPlaces)
        onChange && onChange(nextPlaces)
    }
    function clickItemHandler(itemId: string, place: T | undefined) {
        if (place) {
            setPlaces([...places.filter( m => m.itemId !== itemId)])
        } else {
            const used = usedPlaces(places)
            const remain = placeKinds.filter( m => used.indexOf(m) < 0)
            const next = remain[0]
            if (next)
                placeItemId(itemId, next)
        }
    }
    
    const used = usedPlaces(places)

    return (
        <div className={"flex flex-col justify-center overflow-y-auto " + (className || "") }>
            <span className="pb-4">{ title }</span>
            <div className="flex flex-row justify-center gap-x-2 flex-wrap">
                {placeKinds.map( kind => 
                <PlaceLogoView key={kind + ""}
                    place={kind} 
                    draggable={true} 
                    used={used.indexOf(kind) >= 0}/>
                )}
            </div>
            {items.length > 0 
            ? items.map( (item, index) => 
            <div key={item.id || item.title} 
                className="flex flex-row items-center gap-x-2 overflow-hidden w-full"
                >
                <VoteCheckBox className="flex-none"
                    itemId={item.id!!}
                    place={places.find(m => m.itemId === item.id)?.place} 
                    placeKinds={placeKinds}
                    onClick={clickItemHandler}
                    onPlace={placeItemId}/>
                {renderItem(item, index)}
            </div>
            )
            : <EmptyView text={strings.msg_empty_items}/>
            }
            <div className="flex flex-row justify-center">
                <button 
                    className="greenbutton mt-8 min-w-1/2" 
                    onClick={sendVoteHandler}>{strings.button_vote}</button>
            </div>
        </div>
    )
}

interface VoteCheckBoxProps<T> {
    itemId: string
    place?: T
    placeKinds: T[]
    className?: string
    onPlace: (itemId: string, place: T) => void
    onClick: (itemId: string, place: T | undefined) => void
}

export function VoteCheckBox<T>({ itemId, place, placeKinds, className, onClick, onPlace }: VoteCheckBoxProps<T>) {
    function dragOverHandler(e: React.DragEvent<HTMLDivElement>) {
        e.preventDefault()
    }
    function dropPlaceHandler(e: React.DragEvent<HTMLDivElement>) {
        const data = e.dataTransfer.getData("text/plain")
        const kind = placeKinds.find( m => m + "" === data)
        // console.log("dropPlaceHandler", data, placeKinds)
        
        // "Объект 1" | 1 | 3 |  4
        // "Объект 2" | 2 |   |  2
        // "Объект 3" | 3 |   |  3
        // "Объект 4" |   | 2 |  2
        // "Объект 5" |   | 1 |  1

        // | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 |
        // | 8 | 7 | 6 | 5 | 4 | 3 | 2 | 1 |

        if (kind) {
            e.preventDefault()
            onPlace(itemId, kind)
        }
    }
    function clickHandler(e: React.MouseEvent) {
        e.stopPropagation()
        e.preventDefault()
        onClick(itemId, place)
    }
    
    return (
        <div className={"relative cursor-pointer active:bg-primary active:rounded-full " + (className || "")} 
            onClick={clickHandler}
            onDrop={dropPlaceHandler}
            onDragOver={dragOverHandler}
            style={{
                width: `${VOTE_PLACE_SIZE}px`, 
                height: `${VOTE_PLACE_SIZE}px`
            }}>
            { place
            ? <PlaceLogoView place={place} draggable={true}/>
            : <Icon name="radio_button_unchecked" 
                size={VOTE_PLACE_SIZE / 2}
                className="select-none absolute-center" 
                />
            }
        </div>
    )
}

interface PlaceLogoViewProps<T> {
    place?: T
    draggable?: boolean
    used?: boolean
}

export function PlaceLogoView<T>({ draggable, used, place}: PlaceLogoViewProps<T>) {
    function dragStartHandler(e: React.DragEvent<HTMLDivElement>) {
        if (used) {
            e.preventDefault()
            return
        }
        e.dataTransfer.clearData()
        e.dataTransfer.setData("text/plain", place + "")
    }
    return (
        <div className={"text-center relative cursor-pointer "} 
            onDragStart={used ? undefined : dragStartHandler}
            draggable={draggable && !used}>
            <LogoView 
                size={VOTE_PLACE_SIZE} 
                disabled={used}/>
            <div className={"absolute-center font-semibold pb-3 " + (used ? "text-disabled" : "text-onPrimary")}
                >{place + ""}</div>
        </div>
    )
}