import React from "react"
import ContentEditable, { ContentEditableEvent } from "react-contenteditable"
import sanitizeHtml from "sanitize-html"

interface RichEditProps {
    // className?: string
}
interface RichEditState {
    html: string
    editable: boolean
}

export class RichEdit extends React.Component<RichEditProps, RichEditState> {
    constructor(props: RichEditProps) {
        super(props)
        this.state = {
            html: `<p>Hello <b>World</b> !</p><p>Paragraph 2</p>`,
            editable: true
        };
    }
    handleChange = (evt: ContentEditableEvent) => {
        this.setState({ html: evt.target.value });
    };

    sanitizeConf = {
        allowedTags: ["b", "i", "em", "strong", "a", "p", "h1", "font"],
        allowedAttributes: { a: ["href"], font: ["color", "size"] }
    };

    sanitize = () => {
        this.setState({ 
            html: this.state.html//sanitizeHtml(this.state.html, this.sanitizeConf) 
        });
    };

    toggleEditable = () => {
        this.setState({ editable: !this.state.editable });
    };

    render = () => {
        return (
            <div className="w-full">
                <h3>editable contents</h3>
                <ContentEditable
                    className="textfield w-full"
                    html={this.state.html} // innerHTML of the editable div
                    disabled={!this.state.editable} // use true to disable edition
                    onChange={this.handleChange} // handle innerHTML change
                    onBlur={this.sanitize}
                    />
                <h3>source</h3>
                <textarea
                    className="textfield w-full"
                    value={this.state.html}
                    onChange={this.handleChange}
                    onBlur={this.sanitize}
                    />
                <h3>actions</h3>
                <EditButton cmd="italic" />
                <EditButton cmd="bold" />
                <EditButton cmd="fontSize" arg="105" />
                <EditButton cmd="foreColor" arg="red"/>
                <EditButton cmd="backColor" arg="red"/>

                <EditButton cmd="removeFormat" />
                <EditButton cmd="formatBlock" arg="h1" name="heading" />
                <EditButton
                    cmd="createLink"
                    arg="https://github.com/lovasoa/react-contenteditable"
                    name="hyperlink"
                    />
                {/* strikeThrough, underline */}
                {/* subscript, superscript */}
                {/* cmd="decreaseFontSize" -- <small> */}
                {/* cmd="delete" -- Deletes the current selection. */}
                {/* cmd="fontName" -- <font> This requires a font name string (like "Arial") as a value argument. */}
                {/* cmd="fontSize" -- <font> arg = 1..7*/}
                {/* cmd="backColor, foreColor" <font> -- */}
                {/* 
                insertImage, insertOrderedList, insertUnorderedList, insertParagraph, 
                insertText, insertHtml
                */}
                {/* justifyCenter, justifyFull, justifyLeft, justifyRight 
                -- <p style="text-align: center;"> </p>*/}
                {/* outdent */}
                {/* cmd="enableInlineTableEditing,enableObjectResizing" */}
                {/* removeFormat -- Removes all formatting from the current selection. */}
                <button className="textbutton" onClick={this.toggleEditable}>
                    Make {this.state.editable ? "readonly" : "editable"}
                </button>
            </div>
        );
    };
}

function EditButton(props: { cmd: string, name?: string, arg?: string }) {
    return (
        <button
            key={props.cmd}
            className="textbutton"
            onMouseDown={evt => {
                evt.preventDefault(); // Avoids loosing focus from the editable area
                document.execCommand(props.cmd, false, props.arg); // Send the command to the browser
            }}
            >
            {props.name || props.cmd}
        </button>
    );
}