import { FieldError } from "react-hook-form"
import strings from "../strings"

interface LabelProps {
    text: string
    error?: FieldError
    disabled?: boolean
    className?: string
}

export function Label({ text, error, disabled, className }: LabelProps) {
    const errMessage = error && (error.message || (error.type === "required" ? strings.msg_required : ""))
    return (
        <label className={ (className || "") +
            disabled 
            ? ' font-light flex pt-2 text-sm text-onPrimary/50 select-none' //w-full 
            : ' font-light flex pt-2 text-sm text-onPrimary/75 select-none'//w-full 
        }
            >{text}
            {error && 
            <div className="bg-error text-onError mx-4 px-1 rounded-full text-ellipsis"
                title={errMessage}>
                {errMessage}
            </div>}
        </label>
    )
}

interface LabelWithProps {
    text: string
    error?: FieldError
    disabled?: boolean
    className?: string
    children: React.ReactNode 
}

export function LabelWith(props: LabelWithProps) {
    return (
        <div className={`flex flex-col ${props.className ? props.className : ""}`}>
            <Label text={props.text}
                error={props.error}
                disabled={props.disabled}/>
            {props.children}
        </div>
    )
}