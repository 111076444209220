import menus from "../../menus"
import { IContestUser, IUser, PhotoSize, instanceOfUser } from "../../model/models"
import { AvatarView } from "../AvatarView"
import { ContextMenuDir, IContextMenuItem } from "../ContextMenu"
import ContextMenuButton from "../ContextMenuButton"

const menu = [menus.sendMessage, menus.removeAccess]

type User = IContestUser | IUser

interface ContestUserRowProps<U> {
    user: U
    className?: string
    onClick?: (user: U) => void
    onRemoveAccess?: (user: U) => void
    onSendMessage?: (user: U) => void
}

export function ContestUserRow<U extends User>({ user, className, onClick, onRemoveAccess, onSendMessage }: ContestUserRowProps<U>) {
    function menuClickHandler(mi: IContextMenuItem) {
        switch (mi.id) {
            case menus.SEND_MESSAGE:
                onSendMessage && onSendMessage(user)
                return
            case menus.REMOVE_ACCESS: 
                onRemoveAccess && onRemoveAccess(user)
                return
        }
    }
    const name = instanceOfUser(user) ? user.fullname : user.name 
    return (
        <div className={"flex flex-row items-center gap-x-2 rounded-lg bg-primary3 select-none pr-2 " + (onClick ? "itemrow " : "") + (className || "")}
            onClick={() => onClick && onClick(user)}>
            <div className="flex-none overflow-hidden h-[60px] flex flex-row items-center ">
                <AvatarView className="ml-[-10px]" 
                    src={user.avatar} size={PhotoSize.medium} />
            </div>
            <span className="flex-auto text-ellipsis overflow-hidden line-clamp-2">{name}</span>
            <ContextMenuButton menu={menu} className=""
                onClick={menuClickHandler} dir={ContextMenuDir.FromRightBottom}
                onVisible={mi => 
                    (mi.id === menus.REMOVE_ACCESS && !!onRemoveAccess) || (mi.id === menus.SEND_MESSAGE && !!onSendMessage)
                }
                />
        </div>
    )
}