import React, { useState } from "react"
import { IPoint } from "../model/common"

export function getContextMenuPtFromEvent(e: React.MouseEvent) {
    return { x: e.pageX - document.documentElement.scrollLeft, y: e.pageY - document.documentElement.scrollTop } as IPoint
}

export interface IContextMenuItem {
    id: string
    title: string
    items?: IContextMenuItem[]
}

type IContextMenu4<T> = [IContextMenuItem[], (mi: IContextMenuItem, item: T) => void, (mi: IContextMenuItem, item: T) => boolean, (mi: IContextMenuItem, item: T) => boolean]
type IContextMenu3<T> = [IContextMenuItem[], (mi: IContextMenuItem, item: T) => void, (mi: IContextMenuItem, item: T) => boolean]
type IContextMenu2<T> = [IContextMenuItem[], (mi: IContextMenuItem, item: T) => void]

export type IContextMenu<T> = IContextMenu2<T> | IContextMenu3<T> | IContextMenu4<T>

interface ContextMenuItemProps {
    item: IContextMenuItem
    disabled?: boolean
    onClick: (item: IContextMenuItem) => void 
}

export function ContextMenuItem({ item, disabled, onClick }: ContextMenuItemProps) {
    const [ innerMenu, setInnerMenu ] = useState<[IPoint, IContextMenuItem[]]>()
    
    function clickHandler(e: React.MouseEvent<HTMLLIElement>) {
        e.stopPropagation()
        if (!disabled) {
            // onClick && 
            if (item.items) {
                let pt = getContextMenuPtFromEvent(e)
                setInnerMenu([pt, item.items])
            } else
                onClick(item)
        }
    }
    return (
        <li className={`text-sm bg-primary border-0 menuitem overflow-visible ${disabled ? "disabled" : ""}`}
            onClick={clickHandler} >
            <span className="text-ellipsis overflow-hidden ">{item.title}</span>
            {innerMenu &&
            <ContextMenu menu={innerMenu[1]} pt={innerMenu[0]}
                onClick={onClick} onClose={() => setInnerMenu(undefined)}/>
            }
        </li>
    )
}

export enum ContextMenuDir {
    FromRightBottom = "right-0 bottom-0", 
    FromLeftBottom = "left-0 bottom-0", 
    FromLeftTop = "left-0 top-0", 
    FromRightTop = "right-0 top-0"
}

interface ContextMenuProps {
    menu: IContextMenuItem[]
    dir?: ContextMenuDir
    pt?: IPoint
    onDisabled?: (item: IContextMenuItem) => boolean
    onVisible?: (item: IContextMenuItem) => boolean
    onClick: (item: IContextMenuItem) => void
    onClose: () => void
}

export function ContextMenu({ menu, dir, pt, onClick, onClose, onDisabled, onVisible }: ContextMenuProps) {
    function clickItemHandler(mi: IContextMenuItem) {
        onClick(mi)
        onClose()
    }
    function closeHandler(e: React.MouseEvent) {
        e.stopPropagation()
        e.preventDefault()
        onClose && onClose()
    }
    return (
        <>
        <div className="fixed bg-black/10 left-0 top-0 right-0 bottom-0 z-40" onClick={closeHandler}></div>
        { pt 
        ? <ul 
        // + (dir || "")
            className={"fixed z-50 items box-border min-w-[100px] max-w-[200px] bg-primary rounded-md overflow-visible "}
            style={{left: pt.x, top: pt.y}}>
            {menu.filter(mi => !onVisible || onVisible(mi)).map( mi =>
            <ContextMenuItem key={mi.id}
                item={mi} 
                disabled={onDisabled && onDisabled(mi)}
                onClick={clickItemHandler}
                />
            )}
        </ul>
        : <ul 
            className={"absolute z-50 items box-border min-w-[100px] max-w-[200px] bg-primary rounded-md overflow-visible " + (dir || "")}
            >
            {menu.filter(mi => !onVisible || onVisible(mi)).map( mi =>
            <ContextMenuItem key={mi.id}
                item={mi} 
                disabled={onDisabled && onDisabled(mi)}
                onClick={clickItemHandler}
                />
            )}
        </ul>
        }        
        </>
    )
}

interface ContextMenu2Props<T> {
    menu: IContextMenu<T>
    item: T
    pt?: IPoint
    dir?: ContextMenuDir
    onClose: () => void
}

export function ContextMenu2<T>({ menu, item, dir, pt, onClose }: ContextMenu2Props<T>) {
    return (
        <ContextMenu menu={menu[0]} onClose={onClose}
            dir={dir} pt={pt}
            onClick={mi => menu[1](mi, item)}
            onDisabled={mi => menu[2] ? menu[2](mi, item) : false}
            onVisible={mi => menu[3] ? menu[3](mi, item) : true}
            />
    )
}