import { ISlide } from "../../model/pptx"

interface SlideMiniProps {
    slide: ISlide
    selected: boolean
    onClick?: (slide: ISlide) => void
}

export function SlideMini({slide, selected, onClick}: SlideMiniProps) {
    if (slide.childs)
        return (
            <div className="relative pr-2 pb-2 select-none " onClick={() => onClick && onClick(slide)}>
                <div className={"flex-none w-[240px] h-[135px] drop-shadow rounded-lg " + (selected ? "bg-secondary" : "bg-primary2") }></div>
                <div className={"absolute left-1 top-1 flex-none w-[240px] h-[135px] drop-shadow border border-primary3 rounded-lg " + (selected ? "bg-secondary" : "bg-primary2") }></div>
                <div className={"absolute left-2 top-2 flex-none w-[240px] h-[135px] drop-shadow border border-primary3 rounded-lg " + (selected ? "bg-secondary" : "bg-primary2") }>
                    <span className="p-2 text-black">{ slide.name || slide.childs.length + " слайдов" }</span>
                </div>
            </div>
        )
    else
        return (
            <div className={"flex-none w-[240px] h-[135px] drop-shadow rounded-lg select-none " + (selected ? "bg-secondary" : "bg-primary2") }
                onClick={() => onClick && onClick(slide)}>
                    <span className="p-2 text-black">{ slide.name || "Без названия" }</span>
                </div>
        )
}