import { AxiosError } from "axios"
import { useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { Checkbox, GroupBox } from "../../../comp/Checkbox"
import { ErrorMessage } from "../../../comp/ErrorMessage"
import { LabelWith } from "../../../comp/Label"
import { descrValidation, titleValidation } from "../../../comp/forms/validations"
import { IContest } from "../../../model/models"
import { IPresentation, buildSlides } from "../../../model/pptx"
import { IDataQuery, TocData } from "../../../model/query"
import DocService from "../../../services/DocService"
import strings from "../../../strings"
import { DataQueryTocView } from "../DataQueryView"

interface CreatePresentationFormProps {
    presentation: IPresentation
    contest: IContest
    toc: TocData
    onClose: () => void
    onSave: (presentation: IPresentation) => void
}

export function CreatePresentationForm({ presentation, contest, toc, onClose, onSave }: CreatePresentationFormProps) {
    const { register, formState: { errors }, handleSubmit, setError } = useForm<IPresentation>({ defaultValues: presentation })
    const [query, setQuery] = useState<IDataQuery>(presentation.query || { nominationIds: [], entityQueries: [] })
    const [vote, setVote] = useState<boolean>(false)

    const onSubmit: SubmitHandler<IPresentation> = async (data) => {
        try {
            const newPresentation = {...data, 
                query: query, 
                contestId: toc.contest.id,
                vote
            } as IPresentation

            let res = buildSlides(data.name, toc, undefined, query, vote)
            newPresentation.slides = res.slides
            newPresentation.templates = res.templates

            // onSave({...newPresentation, id: Date.now() + "" })
            const resp = await DocService.createPresentation(newPresentation)
            onSave({...newPresentation, id: resp.data.id })
            onClose()
        } catch(e) {
            console.error(e)
            const err = e as AxiosError
            const data: any = err.response?.data
            setError("root", {
                type: "manual",
                message: data?.message || err.message
            })
        }
    }

    return (
        <form className='flex flex-col w-full' 
            onReset={onClose}
            onSubmit={handleSubmit(onSubmit)}>
            {errors.root?.message &&
            <ErrorMessage message={errors.root?.message}/>
            }
            <LabelWith text={strings.lab_title}
                error={errors.name}>
                <input className='textinput' 
                    placeholder={strings.lab_title}
                    {...register("name", titleValidation())} />
            </LabelWith>
            
            <LabelWith text={strings.lab_descr}
                error={errors.description}>
                <textarea className='textinput' 
                    placeholder={strings.lab_descr}
                    rows={5}
                    {...register("description", descrValidation())} />
            </LabelWith>

            <GroupBox text={strings.lab_settings}>
                <Checkbox text="Слайд голосования по номинации" 
                    value={vote} 
                    setValue={setVote} 
                    left/>
            </GroupBox>

            <DataQueryTocView 
                query={query}
                onChange={next => setQuery(next)}
                toc={toc}
                />

            <div className="flex flex-row pt-4 gap-2 justify-end">
                <button className="greenbutton" type="submit">{strings.button_add}</button>
                <button className="textbutton" type="reset">{strings.button_cancel}</button>
            </div>
        </form>
    )
}