import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { ErrorMessage } from "../comp/ErrorMessage"
import { Icon } from "../comp/Icon"
import { LabelWith } from "../comp/Label"
import { ListItems, ListUseItems } from "../comp/ListItems"
import { EmptyView, Loading } from "../comp/Loading"
import { Modal } from "../comp/Modal"
import { TabsView } from "../comp/TabsView"
import { useVoteResults, useVoteResultsBy } from "../hooks/useItems"
import { useModal } from "../hooks/useModal"
import { useTocData } from "../hooks/useTocData"
import { IContestEntity, INomination, IStage, IVoteResult, IVoteRules, VoteKind, dbid } from "../model/models"
import { ITocItem, TocSection, scopes } from "../model/query"
import paths from "../paths"
import DataService from "../services/DataService"
import strings from "../strings"

type EntitiesSelector = [ids: dbid[], setIds: (next: dbid[]) => void]

interface VoteResultPageProps {
    contestId: dbid
}

export function VoteResultPage({ contestId }: VoteResultPageProps) {
    return (
        <div className="maincontainer">
            <TabsView>
                <VoteResultTable title="Таблица"  contestId={contestId}/>
                <VoteResultList title="Список" contestId={contestId}/>
            </TabsView>
        </div>
    )
}

interface VoteResultTableProps {
    title?: string
    contestId: dbid
}

function VoteResultTable({ contestId }: VoteResultTableProps) {
    const { contest, toc, loading, error, loadSection } = useTocData(contestId, { onlySection: 0 })
    const [stage, setStage] = useState<[curr: IStage, filter: IStage | undefined]>()
    if (loading) 
        return <Loading/>
    else if (!contest || !toc)
        return <ErrorMessage message={strings.err_load_contest}/>
        
    function stageChangeHandler(e: React.ChangeEvent<HTMLSelectElement>) {
        if (!contest?.stages)
            return
        let index = contest.stages.findIndex(stg => stg.id === e.target.value)
        let nextCurrStage = contest.stages[index]
        let nextFilterStage = contest.stages[index - 1]
        setStage(nextCurrStage ? [nextCurrStage, nextFilterStage] : undefined)
    }    

    return (
        <>
            <LabelWith text={strings.lab_stage}>
                <select className="textfield"
                    value={stage?.[0]?.id || ""} onChange={stageChangeHandler}
                    >
                    <option key="none" value=""></option>
                    {contest.stages.map(stg => 
                    <option key={stg.id} value={stg.id}>{stg.title}</option>
                    )}
                </select>
            </LabelWith>
            { !stage 
            ? <EmptyView text="Выбирите стадию конкуса"/>
            : !stage[0].voting
            ? <ErrorMessage message="Нет условий голосования"/>
            : toc.sections.map(sect => 
                <NominationVoteResult key={sect.nomination.id} 
                    contestId={contestId}
                    filterStageId={stage[1]?.id}
                    currStageId={stage[0].id} 
                    voteRules={stage[0].voting}
                    nomination={sect.nomination}
                    sect={sect}
                    entities={sect.items}
                    onLoad={() => loadSection(sect, toc)}/>
                )
            }
        </>
    )
}

interface NominationVoteResultProps {
    contestId: dbid
    currStageId: dbid
    filterStageId: dbid | undefined
    voteRules: IVoteRules
    nomination: INomination
    sect: TocSection
    entities: ITocItem[]
    onLoad: () => void
}

function NominationVoteResult({ contestId, currStageId, filterStageId, voteRules, nomination, sect, entities, onLoad }: NominationVoteResultProps) {
    const [ collapsed, setCollapsed ] = useState<boolean>()
    function toggleCollapsed() {
        setCollapsed(!collapsed)
    }
    
    return (
        <>
        <div className="px-2 pt-4 text-lg flex flex-row cursor-default hover:bg-white/5" 
            onClick={!sect.loaded ? onLoad : toggleCollapsed}>
            <div className="flex-auto">{nomination.title}</div>
            <Icon name={(!sect.loaded || collapsed) ? "arrow_drop_down" : "arrow_drop_up"} />
        </div>
        {sect.loaded && !collapsed &&
        <>
        <NominationVoteResultItems 
            contestId={contestId} entities={entities} voteRules={voteRules} nomination={nomination}
            filterStageId={filterStageId}
            currStageId={currStageId}/>
        </>
        }
        </>
    )
}

// function EditVoteResult

function useNominationVoteResultItems(contestId: dbid, filterStageId: dbid | undefined, stageId: dbid, voteRules: IVoteRules, nominationId: dbid, entities: ITocItem[]) {
    const name = `nomination(${nominationId})`
    const { items: votes, loading } = useVoteResultsBy(contestId, stageId, name)
    const [ items, setItems ] = useState<IEntityWithResults[]>()

    async function update() {
        if (!votes)
            return
        let nextItems: IEntityWithResults[] = []
        for (let item of entities) {
            let linkToNom = item.entity.nominations && item.entity.nominations[nominationId]
            if (filterStageId) {
                let exists = linkToNom && linkToNom.stages && linkToNom.stages.indexOf(filterStageId) >= 0
                if (!exists)
                    continue
            }

            let res = await calcVotes(votes, name, item.scopeId, voteRules)
            nextItems.push({
                item: item.entity, votes: res.value, points: res.points, names: res.names,
                stages: linkToNom && linkToNom.stages
            })
        }
        
        setItems(nextItems.sort((a,b) => b.points - a.points))
    }
    useEffect(() => {
        update()
    }, [votes])

    return {
        name, loading, votes, items, setItems
    }
}

interface IEntityWithResults {
    item: IContestEntity
    stages: string[] | undefined
    votes: number
    points: number
    names: string[]
}

interface NominationVoteResultItemsProps {
    contestId: dbid
    filterStageId: dbid | undefined
    currStageId: dbid
    voteRules: IVoteRules
    nomination: INomination
    entities: ITocItem[]
}

function NominationVoteResultItems({ contestId, filterStageId, currStageId, voteRules, nomination, entities }: NominationVoteResultItemsProps) {
    const editResult = useModal<IEntityWithResults[]>()

    const { loading, items, setItems } = useNominationVoteResultItems(contestId, filterStageId, currStageId, voteRules, nomination.id, entities)
    if (loading)
        return <Loading/>
    else if (!items)
        return <ErrorMessage message={strings.err_server}/>
    function saveHandler(ids: dbid[]) {
        if (!items)
            return
        items?.forEach(item => {
            let stages = item.stages?.filter(id => id !== currStageId) || []
            if (ids.indexOf(item.item.id) >= 0) {
                stages.push(currStageId)
            }
            item.stages = stages
        })
        setItems([...items])
    }

    return (
        <>
        {items &&
        <div className="flex flex-row justify-end items-center">
            <span className="font-thin">Кол-во заявок: {items.length}</span>
            <div className="flex-auto"></div>
            <button className="redbutton" onClick={() => editResult.openModal(items)}>Результаты голосования</button>
        </div>
        }
        <ListItems 
            className="pt-4 pl-8"
            items={items}
            emptyText={strings.msg_empty_entities}
            render={item => 
            <Link to={paths.contest_entity_by_id(contestId, item.item.id)} target="_blank">
                <ContestEntityVoteResultRow key={item.item.id} 
                    stageId={currStageId}
                    item={item}
                    />
            </Link>
            }
            />
        {editResult.modal && 
        <Modal onClose={editResult.closeModal}>
            <SummingUpForm 
                contestId={contestId} stageId={currStageId} nominationId={nomination.id}
                items={editResult.modal} 
                onSave={saveHandler}
                onClose={editResult.closeModal}/>
        </Modal>
        }
        </>
    )
}

interface SummingUpFormProps {
    contestId: dbid
    stageId: dbid
    nominationId: dbid
    items: IEntityWithResults[]
    onSave: (ids: dbid[]) => void
    onClose: () => void
}

function SummingUpForm({ items, contestId, stageId, nominationId, onSave, onClose }: SummingUpFormProps) {
    const [entityIds, setEntityIds] = useState(() => 
        items.filter(item => item.stages && item.stages.indexOf(stageId) >= 0).map(item => item.item.id)
    )
    let selector: EntitiesSelector = [entityIds, setEntityIds]
    async function submitHandler() {
        try {
            const resp = await DataService.summingUpVoting(contestId, stageId, nominationId, entityIds)
            if (resp.statusText === "OK") {
                onSave(entityIds)
                onClose()
            }
        } finally {

        }
    }
    return (
        <div className="flex flex-col">
            <span>Выбирите объекты что переходят на следующую стадию конкурса:</span>
            <ListItems 
            className="pt-4"
            items={items}
            emptyText={strings.msg_empty_entities}
            render={item => 
            <ContestEntityVoteResultRow key={item.item.id} 
                stageId={stageId}
                item={item}
                selector={selector}
                />
            }
            />
            <div className='flex flex-row justify-end gap-2 pt-4'> 
                <button type='submit' 
                    className='greenbutton'
                    onClick={submitHandler}>{strings.button_save}</button>
                <button type='reset' 
                    className='textbutton' 
                    onClick={onClose}>{strings.button_cancel}</button>
            </div>
        </div>
    )
}

export interface ICalcVotesResult {
    value: number
    points: number
    names: Array<string>
    values: {[juryScope: string]: number | undefined}
}

export function calcVotes(votes: IVoteResult[], nominationScope: string, entity: string, rules: IVoteRules | undefined) {
    console.log("calcVotes: " + rules?.voteKind)
    let max = rules?.numbers || 3
    let res: ICalcVotesResult = { value: 0, points: 0, names: [], values: {  } }
    let syms = (max + "").length
    votes.forEach( vote => {
        let item = vote.items[nominationScope]
        let value = item && item[entity]
        
        switch(rules?.voteKind) {
            case VoteKind.PLACES:
                if (!value)
                    value = 0
                res.points += max - (value - 1)
                res.value += value
                if (item && value) {
                    let valueS = (value + "").padStart(syms, " ")
                    res.values[vote.scopeId] = value
                    res.names.push(`${valueS}-${vote.name}`)
                }        
                break;
            case undefined:
            case VoteKind.SINGLE:
            case VoteKind.MANY:
                if (item && value) {
                    let valueS = (value + "").padStart(syms, " ")
                    res.values[vote.scopeId] = value
                    res.names.push(`${valueS}-${vote.name}`)
        
                    res.value += value
                    res.points += value
                }
                break;
        }
                    
    })
    if (rules?.voteKind === VoteKind.PLACES)
        res.names.sort((a, b) => a.localeCompare(b))
    return res
}

interface ContestEntityVoteResultRowProps {
    stageId: dbid
    item: IEntityWithResults
    selector?: EntitiesSelector
}

function ContestEntityVoteResultRow({ stageId, item, selector }: ContestEntityVoteResultRowProps) {
    function clickHandler() {
        if (!selector)
            return
        let exists = selector[0].indexOf(item.item.id) >= 0
        if (exists)
            selector[1](selector[0].filter(id => id !== item.item.id))
        else 
            selector[1]([...selector[0], item.item.id])
    }
    return (
        <div className={`py-2 flex flex-col select-none ${item.stages && item.stages.indexOf(stageId) >= 0 ? "font-bold" : "font-thin"} ${selector ? "cursor-pointer" : ""} ${selector && selector[0].indexOf(item.item.id) >= 0 ? "bg-success" : ""}`}
            onClick={selector && clickHandler}>
            <span className="text-xl">{item.item.title}</span>
            <div className="pl-4 flex flex-row gap-1 items-center text-sm flex-wrap"
                title={item.names.join()}>
                <span className="flex-none">{item.votes ? item.votes : "-"}</span>
                <span className="flex-none">{item.points ? "(" + item.points + ")" : ""}</span>
                <div className="flex-auto"></div>
                {item.names.map( nm =>
                <span className="text-xs text-disabled0 text-ellipsis overflow-hidden line-clamp-1 max-w-[150px]">{nm}</span>
                )}
            </div>
        </div>
    )
}

interface VoteResultListProps {
    title?: string
    contestId: dbid
}

function VoteResultList({ contestId }: VoteResultListProps) {
    const votes = useVoteResults(contestId)
    return (
        <ListUseItems useItems={votes}
            render={vote => 
                <div key={vote.id} className="flex flex-col itemrow">
                    <span>{vote.name}</span>
                    <div className="text-disabled text-xs">{vote.userId ? strings.lab_user : vote.juryId ? strings.lab_jury : "?"}</div>
                    <span className="text-disabled">{new Date(vote.end).toLocaleString()}</span>
                </div>
            }
            />
    )
}