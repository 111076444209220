import { useTitle } from "../hooks/useTitle";
import strings from "../strings";

export function AboutPage() {
    useTitle(strings.nav_about)
    // TODO Not implemented yet
    return (
        <div className='maincontainer flex flex-col'>
            <h1 className="header">{strings.nav_about}</h1>
            <p>
                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aspernatur maiores, eos sed modi esse sint? A porro necessitatibus voluptas aspernatur voluptatibus sint! Labore ipsam atque hic quod vero? Molestiae, quisquam!
            </p>
        </div>
    );
}