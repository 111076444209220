import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { IMessage, IUserMessage } from "../model/models"
import { TextField } from "../comp/TextField"
import strings from "../strings"
import { EmptyView, Loading } from "../comp/Loading"
import { LabelWith } from "../comp/Label"
import { useModal } from "../hooks/useModal"
import { SendMessageDialog } from "../comp/forms/SendMessageForm"
import MessageService from "../services/MessageService"
import { useTitle } from "../hooks/useTitle"
import paths from "../paths"

export function MessageReaderPage() {
    const params = useParams()
    const navigate = useNavigate()
    const messageId = params["id"] || ""
    const [ message, setMessage ] = useState<IMessage>()
    const sendMessageModal = useModal<IUserMessage>()
    useTitle(strings.nav_messages + (message ? " - " + message?.title : ""))


    useEffect(() => {
        async function fetchMessage() {
            try {
                const resp = await MessageService.getMessage(messageId)
                setMessage(resp.data)
            } catch(e) {
                console.error(e)
            }
        }
        fetchMessage()
    }, [messageId])

    return (
        <div className="maincontainer flex flex-col w-full">
            <span className="debugfield">ID: #{messageId}, ChatID: {message?.chatId}, ReplyID: #{message?.replyId}</span>
            { message ? 
            <>
            <div className="flex flex-row gap-x-2">
                <TextField label={strings.lab_from_user} className="flex-auto"
                    text={message.from.name}
                />
                <TextField label={strings.lab_date} className="w-64"
                    text={new Date(message.date).toLocaleDateString()}
                />
            </div>
            <TextField label={strings.lab_title} 
                text={message.title}
            />
            <LabelWith text={strings.lab_body} >
                { message.body 
                ? <div className="textfield" 
                    dangerouslySetInnerHTML={{__html: message.body!!.replaceAll("\n", "<br/>") + "<br/><br/>"}}></div>
                : <EmptyView/>
                }
            </LabelWith>
            <div className="flex flex-row pt-big gap-2">
                <button className="textbutton" 
                    onClick={() => sendMessageModal.openModal(message.from)}
                    >{strings.button_reply}</button>
                {message.chatId && 
                <button className="textbutton"
                    onClick={() => navigate(paths.messages_chat(message.chatId!!))}
                    >{strings.button_open_chat}</button>
                }    
            </div>
            <SendMessageDialog modal={sendMessageModal}
                chatId={message.chatId}
                replyId={message.id}/>
            </>
            : <Loading/>
            }
        </div>
    )
}