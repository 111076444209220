import { useEffect } from "react"
import { IVoteSubmit, dbid } from "../../../../model/models"
import { IVoteItem } from "../../../../model/pptx"
import { OnGotoSlideById } from "../SlideVoteBox"
import { toast } from "react-toastify"
import { EmptyView } from "../../../../comp/Loading"
import { VoteCheckBox } from "../../../views/VoteCheckItems"
import { IconButton } from "../../../../comp/Icon"
import strings from "../../../../strings"
import { preventClickPropagation } from "./VotePlacesView"

interface VoteManyViewProps {
    items: IVoteItem[]
    votes: IVoteSubmit
    setVotes: (next: IVoteSubmit) => void
    max: number
    onGoto?: OnGotoSlideById
    onSubmit?: (value: IVoteSubmit) => void
}

export function VoteManyView({ items, votes, setVotes, max, onGoto, onSubmit }: VoteManyViewProps) {
    useEffect(() => {
        let removed = Object.keys(votes).filter(id => 
            !items.find(item => item.id === id)
        )
        if (removed.length > 0) {
            toast.error(`Исчезло ${removed.length} проголосованных заявок: ${removed.join()}`)
            let next = {...votes}
            removed.forEach(id => 
                delete next[id]
            )
            setVotes(next)
        }
    }, [items])

    function toggleVote(itemId: dbid) {
        if (votes[itemId] === undefined) {
            if (Object.keys(votes).length >= max) 
                return
            setVotes({...votes, [itemId]: 1})
        } else {
            let next = {...votes}
            delete next[itemId]
            setVotes(next)
        }
    }

    return (
        <div className="w-full h-full flex flex-col overflow-y-auto " onClick={preventClickPropagation}>
            <div className="flex-none flex flex-row flex-wrap justify-center">
                {Object.keys(votes).length} из {max}
            </div>
            {items.length === 0
            ? <EmptyView/> 
            : items.map(item =>
                <div key={item.id} 
                    className="flex-none flex flex-row items-center gap-x-2 overflow-hidden w-full"
                    >
                    <VoteCheckBox className="flex-none"
                        itemId={item.id!!}
                        place={votes[item.id] !== undefined ? " " : undefined} 
                        placeKinds={[]}
                        onClick={() => toggleVote(item.id)}
                        onPlace={() => {}}
                        />
                    <span className="flex-auto text-ellipsis line-clamp-1">{item.title}</span>
                    { onGoto && 
                    <IconButton onClick={() => onGoto(item.id)} name="reply" />
                    }                    
                </div>                                
            )}
            <div className="flex flex-row justify-center">
                <button disabled={!onSubmit}
                    className="greenbutton mt-8 min-w-1/2" 
                    onClick={() => onSubmit && onSubmit(votes)}>{strings.button_vote}</button>
            </div>
        </div>
    )
}
