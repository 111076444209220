import React from "react"

interface IconProps {
    name: string
    size?: string | number
    hint?: string
    className?: string
}

export function Icon({ name, size, hint, className }: IconProps) {
    return (
        <span className={"material-symbols-outlined " + (className || "")}
            title={hint}
            style={{ fontSize: size || "18px" }}
            >{name}</span>
    )
}

interface IconButtonProps {
    name: string
    className?: string
    size?: string | number
    iconSize?: string | number
    disabled?: boolean
    alt?: string
    transparent?: boolean
    color?: string
    onClick: (e: React.MouseEvent<HTMLButtonElement>) => void
}

export function IconButton({ name, className, alt, size, iconSize, color, disabled, transparent, onClick }: IconButtonProps) {
    function clickHandler(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault()
        e.stopPropagation()
        !disabled && onClick(e)
    }
    return (
        <button className={"iconbutton " + (transparent ? "transparent " : "") + (className || "")}
            disabled={disabled}
            onClick={clickHandler} 
            style={{width: size, height: size, color }}
            title={alt}
            >
            <Icon name={name} size={iconSize}/>
        </button>
    )
}

interface ToggleButtonProps {
    name: string
    active: boolean
    disabled?: boolean
    onClick: () => void
}

export function ToggleButton({ name, active, disabled, onClick }: ToggleButtonProps) {
    return (
        <IconButton name={name} className={ active ? "" : "transparent" } disabled={disabled}
            onClick={onClick}/>
    )
}