import api from "../api";
import { AuthResponse } from "../model/response";

export default class AuthService {
    static async check() {
        return api.get<AuthResponse>("/user")
    }

    static async login(email: string, password: string) {
        return api.post<AuthResponse>("/login", { email, password })
    }

    static async register(fullname: string, email: string, password: string) {
        return api.post<AuthResponse>("/register", { fullname, email, password })
    }

    static async logout() {
        return api.post("/logout")
    }
}