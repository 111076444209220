import { useParams } from "react-router-dom";
import { DescrView } from "../comp/DescrView";
import { ListUseItems } from "../comp/ListItems";
import { useChatMessages } from "../hooks/useItems";
import { useTitle } from "../hooks/useTitle";
import { useTypedSelector } from "../hooks/useTypedSelector";
import { IMessage } from "../model/models";
import strings from "../strings";

interface ChatMessageProps {
    item: IMessage
    isMe: boolean
}

function ChatMessage({item, isMe}: ChatMessageProps) {
    return (
        <div className="flex flex-col py-2 itemrow">
            {/* <AvatarView size={PhotoSize.small} src={item.from.}/> */}
            <div className="flex flex-row">
                <span className="text-secondary font-bold">{item.from.name} <span className="font-light">{isMe ? "(" + strings.msg_its_you + ")" : ""}</span></span>
                <div className="flex-auto"></div>
                <span className="text-disabled">{new Date(item.date).toLocaleString()}</span>
            </div>
            <span className="pl-8 text-lg">{item.title}</span>
            <DescrView className="pl-8 pt-2 text-sm line-clamp-2" body={item.body} />
        </div>
    )
}

export function ChatPage() {
    const chatId = useParams().chatId || ""
    const { user } = useTypedSelector(state => state.auth);
    const chat = useChatMessages(chatId)
    useTitle(strings.nav_chat)
    
    return (
        <div className="maincontainer flex flex-col">
            <span className="debugfield">ChatID: #{chatId}</span>
            <ListUseItems useItems={chat} 
                render={ (item) =>
                    <ChatMessage item={item} isMe={user?.id === item.from.id}/>
                }/>

        </div>
    )
}