import React from "react"
import { ColorView } from "../../../comp/ColorView"
import { LabelWith } from "../../../comp/Label"
import { BorderLineStyle, IBorder } from "../../../model/common"

interface EditBorderTabProps {
    border: IBorder | undefined
    setBorder?: (next: IBorder) => void
}

export function EditBorderTab({ border, setBorder }: EditBorderTabProps) {
    function borderStyleHanlder(e: React.ChangeEvent<HTMLSelectElement>) {
        const nextStyle = Object.values(BorderLineStyle).find(item => item === e.target.value)
        setBorder && setBorder({...border, style: nextStyle})
    }
    return (
        <div className="flex flex-row justify-around items-center p-4">
            <LabelWith text="Line">
                <div className="border-solid border-2 border-black w-full"></div>
                <select className="textfield min-w-[100px] border-style"
                    value={border?.style || BorderLineStyle.solid}
                    onChange={borderStyleHanlder}>
                    <option value={BorderLineStyle.solid}>──</option>
                    <option value={BorderLineStyle.dots}>······</option>
                    <option value={BorderLineStyle.dash}>‐‐‐‐</option>
                </select>
            </LabelWith>
            <LabelWith text="Color">
                <ColorView color={border?.color} 
                    width={70}
                    setColor={(next) => setBorder && setBorder({...border, color: next})}/>
            </LabelWith>
            <LabelWith text="Width">
                <input type="number" className="textfield w-[70px] "
                    value={border?.width || 0} 
                    onChange={e => setBorder && setBorder({...border, width: parseInt(e.target.value) || 0})}
                    />
            </LabelWith>
        </div>
    )
}

