import api from "../api"
import { IUserVoteResult, IVoteResult, IVoteSubmit, dbid } from "../model/models"

export default class VoteService {

    static async getVotes(contestId: dbid, offset: number, limit: number) {
        return api.get<IUserVoteResult[]>(`/votes/${contestId}?offset=${offset}&limit=${limit}`)
    }

    static async getVotesBy(contestId: dbid, stageId: dbid, name: string | undefined, offset: number, limit: number) {
        return api.get<IVoteResult[]>(`/votes/${contestId}/by?stage=${stageId}${name ? ("&name=" + name) : ""}&offset=${offset}&limit=${limit}`)
    }

    static async getMyVote(contestId: dbid) {
        return api.get<IVoteResult>(`/votes/${contestId}/my`)
    }

    static async presentationVote(contestId: dbid, voteId: dbid, name: string, value: IVoteSubmit) {
        return api.post(`/votes/${contestId}/${voteId}`, { name, value })
    }
}