import { useTypedSelector } from "../hooks/useTypedSelector";
import { Navigate } from "react-router-dom";
import paths from "../paths";

interface ProtectedRouteProps {
    isAllowed?: boolean
    forJury?: boolean
    forSiteAdmin?: boolean
    redirectTo?: string
    children: React.ReactElement
}

export default function ProtectedRoute({ isAllowed, forJury, forSiteAdmin, redirectTo, children }: ProtectedRouteProps) {
    const { isAuth, jury, user } = useTypedSelector(state => state.auth);
    if (isAllowed === undefined)
        isAllowed = isAuth
    if (forJury && !jury)
        isAllowed = false
    if (forSiteAdmin && !user?.isSiteAdmin)
        isAllowed = false

    if (isAllowed)
        return children
    else 
        return <Navigate to={redirectTo || paths.login} />
}