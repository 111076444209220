import { Icon } from "./Icon"
import { Label } from "./Label"

interface CheckboxProps {
    left?: boolean
    disabled?: boolean
    text: string
    value: boolean
    className?: string
    setValue?: (next: boolean) => void
    onChange?: (e: React.MouseEvent, next: boolean) => void
}

export function Checkbox({ disabled, value, setValue, text, left, className, onChange }: CheckboxProps) {
    function clickHandler(e: React.MouseEvent<HTMLDivElement>) {
        let next = !value
        setValue && setValue(next)
        onChange && onChange(e, next)
    }
    if (left)
        return (
            <div className={(className || "") + " py-2 flex flex-row items-center gap-2 select-none " + (disabled ? "text-disabled" : "")} 
                onClick={clickHandler}> 
                <Icon name={value ? "check_box" : "check_box_outline_blank"} className={"cursor-pointer "} />
                <span>{text}</span>
            </div>
        )
    else
        return (
            <div className={(className || "") + " py-2 flex flex-row items-center gap-2 select-none " + (disabled ? "text-disabled" : "")} 
                onClick={clickHandler}> 
                <span>{text}</span>
                <Icon name={value ? "check_box" : "check_box_outline_blank"} className={"cursor-pointer "} />
            </div>
        )
}

interface GroupBoxProps {
    text?: string
    horizontal?: boolean
    className?: string
    innerClassName?: string
    children: React.ReactNode 
}

export function GroupBox({ text, horizontal, className, innerClassName, children }: GroupBoxProps) {
    return (
        <div className={"flex flex-col " + className}>
            {text &&
            <Label text={text} />
            }
            <div className={innerClassName + " border border-primary2 rounded px-4 pt-2 pb-2 relative " + (horizontal ? "flex flex-row justify-around" : "flex flex-col")}>
                {children}
            </div>
        </div>
    )
}