import { Dispatch, SetStateAction, useState } from "react"

const stateStore = new Map<string, any>()

export function useGlobalState<T>(name: string, initialState: T | (() => T)): [T, Dispatch<SetStateAction<T>>] {
    let [value, setValue] = useState<T>(() => {
        let prev = stateStore.get(name)
        if (prev)
            return prev
        return initialState instanceof Function ? initialState() : initialState
    })
    function setter(next: T | ((prevState: T) => T)) {

        let nextValue = next instanceof Function ? next(value) : next
        console.log(`change global state ${value} -> ${nextValue}`)
        stateStore.set(name, nextValue)

        setValue(nextValue)
    }
    return [value, setter]
}