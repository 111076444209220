import PptxGenJS from "pptxgenjs"
import { GalleryLayoutKind, IPresentation, ISlide, ISlideField, ISlideGalleryField, ISlideImageField, ISlideTextField, SLIDE_SIZE } from "../model/pptx"
import { IPoint, IRect, ImageAlign, verticalAlignCss } from "../model/common"
import { extractImageSrc } from "../comp/ImageFB"
import { coverImgToCanvas, coverUri } from "../api/crop"
import { PhotoSize } from "../model/models"
import { AllImagesLoader, isInstanceOfVerticalLayout } from "../pages/EditPresentationPage/boxes/GalleryBox"

export class ImagePos {
    // r: IRect
    pos: IPoint
    size: IPoint
    
    constructor(x: number, y: number, w: number, h: number, public src: string) {
        // this.r = { x, y, w, h } as IRect
        this.pos = { x, y }
        this.size = { x: w, y: h }
    }
}

export async function buildRects(field: ISlideField, gallery: ISlideGalleryField) {
    let promise = new Promise<ImagePos[]>(async (resolve, reject) => {
        let images = gallery.items.trim().split("\n")
        if (images.length === 0) {
            resolve([])
        }
        if (images.length === 1) {
            resolve([new ImagePos(field.pos.x, field.pos.y, field.size.x, field.size.y, images[0])])
        }
        let result = []
        let gap = gallery.gap ? gallery.gap : 0
        let gap2 = gap / 2
        let w_1_3 = field.size.x / 3
        switch (gallery.kind) {
            case GalleryLayoutKind.AUTO: {
                let loader = new AllImagesLoader(images.slice(0, gallery.max || 5))
                let layouts = await loader.preloadAsync()
                console.log(layouts)
                loader.pos(layouts, field.pos, field.size, gallery.gap || 0)
                let index = 0
                for (let layout of layouts) {
                    if (isInstanceOfVerticalLayout(layout)) {
                        layout.items.forEach( img => {
                            result.push(new ImagePos(img.pos.x, img.pos.y, img.pos.w, img.pos.h, img.uri))
                        })
                    } else {
                        result.push(new ImagePos(layout.pos.x, layout.pos.y, layout.pos.w, layout.pos.h, layout.uri))
                    }
                    index++
                }
                break;
            }
            case GalleryLayoutKind.BIG_CENTER_COL_RIGHT: {
                result.push(new ImagePos(field.pos.x, field.pos.y, 2*w_1_3 - gap2, field.size.y, images[0]))
                let cols = images.slice(1, 4)
                let hCol = field.size.y / cols.length
                let index = 0
                for (let img of cols) {
                    result.push(new ImagePos(
                        field.pos.x + 2*w_1_3 + gap2, 
                        field.pos.y + hCol*index + (index > 0 ? gap2 : 0), 
                        w_1_3 - gap, 
                        hCol - gap2,//(index < cols.length - 1 ? gap2 : 0), 
                        img
                    ))
                    index ++
                }
                break
            }
            case GalleryLayoutKind.BIG_CENTER_COL_LEFT: {
                result.push(new ImagePos(field.pos.x + w_1_3 + gap2, field.pos.y, 2*w_1_3, field.size.y, images[0]))
                let cols = images.slice(1, 4)
                let hCol = field.size.y / cols.length
                // cols.forEach( (img, index) => {
                let index = 0
                for (let img of cols) {
                    result.push(new ImagePos(
                        field.pos.x, 
                        field.pos.y + hCol*index + (index > 0 ? gap2 : 0), 
                        w_1_3 - gap2, 
                        hCol - gap2,//(index < cols.length - 1 ? gap2 : 0), 
                        img))
                    index++
                }
                break
            }
            case GalleryLayoutKind.GRID: {
                break
            }
            case GalleryLayoutKind.ROW: {
                break
            }
            case GalleryLayoutKind.COL: {
                break
            }
        }
        resolve(result)
    })
    return promise
}

const DEFAULT_SLIDE_X_INCH = 10
const DEFAULT_SLIDE_Y_INCH = 5.625

function xToInches(x: number) {
    return (x / SLIDE_SIZE.x) * DEFAULT_SLIDE_X_INCH
}

function yToInches(y: number) {
    return (y / SLIDE_SIZE.y) * DEFAULT_SLIDE_Y_INCH
}

async function addImageData(pptxSlide: PptxGenJS.Slide, 
    posX: number, posY: number, w: number, h: number, 
    data: string
) {
    try {
        let wInch = xToInches(w)
        let hInch = yToInches(h)
        pptxSlide.addImage({
            x: xToInches(posX),
            y: yToInches(posY),
            w: wInch,
            h: hInch,
            
            // sizing: { 
            //     type: "crop", 
            //     y: "10%",
            // //     // y: yToInches(50),
            // //     // w: "100%", h: "100%"
            //     w: wInch, 
            //     h: hInch
            // },
            data
            // path: uri
        })
    } catch(e) {
        console.error(e)
    }
}

async function addImage0(pptxSlide: PptxGenJS.Slide, 
    posX: number, posY: number, w: number, h: number, 
    src: string, align: ImageAlign | undefined, pos: IPoint | undefined
) {
    try {
        let uri = extractImageSrc(src, PhotoSize.large)
        let data = await coverUri(uri, w, h, { align, pos })
        addImageData(pptxSlide, posX, posY, w, h, data)
    } catch(e) {
        console.error(e)
    }
}

// function addImageFromImg(pptxSlide: PptxGenJS.Slide, 
//     posX: number, posY: number, w: number, h: number, 
//     img: HTMLImageElement, align?: ImageAlign
// ) {
//     let canvas = coverImgToCanvas(img, w, h, align || ImageAlign.center)
//     if (canvas) 
//         addImageData(pptxSlide, posX, posY, w, h, canvas.toDataURL())
// }

async function addImage(pptxSlide: PptxGenJS.Slide, field: ISlideField, image: ISlideImageField) {
    await addImage0(pptxSlide, field.pos.x, field.pos.y, field.size.x, field.size.y, image.src, image.align, image.pos)
}

async function addGallery(pptxSlide: PptxGenJS.Slide, field: ISlideField, gallery: ISlideGalleryField) {
    let poses = await buildRects(field, gallery)
    for (let pos of poses) {
        await addImage0(pptxSlide, pos.pos.x, pos.pos.y, pos.size.x, pos.size.y, pos.src, undefined, undefined)
    }
    // let images = gallery.items.trim().split("\n")
    // if (images.length === 0) {
    //     return
    // }
    // if (images.length === 1) {
    //     return addImage0(pptxSlide, field.pos.x, field.pos.y, field.size.x, field.size.y, images[0])
    // }
    // let gap = gallery.gap ? gallery.gap : 0
    // let gap2 = gap / 2
    // let w_1_3 = field.size.x / 3
    // switch (gallery.kind) {
    //     case GalleryLayoutKind.AUTO: {
    //         let loader = new AllImagesLoader(images.slice(0, gallery.max || 5))
    //         let layouts = await loader.preloadAsync()
    //         console.log(layouts)
    //         loader.pos(layouts, field.pos, field.size, gallery.gap || 0)
    //         let index = 0
    //         for (let layout of layouts) {
    //             if (isInstanceOfVerticalLayout(layout)) {
    //                 layout.items.forEach( img => {
    //                     addImageFromImg(pptxSlide, img.pos.x, img.pos.y, img.pos.w, img.pos.h, img.img)
    //                 })
    //             } else {
    //                 addImageFromImg(pptxSlide, layout.pos.x, layout.pos.y, layout.pos.w, layout.pos.h, layout.img)
    //             }
    //             index++
    //         }
    //         return
    //     }
    //     case GalleryLayoutKind.BIG_CENTER_COL_RIGHT: {
    //         await addImage0(pptxSlide, field.pos.x, field.pos.y, 2*w_1_3 - gap2, field.size.y, images[0])
    //         let cols = images.slice(1, 4)
    //         let hCol = field.size.y / cols.length
    //         let index = 0
    //         for (let img of cols) {
    //             await addImage0(pptxSlide, 
    //                 field.pos.x + 2*w_1_3 + gap2, 
    //                 field.pos.y + hCol*index + (index > 0 ? gap2 : 0), 
    //                 w_1_3 - gap, 
    //                 hCol - gap2,//(index < cols.length - 1 ? gap2 : 0), 
    //                 img
    //             )
    //             index ++
    //         }
    //         return
    //     }
    //     case GalleryLayoutKind.BIG_CENTER_COL_LEFT: {
    //         addImage0(pptxSlide, field.pos.x + w_1_3 + gap2, field.pos.y, 2*w_1_3, field.size.y, images[0])
    //         let cols = images.slice(1, 4)
    //         let hCol = field.size.y / cols.length
    //         // cols.forEach( (img, index) => {
    //         let index = 0
    //         for (let img of cols) {
    //             await addImage0(pptxSlide, 
    //                 field.pos.x, 
    //                 field.pos.y + hCol*index + (index > 0 ? gap2 : 0), 
    //                 w_1_3 - gap2, 
    //                 hCol - gap2,//(index < cols.length - 1 ? gap2 : 0), 
    //                 img)
    //             index++
    //         }
    //         return
    //     }
    //     case GalleryLayoutKind.GRID: {
    //         return
    //     }
    //     case GalleryLayoutKind.ROW: {
    //         return
    //     }
    //     case GalleryLayoutKind.COL: {
    //         return
    //     }
    // }
}

function addText(pptxSlide: PptxGenJS.Slide, field: ISlideField, text: ISlideTextField) {
    let opts = {
        x: xToInches(field.pos.x),
        y: yToInches(field.pos.y),
        w: xToInches(field.size.x),
        h: yToInches(field.size.y),
        align: text.align,
        fontSize: text.style?.fontSize,
        color: text.style?.color,
        fill: text.style?.background,
        bold: text.style?.bold,
        italic: text.style?.italic,
        strike: text.style?.strike,
        underline: text.style?.underline ? { style: "sng" } : undefined,
        // margin
        valign: verticalAlignCss(text.verticalAlign)
    } as PptxGenJS.TextPropsOptions
    pptxSlide.addText(text.text, opts)
}

async function addField(pptxSlide: PptxGenJS.Slide, field: ISlideField) {
    if (field.text) {
        addText(pptxSlide, field, field.text)
    } else if (field.image) {
        await addImage(pptxSlide, field, field.image)
    } else if (field.gallery) {
        await addGallery(pptxSlide, field, field.gallery)
    } else {
        console.error("Empty slide!")
    }
}

async function buildSlide(pptx: PptxGenJS, slide: ISlide) {
    if (slide.childs) {
        for (let child of slide.childs) {
            await buildSlide(pptx, child)
        }
    } else {
        let pptxSlide = pptx.addSlide();
        for (let field of slide.fields) {
            await addField(pptxSlide, field)
        }
    }
}

export async function buildPptx(presentation: IPresentation) {
    let pptx = new PptxGenJS()
    pptx.title = presentation.name
    // pptx.author = user
    // pptx.subject = 
    // pptx.company = 
    // Standard Slide Layouts
    // Layout Name	Default	Layout Slide Size
    // LAYOUT_16x9	Yes	10 x 5.625 inches
    // LAYOUT_16x10	No	10 x 6.25 inches
    // LAYOUT_4x3	No	10 x 7.5 inches
    // LAYOUT_WIDE	No	13.3 x 7.5 inches


    // let slide = presentation.slides[4]//.childs[0]
    // let child = slide.childs && slide.childs[4]
    // if (child)
    //     await buildSlide(pptx, child)

    for (let slide of presentation.slides) {
        await buildSlide(pptx, slide)
    }

    // let slide = pptx.addSlide();
    // let opts = {
    //     x: 0.0,
    //     y: 0.25,
    //     w: '100%',
    //     h: 1.5,
    //     align: 'center',
    //     fontSize: 24,
    //     color: '0088CC',
    //     fill: 'F1F1F1'
    //   } as PptxGenJS.TextPropsOptions
    //   slide.addText(
    //     'BONJOUR - CIAO - GUTEN TAG - HELLO - HOLA - NAMASTE - OLÀ - ZDRAS-TVUY-TE - こんにちは - 你好',
    //     opts
    //   )
    await pptx.writeFile({ fileName: `${presentation.name}.pptx` });
}