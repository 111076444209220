import { IContestEntity, ITemplate, dbid } from "../../model/models";
import { EditField } from "../fields/EditField";
import DataService from "../../services/DataService";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { LabelWith } from "../Label";
import { descrValidation, titleValidation } from "./validations";
import strings from "../../strings";

interface CreateContestEntityProps {
    item: IContestEntity
    contestId: dbid,
    template?: ITemplate
    onSave: (item: IContestEntity) => void
    onCancel?: () => void
}

export function CreateContestEntity({ item, contestId, template, onSave, onCancel }: CreateContestEntityProps) {
    const { 
        register, control, formState: { errors }, handleSubmit 
    } = useForm<IContestEntity>({ defaultValues: item })
    
    const onSubmit: SubmitHandler<IContestEntity> = async (data) => {
        data.id = data.id || item.id
        try {
            const resp = await DataService.saveContestEntity(contestId, data)
            onSave(resp.data);
        } catch(e) {
            console.error(e)
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col space-y-2 w-full'>
            <LabelWith text={strings.lab_entity_name} error={errors.title} >
                <input className="textinput"
                    placeholder={strings.lab_entity_name}
                    {...register("title", titleValidation())}/>
            </LabelWith>
            <LabelWith text={strings.lab_entity_desc} error={errors.description} >
                <textarea className="textinput" rows={3}
                    placeholder={strings.lab_entity_desc}
                    {...register("description", descrValidation())}/>    
            </LabelWith>
            { template?.fields?.map(fld => 
                <Controller key={fld.name} 
                    name={`fields.${fld.name}`}
                    control={control}
                    rules={{ 
                        required: fld.required,
                        pattern: fld.regex ? {
                            value: new RegExp(fld.regex),
                            message: strings.msg_incorrect + " " + fld.hint && ` ${fld.hint}`
                        } : undefined
                    }}
                    render={({ field: { onChange, onBlur, value } }) =>
                        <EditField
                            contestId={contestId} entityId={item?.id}
                            error={errors.fields && errors.fields[fld.name]}
                            value={value} onChange={onChange} onBlur={onBlur}
                            field={fld}
                            />
                    }
                    />
            )}

            <div className='flex flex-row justify-end gap-2 pt-4'>
                <button type='submit' className='greenbutton'>{strings.button_save}</button>
                {onCancel &&
                <button type='reset' className='textbutton' onClick={onCancel}>{strings.button_cancel}</button>
                }
            </div> 
        </form>
    );
}