import { ListItemsReorder, ListUseItems } from "../../comp/ListItems"
import { Modal } from "../../comp/Modal"
import { CreateNomination } from "../../comp/forms/CreateNomination"
import NominationRow from "../../comp/rows/NominationRow"
import { NominationsSortDir, useNominations, useNominationsAll } from "../../hooks/useItems"
import { useModal } from "../../hooks/useModal"
import { INomination, dbid } from "../../model/models"
import strings from "../../strings"
import { CreateNominationList } from "../../comp/forms/CreateNominationList"
import { searchInFields } from "../../extends/string"
import DataService, { IReorderParams } from "../../services/DataService"
import { IconButton } from "../../comp/Icon"
import { Loading } from "../../comp/Loading"
import { useState } from "react"
import { ComboKeyText, Combobox2 } from "../../comp/Combobox"
import { useGlobalState } from "../../hooks/useGlobalState"

const sortDirs: ComboKeyText<NominationsSortDir>[] = [
    [ NominationsSortDir.CREATE_ASC,  "Сначала старые" ],
    [ NominationsSortDir.CREATE_DESC, "Сначала новые" ],
    [ NominationsSortDir.TITLE_ASC,   "По алфавиту" ],
    [ NominationsSortDir.INDEX_ASC,   "По индексу" ],
]

interface NominationsViewProps {
    contestId: dbid
    onAdd: () => void
}

export function NominationsView({ contestId }: NominationsViewProps) {
    const [sort, setSort] = useGlobalState<NominationsSortDir>("NominationsView.sort", NominationsSortDir.INDEX_ASC)
    const nominations = useNominations(contestId, sort)
    const addNominationModal = useModal<INomination>()
    const addNominationsModal = useModal<string[]>()
    const reorderModal = useModal<boolean>()

    function saveEditNominationHandler(nextNomination: INomination) {
        let src = nominations.items.find( n => n.id === nextNomination.id)
        if (src) {
            nominations.replaceItem(src, nextNomination)
        } else {
            nominations.addItem(nextNomination, true)
        }
        addNominationModal.closeModal()
    }

    function saveNominationListHandler(items: INomination[]) {
        items.forEach(item =>
            nominations.addItem(item, true)
        )
        addNominationsModal.closeModal()
    }

    async function deleteHandler(nomination: INomination) {
        if (!global.confirm(strings.confirm_delete_nomination))
            return
        try {
            const resp = await DataService.deleteNomination(contestId, nomination.id)
            if (resp.statusText === "OK") {
                nominations.removeItem(nomination)
            }
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <>
        <div className="flex flex-row gap-x-2 pb-2">
            <IconButton //className="textbutton" 
                name="add"
                alt={strings.button_add_nomination}
                onClick={() => addNominationModal.openModal({} as INomination)}
                />
            <IconButton //className="textbutton" 
                name="post_add"
                alt={strings.button_add_nominations}
                onClick={() => addNominationsModal.openModal([])}
                />
            <IconButton name="reorder" 
                alt={"Изменить порядок"}
                onClick={() => reorderModal.openModal(true)}/>
        </div>
        <ListUseItems useItems={nominations} 
            onFilter={ (items, s) => 
                items.filter(m => searchInFields(s, m.title, m.description))
            }
            renderTools={() =>
                <div className="flex flex-row">
                    <Combobox2 value={sort} items={sortDirs} setValue={setSort}/>
                </div>
            }
            render={n =>
                <NominationRow key={n.id || n.title} item={n} 
                    onClick={() => addNominationModal.openModal(n)}
                    onDelete={() => deleteHandler(n)}/>
            }
            />
        {addNominationModal.modal && 
        <Modal title={strings.dlg_title_edit_nomination} onClose={addNominationModal.closeModal}>
            <CreateNomination contestId={contestId} 
                nomination={addNominationModal.modal}
                onSave={saveEditNominationHandler} 
                onCancel={addNominationModal.closeModal}
                />
        </Modal>
        }
        {addNominationsModal.modal &&
        <Modal title={strings.dlg_title_edit_nomination} onClose={addNominationsModal.closeModal}>
            <CreateNominationList contestId={contestId} 
                onSave={saveNominationListHandler} 
                onClose={addNominationsModal.closeModal}
                />
        </Modal>
        }
        { reorderModal.modal &&
        <Modal title="Изменить порядок" onClose={reorderModal.closeModal}>
            <ReorderNominationList contestId={contestId} 
                // onSave={saveNominationListHandler} 
                onSave={nominations.reload}
                onClose={reorderModal.closeModal}
                />
        </Modal>
        }
        </>
    )
}

interface ReorderNominationListProps {
    contestId: dbid
    onSave: () => void
    onClose: () => void
}

function ReorderNominationList({ contestId, onSave, onClose }: ReorderNominationListProps) {
    const nominations = useNominationsAll(contestId)

    async function saveHandler() {
        const data = {} as IReorderParams
        nominations.items.forEach( (item, index) => 
            data[item.id] = index
        )
        try {
            const resp = await DataService.reorderNominations(contestId, data)
            if (resp.statusText === "OK") {
                onSave()
                onClose()
            }
        } catch(e) {
            console.error(e)
        }
    }
    return (
        <div className='flex flex-col space-y-2 w-full'>
            <div className='flex flex-row justify-between gap-2 pt-4'>
                <button type='reset' className='textbutton' onClick={onClose}>{strings.button_close}</button>
                <button type='submit' className='greenbutton' onClick={saveHandler}>{strings.button_save}</button>
            </div>
            {nominations.loading 
            ? <Loading />
            : <ListItemsReorder 
                items={nominations.items}
                getKey={it => it.id}
                render={item => 
                    <NominationRow item={item} key={item.id}/>
                }
                onReorder={next => nominations.setItems(next) }
                />
            }
        </div>
    )
}