import { useState } from "react";
import { PhotoSize, dbid } from "../../model/models";
import { LabelWith } from "../Label";
import { Loading } from "../Loading";
import { ImageEx } from "../ImageFB";
import DataService from "../../services/DataService";
import { FieldError } from "react-hook-form";
import strings from "../../strings";
import { ImgUploader } from "../ImgUploader";
import { useImageFullscreen } from "../forms/ImageFullscreen";

interface EditImageFieldProps {
    contestId: dbid
    entityId?: dbid
    value?: string
    error?: FieldError
    onChange?: (e: any) => void
    onBlur?: () => void
    // field?:  ITemplateField
    label: string
    className?: string
    required?: boolean
}

export function EditImageField({ className, value, error, onChange, onBlur, label, required, contestId, entityId }: EditImageFieldProps) {
    const [uploading, setUploading] = useState(false)
    const imgFullscreen = useImageFullscreen()

    function updateValue(nextValue: string | null) {
        onChange && onChange(nextValue)
    }
    
    async function sendImageFile(files: FileList) {
        let file = files[0]
        if (!file || !onChange) {
            return
        }
        setUploading(true)
        try {
            const resp = await DataService.uploadImage(file, contestId, entityId);
            updateValue(resp.data.orig)
        } catch(e) {
            console.error(e)
        } finally {
            setUploading(false)
        }
    }

    function clearHandler() {
        if (!onChange || !global.confirm(strings.confirm_delete_photo)) {
            return
        }
        onBlur && onBlur()
        updateValue("")
    }

    return (
        <LabelWith text={label} error={error} className={className}>
            <div className="relative w-full min-h-[100px] max-h-[200px] max-w-[500px] overflow-hidden">
                { value && 
                <ImageEx src={value} size={PhotoSize.large} 
                    className="object-cover w-full rounded"
                    onClick={() => imgFullscreen.open(value)}/>
                }
                { onChange &&  
                <div className="absolute-center flex flex-col gap-y-2 text-center">
                    {uploading && <Loading/>}
                    { (!value) && 
                    <span className="text-center">{strings.msg_empty_photo}</span>
                    }
                    <ImgUploader onUpload={sendImageFile}/>
                    {(!required && value) && 
                    <button className="textbutton text-sm"
                        onClick={clearHandler}>{strings.mi_delete}</button>
                    }
                </div>
                }
            </div>    
            {imgFullscreen.render()}
        </LabelWith>
    )
}