import React, { useEffect, useMemo } from "react"
import { toast } from "react-toastify"
import { EmptyView } from "../../../../comp/Loading"
import { IVoteContextData } from "../../../../context/VoteContext"
import { IVoteSubmit } from "../../../../model/models"
import { IVoteItem } from "../../../../model/pptx"
import strings from "../../../../strings"
import { PlaceLogoView, VoteCheckBox } from "../../../views/VoteCheckItems"
import { OnGotoSlideById } from "../SlideVoteBox"

export function preventClickPropagation(e: React.MouseEvent) {
    e.preventDefault(); 
    e.stopPropagation();
}

const DEFAULT_PLACES = [1, 2, 3]

function placesFor(number: number | undefined) {
    if (!number || number < 1)
        return DEFAULT_PLACES
    let res = new Array<number>()
    for(let i = 1; i <= number; i++) {
        res.push(i)
    }
    return res
}

interface VotePlacesViewProps {
    items: IVoteItem[]
    voteContext: IVoteContextData
    votes: IVoteSubmit
    setVotes: (next: IVoteSubmit) => void
    max: number
    onGoto?: OnGotoSlideById
    onSubmit?: (value: IVoteSubmit) => void
}

export function VotePlacesView({ items, voteContext, votes, setVotes, max, onGoto, onSubmit }: VotePlacesViewProps) {
    let places = useMemo(() => placesFor(voteContext.rules?.numbers || 3), [items]) 

    useEffect(() => {
        let removed = Object.keys(votes).filter(id => 
            !items.find(item => item.id === id)
        )
        if (removed.length > 0) {
            toast.error(`Исчезло ${removed.length} проголосованных заявок: ${removed.join()}`)
            let next = {...votes}
            removed.forEach(id => 
                delete next[id]
            )
            setVotes(next)
        }
    }, [items])

    function toggleVote(itemId: string, prevPlace: number | undefined) {
        console.log("toggleVote")
        if (votes[itemId] === undefined) {
            let usedPlaces = Object.values(votes)
            let freePlace = places.find(place => usedPlaces.indexOf(place) < 0)
            if (typeof freePlace !== "undefined")
                setVotes({...votes, [itemId]: freePlace})
        } else {
            let next = {...votes}
            delete next[itemId]
            setVotes(next)
        }
    }
    function dropPlaceHandler(itemId: string, place: number) {
        let next = {...votes}
        delete next[itemId]
        Object.keys(next).forEach( id => {
            if (next[id] === place) {
                delete next[id]
            }
        })
        next[itemId] = place
        setVotes(next)
    }
    let usedPlaces = Object.values(votes)

    return (
        <div className="w-full h-full flex flex-col overflow-hidden" onClick={preventClickPropagation}>
            <div className="flex-none flex flex-row flex-wrap justify-center">
                {places.map( kind => 
                <PlaceLogoView key={kind + ""}
                    place={kind} 
                    draggable={true} 
                    used={usedPlaces.indexOf(kind) >= 0}/>
                )}
            </div>
            <div className="flex-auto overflow-y-auto bg-error0">
            {items.length === 0
            ? <EmptyView/> 
            : <div className="flex flex-col ">
            {items.map(item =>
                <div key={item.id} 
                    className="flex-none flex flex-row items-center gap-x-2 overflow-hidden w-full"
                    >
                    <VoteCheckBox className="flex-none"
                        itemId={item.id}
                        place={votes[item.id]} 
                        placeKinds={places}
                        onClick={toggleVote}
                        onPlace={dropPlaceHandler}/>
                    <span className="text-ellipsis line-clamp-1">{item.title}</span>
                </div>                                
            )}
            </div>
            }
            </div>
            <div className="flex-none flex flex-row justify-center">
                <button disabled={!onSubmit}
                    className="greenbutton mt-2 min-w-1/2" 
                    onClick={() => onSubmit && onSubmit(votes)}>{strings.button_vote}</button>
            </div>
        </div>
    )
}
