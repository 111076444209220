import { useState } from "react";
import { IUseModal, useModal } from "../../hooks/useModal";
import { PhotoSize } from "../../model/models";
import strings from "../../strings";
import { IconButton } from "../Icon";
import { ImageEx } from "../ImageFB";
import { Modal } from "../Modal";

export function ImageFullscreen({ modal }: { modal: IUseModal<string> }) {
    const [size, setSize] = useState(PhotoSize.large)
    if (modal.modal) {
        const img = modal.modal
        return (
            <Modal title={strings.dlg_title_fullscreen_photo} 
                onClose={ modal.closeModal }
                fullscreen={true}>
                <ImageEx src={img} size={size} alt={strings.dlg_title_fullscreen_photo}
                    objectFit={"contain"}
                    />
                <div className="flex flex-row gap-2 absolute right-4 bottom-4">
                    <IconButton transparent 
                        className=""
                        name="photo_size_select_large"
                        onClick={() => setSize(size === PhotoSize.large ? PhotoSize.orig : PhotoSize.large)}/>
                </div>
            </Modal>
        )
    } else 
        return (<></>)
}

export function useImageFullscreen() {
    const modal = useModal<string>()
    return {
        open: modal.openModal,
        render: () => <ImageFullscreen modal={modal}/>
    }
}