import { ResizeBox, ResizeBoxKind } from "../../../comp/ResizeBox";
import { BorderLineStyle, IBorder, IPoint } from "../../../model/common";
import { IVoteResult, IVoteRules, IVoteSubmit, dbid } from "../../../model/models";
import { ISlideField } from "../../../model/pptx";
import { TocData } from "../../../model/query";
import { SlideGalleryBox } from "./GalleryBox";
import { SlideImageBox } from "./ImageBox";
import { OnGotoSlideById, SlideVoteBox } from "./SlideVoteBox";
import { SlideTextBox } from "./TextBox";

export function borderCss(border: IBorder | undefined) {
    if (!border || !border.width)
        return
    const width = border.width
    const style = border.style || BorderLineStyle.solid
    const color = border.color || "black"
    return `${width}px ${style} ${color}`
}

interface SlideBoxProps {
    pos: IPoint
    size: IPoint
    field: ISlideField
    selected?: boolean
    onGoto?: OnGotoSlideById
    isTemplate: boolean
    onDblClick?: (field: ISlideField) => void
} 

export function SlideBox({ pos, size, field, selected, onGoto, isTemplate, onDblClick }: SlideBoxProps) {
    return (
        <div className="select-none absolute drop-shadow overflow-hidden grid"
            style={{
                left:   `${pos.x  }px`,
                top:    `${pos.y  }px`,
                width:  `${size.x }px`,
                height: `${size.y }px`,
                paddingLeft: field.padding?.left,
                paddingTop: field.padding?.top,
                paddingRight: field.padding?.right,
                paddingBottom: field.padding?.bottom,
                border: borderCss(field.border),
            }}
            onDoubleClick={() => onDblClick && onDblClick(field)}
            title={`pos: ${JSON.stringify(pos)}, size: ${JSON.stringify(size)}`}
        >
            {field.text 
            ? <SlideTextBox data={field.text} isTemplate/>
            : field.image 
            ? <SlideImageBox data={field.image} isTemplate={isTemplate}/>
            : field.gallery
            ? <SlideGalleryBox field={field} data={field.gallery} isTemplate={isTemplate}/>
            : field.vote
            ? <SlideVoteBox data={field.vote} isTemplate={isTemplate} 
                    onGoto={onGoto}/>
            : <></>
            }

            {selected && 
            <ResizeBox className="m-[1px] zIndex-50" kind={ResizeBoxKind.All}/>
            }                
        </div>
    )
}

