import { INomination, dbid } from "../../model/models";
import DataService from "../../services/DataService";
import { SubmitHandler, useForm } from "react-hook-form";
import { LabelWith } from "../Label";
import { descrValidation, titleValidation } from "./validations";
import strings from "../../strings";

interface CreateNominationProps {
    nomination: INomination
    contestId: dbid,
    onSave: (nomination: INomination) => void
    onCancel?: () => void
}

export function CreateNomination({ nomination, contestId, onSave, onCancel }: CreateNominationProps) {
    const { 
        register,
        formState: { errors },
        handleSubmit 
    } = useForm<INomination>({ defaultValues: nomination })
    const onSubmit: SubmitHandler<INomination> = async (data) => {
        try {
            const resp = await DataService.saveNomination(contestId, nomination.id, data)
            onSave(resp.data);
        } catch(e) {
            console.error(e)
            // let err = e as AxiosError
            // setError(err.message)
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col space-y-2 w-full'>
            <LabelWith text={strings.lab_nomination_title} error={errors.title}>
                <input className="textinput"
                    placeholder={strings.lab_nomination_title} 
                    {...register("title", titleValidation())}/>
            </LabelWith>

            <LabelWith text={strings.lab_nomination_desc} error={errors.description}>
                <textarea className="textinput" rows={3}
                    placeholder={strings.lab_nomination_desc} 
                    {...register("description", descrValidation())}/>
            </LabelWith>

            <div className='flex flex-row justify-end gap-2 pt-4'>
                <button type='submit' className='greenbutton'>{strings.button_save}</button>
                {onCancel &&
                <button type='reset' className='textbutton' onClick={onCancel}>{strings.button_cancel}</button>
                }
            </div>
        </form>
    );
}