import { Link } from 'react-router-dom';
import strings from '../strings';
import paths from '../paths';

export function Footer() {
    return (
        <div className='py-big flex justify-between items-center bg-primary3 px-big text-disabled'>
            <span>{strings.footer}</span>
            <span></span>
            <Link to={paths.about}>{strings.nav_about}</Link>
        </div>
    );
}
