

const strings = {
    appname: "CityAwards",
    appname2: "Городские конкурсы",
    footer: "© 2024 Вадим Бабин",
    footer2: "© 2024 Вадим Бабин и ООО \"Городские технологии\"",
    filename_arch: "Архив картинок",

    nav_main: "Главная",
    nav_news: "Новости",
    nav_presentation: "Презентация",
    nav_contest: "Номинанты",
    nav_dashboard: "Мои конкурсы",
    nav_messages: "Сообщения",
    nav_about: "О проекте",
    nav_admin: "Панель администратора",
    nav_login: "Вход",
    nav_register: "Регистрация",
    nav_jury_login: "Вход для жюри",
    nav_profile: "Профиль пользователя",
    nav_chat: "Вся переписка по теме",

    button_logout: "Выйти",
    button_save: "Сохранить",
    button_save_changes: "Сохранить изменения",
    button_rebuild: "Пересоздать из шаблона",
    button_send: "Отправить",
    button_open: "Открыть",
    button_add: "Добавить",
    button_cancel: "Отмена",
    button_close: "Закрыть",
    button_login: "Войти",
    button_load: "Загрузить",
    button_register: "Зарегистрировать",
    button_participate_in: "Участвовать в конкурсе",
    button_create_contest: "Создать конкурс",
    button_open_contest: "Открыть конкурс",
    button_add_nomination: "Добавить",
    button_add_nominations: "Добавить списком",
    button_add_contest_entity: "Добавить объект",
    button_entity_change_nominations: "Изменить участие в номинациях",
    button_add_template_field: "Добавить поле",
    button_invite: "Добавить пользователя",
    button_more: "Показать еще",
    button_reply: "Ответить",
    button_open_chat: "Вся переписка",
    button_add_jury: "Регистрация членов жюри",
    button_add_stage: "Добавить",
    button_create_img_arch: "Создать архив картинок",
    button_create_xlsx_template: "Создать шаблон документа",
    button_create_pptx_template: "Создать шаблон презентации",
    button_create_presentation: "Создать презентацию",
    button_create_table: "Создать таблицу",
    button_create_slide_template: "Создать шаблон слайда",

    button_vote: "Проголосовать",
    button_prev: "Назад",
    button_next: "Далее",
    button_first: "Начало",
    button_next_nomination: "Следующая номинация",
    button_summing_up_votes: "Результаты голосования",

    state_publish: "Опубликовать",
    state_unpublish: "Сделать приватным",

    entity_state_not_submitted: "Не подана заявка",
    entity_state_verification:  "Заявка на проверке",
    entity_state_approved:      "Заявка одобрена",
    entity_state_rejected:      "Заявка отклонена",
    entity_state_correction_required: "Требуется коррекция заявки",

    all_entity_state_not_submitted: "Все объекты участники не поданы на проверку",
    all_entity_state_approved:      "Все объекты участники одобрены",

    lab_fullname: "Полное имя",
    lab_name: "Имя",
    lab_jury: "Член жюри",
    lab_user: "Пользователь",
    lab_email: "Электронная почта",
    lab_contest_email: "Электронная почта для приёма заявок",
    lab_userid: "ID пользователя",
    lab_password: "Пароль",
    lab_search: "Поиск",
    lab_title: "Название",
    lab_body: "Текст",
    lab_descr: "Описание",
    lab_public_contests: "Публичные конкурсы",
    lab_contest: "Конкурс",
    lab_presentation: "Презентация",
    lab_table: "Таблица",
    lab_edit_presentation: "Редактор презентаций",
    lab_contests: "Конкурсы",
    lab_nomination: "Номинация",
    lab_nominated: "Номинирован",
    lab_attachments: "Список приложенных файлов",
    lab_texts_extracted: "Текст из пиьма и файлов вложения",
    lab_contest_entity: "Объект",
    lab_nominations: "Номинации",
    lab_document: "Документ",
    lab_documents: "Документы",
    lab_admins: "Администраторы",
    lab_users: "Пользователи",
    lab_juries: "Жюри",
    lab_vote_result: "Результаты голосования",
    lab_template_fields: "Шаблон полей",
    lab_contest_entities: "Объекты",
    lab_logo: "Логотип",
    lab_photos: "Фото",
    lab_created: "Дата создания",
    lab_edited: "Дата редактирования",
    lab_user_status: "Ваш статус",
    lab_user_status_admin: "Вы администратор конкурса",
    lab_user_status_contestant: "Вы участник конкурса",
    lab_user_status_none: "Вы пока не подали заявку на участие в конкурсе",
    lab_entity_name: "Название объекта",
    lab_entity_desc: "Описание объекта",
    lab_entity_state: "Состояние заявки",
    lab_nomination_title: "Название номинации",
    lab_nomination_desc: "Описание номинации",
    lab_template_field_type: "Тип поля",
    lab_template_field_name: "Внутреннее имя поля",
    lab_template_field_regex: "Регулярное выражени для проверки ввода",
    lab_template_field_enum: "Список вариантов",
    lab_template_field_hint: "Подсказка для заполняющего",
    lab_settings_listen_emails: "Электронная почта с которой можно получать новые заявки",

    lab_tables: "Список таблиц",
    lab_presentations: "Список презентаций",
    lab_slide_templates: "Шаблоны слайдов",

    lab_from_user: "От пользователя",
    lab_to_user: "Кому",
    lab_date: "Дата",
    lab_actions: "Действия",
    lab_query: "Выборка данных",
    lab_slides: "Слайды",
    lab_stage: "Стадия конкуса",
    lab_stages: "Стадии конкурса",
    lab_settings: "Настройки",
    lab_editor: "Редактор",

    lab_docs: "Документы",
    lab_docs_create: "Создание документов",
    lab_edit_password: "Редактировать пароль",
    
    lab_presentation_vote: "голосование",
    lab_presentation_end: "конец",

    lab_filter: "Фильтр",
    lab_add_vote: "Слайд голосования по номинации",
    lab_slides_count: (count: number) => `Кол-во слайдов: ${count}`,

    lab_edit_field_text: "Текст",
    lab_edit_field_image: "Картинка",
    lab_edit_field_gallery: "Картинки",
    lab_edit_field_padding: "Отступ",
    lab_edit_field_border: "Граница",

    mi_delete: "Удалить",
    mi_download: "Скачать",
    mi_download_arch: "Скачать архив картинок",
    mi_edit: "Редактировать",
    mi_submit: "Подать заявку",
    mi_approve: "Одобрить",
    mi_show: "Просмотр",
    mi_correct_required: "Требуется коррекция",
    mi_reject: "Отклонить окончательно",
    mi_send_message: "Отправить сообщение",
    mi_remove_access: "Удалить доступ",
    mi_remove: "Удалить",
    mi_add_text: "Добавить текст",
    mi_add_image: "Добавить картинку",
    mi_add_gallery: "Добавить галерею",
    mi_process_attachement: "Извлечить (сделать попытку)",

    msg_empty: "Пусто",
    msg_empty_photo: "Нет фотографии",
    msg_empty_items: "Нет вариантов",
    msg_empty_entities: "Нет заявок",
    msg_loading: "Загрузка…",
    msg_saving: "Сохранение…",
    msg_presentation_loading: "Загрузка презентации…",

    msg_required: "обязательное поле",
    msg_missing: "Отсутствует",
    msg_canceled: "Отменен",
    msg_unknown_field: "Неизвестное поле",
    msg_incorrect: "Некорректное значение",
    msg_invalid_input: "Неправильно введено поле",
    msg_invalid_email: "Неправильно введен email",
    msg_invalid_length_min: "Минимальная длина",
    msg_invalid_length_max: "Максимальная длина",
    msg_invalid_romaji: "Только латинские буквы и цифры",
    msg_its_you: "ваше сообщение",
    msg_presentation_final: "Спасибо за участие в конкурсе!",
    msg_presentation_vote: "Выставите свою оценку:",
    msg_presentation_nomination: "номинация",
    msg_create_nominations: "Список номинаций (не более 5)",
    msg_invite_user_by_id: "По идентификатору пользователя",
    msg_login_jury: "Вход для членов жюри конкурса",

    alt_jury_copy_url: "Скопировать ссылку для входа жюри",
    alt_jury_copy_password: "Скопировать пароль для входа жюри",
    
    confirm_delete_photo: "Удалить картинку?",
    confirm_delete_doc: "Удалить документ?",
    confirm_delete_presentation: "Удалить презентацию?",
    confirm_delete_contest: "Удалить конкурс?",
    confirm_delete_nomination: "Удалить номинацию?",
    confirm_delete_contest_entity: "Удалить участника?",
    confirm_delete_template_field: "Удалить поле?",
    confirm_submit_contest_entity: "Подать заявку на участие в колнкурсе?",
    confirm_remove_access_for: "Удалить административный доступ к конкурсу пользователя ",
    confirm_remove_jury: "Удалить члена жюри ",
    confirm_remove_object: "Удалить объект?",
    alert_message_required: "Требуется ввести сообщение пользователю!",
    alert_arch_no_img_fields: "Нет полей для создания архива картинок",
    prompt_enter_title: "Введите название документа",
    
    dlg_title_create_contest: "Создание конкурса",
    dlg_title_edit_contest: "Редактировать описание конкурса",
    dlg_title_edit_nomination: "Редактировать номинации конкурса",
    dlg_title_edit_entity: "Редактировать описание объекта",
    dlg_title_edit_entity_nominations: "Номинировать",
    dlg_title_edit_template_field: "Редактировать поле шаблона",
    dlg_title_fullscreen_photo: "Полноэкранное фото",

    err_auth: "Ошибка авторизации пользователя",
    err_server: "Ошибка при обращении к серверу",
    err_load_contest: "Ошибка загрузки конкурса",
    err_load_presentation: "Ошибка загрузки презентации",
    err_presentation_nomination: "Номинация не найдена",
    err_presentation_nominee: "Номинант не найден",

    slide_templ_first: "Шаблон первого слайда",
    slide_templ_nomination: "Шаблон слайда номинации",
    slide_templ_entity: "Шаблон слайда номинанта",
    slide_templ_vote: "Шаблон слайда голосования",
    slide_name_first: "Первый слайд",

    depend_filename: "Имя документа",
    depend_context_title: "Заголовок конкурса",
    depend_context_descr: "Описание конкурса",
    depend_context_logo: "Главное лого",
    depend_nomination_title: "Заголовок номинации",
    depend_nomination_descr: "Описание номинации",
    depend_entity_title: "Заголовок заявки",
    depend_entity_descr: "Описание заявки",
    
    sort_create_asc:  "Сначала старые",
    sort_create_desc: "Сначала новые",
    sort_index_asc:  "По индексу (возрастание)",
    sort_index_desc: "По индексу (убывание)",
    sort_title_asc:  "По названию (возрастание)",
    sort_title_desc: "По названию (убывание)",
    sort_vote_asc:  "По кол-ву голосов (убывание)",
    sort_vote_desc: "По кол-ву голосов (возрастание)",

    slide_vote_title: (nominationTitle: string) => 
        `Голосование ${nominationTitle}`,

    nominees: (n: number) => {
        return `${n} номинантов`
    },

    nominationsFor: (n: number) => {
        let n0 = n
        if (n > 20) {
            n0 = n % 10
        }
        if (n0 === 1) {
            return `номинация`
        } else if (n0 === 2 || n0 === 3 || n0 === 4) {
            return `номинации`
        } else {
            return `номинаций`
        }
    },
    entitiesFor: (n: number) => {
        let n0 = n
        if (n > 20) {
            n0 = n % 10
        }
        if (n0 === 1) {
            return `заявка`//${n} 
        } else if (n0 === 2 || n0 === 3 || n0 === 4) {
            return `заявки`//${n} 
        } else {
            return `заявок`//${n} 
        }
    }
} 

export default strings