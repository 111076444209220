import { IDataQuery } from "./query"

export type dbid = string

export interface IError {
    message?: string
}

export interface IUser {
    id: string
    email?: string
    username: string
    fullname: string
    messages?: number
    avatar?: string | null
    juryContestId?: string
    isJury?: boolean
    isSiteAdmin?: boolean
    presentationId?: dbid
    stage?: dbid
    query?: IDataQuery
}

export interface IContestUser {
    id: dbid
    contestId: dbid
    name: string
    username?: string
    avatar?: string
    description?: string
    isAdmin: boolean
    isJury: boolean
    stage?: dbid
    stages?: dbid[]
    presentationId?: dbid
    query?: IDataQuery   
}

export function instanceOfUser(object: any): object is IUser {
    return 'fullname' in object && 'username' in object
}

export enum TemplateFieldType {
    TEXT = "TEXT",
    ENUM = "ENUM",
    BOOL = "BOOL",
    IMAGE = "IMAGE",
    IMAGES = "IMAGES",
    FILE = "FILE",
    FILES = "FILES",
    VIDEO = "VIDEO",
    OBJECT = "OBJECT",
    ARRAY = "ARRAY"
}

export interface ITemplateField {
    index?: number
    required: boolean
    type: TemplateFieldType
    name: string
    label: string
    regex?: string
    enums?: string[]
    hint?: string
}

export interface ITemplate {
    fields: ITemplateField[]
}

export enum VoteKind {
    SINGLE = "SINGLE", 
    MANY = "MANY", 
    PLACES = "PLACES"
}

export interface IVoteRules {
    voteKind: VoteKind
    numbers?: number
}

export interface IStage {
    id: dbid
    num: number
    title: string
    description: string
    voting: IVoteRules
}

export interface IContestExample {
    id: string
    title: string
    description?: string
}

// export interface IContestSettings {
//     listenEmails?: string[]
// }

export interface IContest {
    id: dbid
    title: string
    description?: string
    logo?: string
    email?: string
    isPublished: boolean
    isAdmin?: boolean
    isParticipating?: boolean
    isDeleted?: boolean
    stages: IStage[]
    currentStage?: dbid
    template?: ITemplate
    nominations: number
    entities: number
}

export interface INomination {
    id: dbid
    title: string
    description: string
    index: number
    active: number
}

export interface IVoteSubmit {
    [index: string]: number | undefined
}

export interface IVoteItemsResult {
    [index: string]: IVoteSubmit | undefined
}

export interface IVoteResult {
    id: dbid
    name: string
    scopeId: string
    items: IVoteItemsResult
}

export interface IUserVoteResult {
    id: dbid
    name: string
    start: number
    end: number
    userId?: dbid
    juryId?: dbid
}

export enum EntityState {
    NOT_SUBMITTED = "NOT_SUBMITTED", // "Не подана заявка"
    VERIFICATION = "VERIFICATION",   // "Заявка на проверке"
    APPROVED = "APPROVED",           // "Заявка одобрена"
    REJECTED = "REJECTED",           // "Заявка отклонена"
    CORRECTION_REQUIRED = "CORRECTION_REQUIRED"// "Требуется коррекция заявки"
}

export interface IFields {
    [index: string]: string | undefined
}

export interface IAttachmentFile {
    filename: string
    url: string
    contentType: string
}

export interface ILinkToNomination {
    stages?: string[]
}

export interface INominations {
    [index: dbid]: ILinkToNomination | undefined
}

export interface IContestEntity {
    id: dbid
    nominationIds?: dbid[]
    nominations?: INominations
    title: string
    description: string
    created?: number
    edited?: number
    state: EntityState
    fields: IFields
    texts?: string[]
    attachments?: IAttachmentFile[]
    isAdmin: boolean
    canEdit: boolean
    canDelete: boolean
}

export function instanceOfContestEntity(object: any): object is IContestEntity {
    return 'id' in object && 'title' in object && 'fields' in object && 'state' in object
}

export interface IUserMessage {
    id: dbid
    name: string
}

export interface IMessage {
    id: dbid
    chatId?: string
    replyId?: dbid
    from: IUserMessage
    title: string
    body?: string
    date: number
    isRead: boolean
}

export interface ISendMessage {
    chatId?: string
    replyId?: dbid
    to?: dbid
    email?: dbid
    title: string
    body: string
}

export interface IPhoto {
    orig: string
    small?: string
    medium?: string
    large?: string
}

export interface IJob {
    id: dbid
    name: string
    start: number
    end?: number
    error?: string
    canceled: boolean
}

export interface IDocument {
    id: dbid
    name: string
    date: number
    uri: string
}

export enum PhotoSize {
    orig = "orig",
    small = "small",
    medium = "medium",
    big = "big",
    large = "large" 
}