import { calcVotes } from "../pages/VoteResultPage"
import { IContest, IContestEntity, IContestUser, ILinkToNomination, INomination, IVoteResult, IVoteRules, IVoteSubmit, dbid } from "./models"

export type Operations = ">" | "<" | "=" | ">=" | "<="

export interface IQueryItem {
    field: string
    label: string
    operation?: Operations
    value: string
}

export enum DataNominationSortKind {
    INDEX_ASC  = "INDEX_ASC",
    INDEX_DESC = "INDEX_DESC",
    TITLE_ASC  = "TITLE_ASC",
    TITLE_DESC = "TITLE_DESC"
}

export enum DataEntitySortKind {
    INDEX_ASC  = "INDEX_ASC",
    INDEX_DESC = "INDEX_DESC",
    DATE_ASC   = "DATE_ASC",
    DATE_DESC  = "DATE_DESC",
    TITLE_ASC  = "TITLE_ASC",
    TITLE_DESC = "TITLE_DESC",
    VOTE_ASC   = "VOTE_ASC",
    VOTE_DESC  = "VOTE_DESC"
}

export interface IDataQuery {
    stageId?: dbid
    nominationIds: dbid[]
    entityQueries: IQueryItem[]
    nominationSort?: DataNominationSortKind
    entitySort?: DataEntitySortKind
}

export interface ITocIDs {
    [index: string]: any | undefined
}

export interface IDataScope {
    [index: string]: any | undefined
}

export interface IDataIDs {
    [index: string]: string | undefined
}

export const TYPE_CONTEST = "contest"
export const TYPE_NOMINATION = "nomination"
export const TYPE_ENTITY = "entity"
export const TYPE_VOTE = "vote"
export const TYPE_HEADER = "header"

function scopeTypedId(type: string, id: dbid) {
    return `${type}(${id})`
}

export const scopes = {
    fromContestId: scopeTypedId.bind(null, TYPE_CONTEST),
    fromNominationId: scopeTypedId.bind(null, TYPE_NOMINATION),
    fromEntityId: scopeTypedId.bind(null, TYPE_ENTITY),
    fromJuryId: scopeTypedId.bind(null, "jury"),
    fromUserId: scopeTypedId.bind(null, "user"),
}

export function fetchFromObject(obj: any, expr: string): any {

    if (typeof obj === 'undefined') {
        return undefined
    }

    const index = expr.indexOf('.')
    if (index >= 0) {
        let prop0 = expr.substring(0, index)
        let prop1 = expr.substring(index + 1)
        if (Array.isArray(obj) && prop0 === "$") {
            return obj.map(o => fetchFromObject(o, prop1) || "").join("\n")
        } else {
            let obj2 = obj[prop0]
            if (!obj2) {
                // console.log(`FetchFromObject ${expr}, unknown prop ${prop0}`)
                return
            }
            return fetchFromObject(obj2, prop1);
        }
    }

    return obj[expr];
}


function checkEntityInQueryItem(queryItem: IQueryItem, entity: any) {
    const m = fetchFromObject(entity, queryItem.field)
    if (m === undefined)
        return false
    if (queryItem.operation) {
        if (typeof m !== "number")
            return false

        let value = Number.parseInt(queryItem.value)
        if (Number.isNaN(value))
            return false
        
        switch(queryItem.operation) {
            case ">":
                return m > value
            case "<":
                return m < value
            case "=":
                console.log(`Compare ${m} === ${value}`)
                return m === value
            case ">=":
                return m >= value
            case "<=":
                return m <= value
        }
    } else
        return m === queryItem.value
}

// TODO make private
export function checkEntityInQuery(query: IDataQuery, item: ITocItem) {
    if (query.entityQueries.length === 0)
        return true
    for (let index = 0; index < query.entityQueries.length; index++) {
        const queryItem = query.entityQueries[index]
        if (queryItem.field.startsWith("$")) {
            if (!checkEntityInQueryItem(queryItem, item))
                return false
        } else {
            if (!checkEntityInQueryItem(queryItem, item.entity))
                return false
        }
    }
    return true
}

interface IJuryVote {
    name: string
    value?: number
}

export interface IJuryVoteResult {
    [id: string]: IJuryVote | undefined
}

export interface IVoteData {
    stageId: dbid
    jury: IContestUser[]
    result: IVoteResult[]
}

export class ITocItem {
    id: dbid
    scopeId: string
    title: string
    stages: string[] | undefined
    
    $votesValue: number | undefined = undefined
    $votesPoints: number | undefined = undefined
    $voteResult: IJuryVoteResult | undefined = undefined

    constructor(public entity: IContestEntity, public index: number, nominationId: dbid) {
        this.id = entity.id
        this.title = entity.title
        this.scopeId = scopes.fromEntityId(entity.id)
        this.stages = entity.nominations && entity.nominations[nominationId]?.stages
    }
}

export class TocSection {
    items = new Array<ITocItem>()
    loaded = false
    empty = false
    nominationScope: string

    constructor(public nomination: INomination) {
        this.nominationScope = scopes.fromNominationId(nomination.id)
    }

    append(entities: IContestEntity[]) {
        this.items = [...this.items, ...entities.map((e, index) => new ITocItem(e, this.items.length + index, this.nomination.id))]
        this.loaded = true
        this.empty = this.items.length === 0
    }

    setVoteResult(voteRules: IVoteRules | undefined, voteData: IVoteData) {
        // const votes0 = { } as IVoteSubmit
        // const voteResultByJury: { [entityId: string]: IJuryVoteResult | undefined } = {  }
        
        // voteData.result.forEach(vote => {
        //     let items = vote.items[this.nominationScope]
        //     for (let entityId in items) {
        //         let prev = votes0[entityId]
        //         let value = items[entityId]
        //         if (value) {
        //             votes0[entityId] = (prev || 0) + value
        //             let juryResult = voteResultByJury[entityId]
        //             if (!juryResult) {
        //                 juryResult = {}
        //                 voteResultByJury[entityId] = juryResult
        //             }
        //             juryResult[vote.scopeId] = { name: vote.name, value }
        //         }
        //     }
        // })

        this.items.forEach( item => {
            // item.$votes = votes0[item.scopeId] || 0
            // item.$voteResult = voteResultByJury[item.scopeId]
            let votesRes = calcVotes(voteData.result, this.nominationScope, item.scopeId, voteRules)
            item.$votesValue = votesRes.value
            item.$votesPoints = votesRes.points

            // const result = voteResultByJury[item.scopeId]
            const votes = { } as IJuryVoteResult//item.$voteResult
            voteData.jury.forEach(jury => {
                let juryScopeId = scopes.fromJuryId(jury.id)
                votes[juryScopeId] = { name: jury.name, value: votesRes.values[juryScopeId] }
                // let prev = result && result[juryScopeId]
                // votes[juryScopeId] = prev || { name: jury.name }
            })
            item.$voteResult = votes
            // console.log("Row voteResult", item.$voteResult)
        })
    } 

    clearVoteResult() {
        this.items.forEach( item => {
            item.$votesValue = undefined
            item.$votesPoints = undefined
        })
    }

    filterQuery(query: IDataQuery) {
        let childs = this.items.filter( item => {
            if (!checkEntityInQuery(query, item))
                return false
            if (query.stageId) {
                if (!item.stages || item.stages.indexOf(query.stageId) < 0)
                    return false
            }
            return true
        })
        return childs.sort((a,b) => sortDocEntities(a, b, query.entitySort))
    }
}

export class TocData {
    contest: IContest
    sections: TocSection[] = []
    ids: ITocIDs

    constructor(contest: IContest, sections: TocSection[] = []) {
        this.contest = contest
        this.ids = { }
        sections.forEach(sect => this.push(sect))        
        this.ids[scopes.fromContestId(contest.id)] = contest
    }

    push(sect: TocSection) {
        if (this.sections.indexOf(sect) < 0)
            this.sections.push(sect)
        this.updateIndex(sect)
    }

    updateIndex(sect: TocSection) {
        this.ids[scopes.fromNominationId(sect.nomination.id)] = sect.nomination
        sect.items.forEach( item =>
            this.ids[item.scopeId] = item.entity
        )
    }

    getById(id: string) {
        return this.ids[id]
    }

    fetchObj(prop: string, scope: IDataScope | undefined, depends: IDataIDs | undefined) {
        let res = this.getById(prop)
        if (res)
            return res
        let value = scope && scope[prop]
        if (typeof value !== "undefined")
            return value

        let dependValue = depends && depends[prop]

        if (typeof dependValue === "string") {
            return this.getById(dependValue)
        } else 
            return dependValue
    }

    fetchExprFromDepends(expr: string, depends: IDataIDs | undefined) {
        const n = expr.indexOf('.')
        if (n < 0) {
            let res = this.getById(expr) || (depends && depends[expr])
            // !res && console.log(`Broken template expression: ${expr}`)
            return res
        }
        let obj = this.fetchObj(expr.substring(0, n), undefined, depends)
        if (!obj) {
            // console.log(`Broken template expression: ${expr}, unknown object`, depends)
            return
        }
        let prop = expr.substring(n + 1)
        let res = fetchFromObject(obj, prop)
        // !res && console.log(`Broken template expression: ${expr}, unknown prop ${prop}`, obj)
        return res
    }

    fetchExprFromScope(expr: string, scope: IDataScope) {
        const n = expr.indexOf('.')
        if (n < 0) {
            let res = this.getById(expr) || scope[expr]
            // !res && console.log(`Broken template expression: ${expr}`)
            return res
        }
        let obj = this.fetchObj(expr.substring(0, n), scope, undefined)
        if (!obj) {
            // console.log(`Broken template expression: ${expr}, unknown object`, scope, undefined)
            return
        }
        let prop = expr.substring(n + 1)
        let res = fetchFromObject(obj, prop)
        // !res && console.log(`Broken template expression: ${expr}, unknown prop ${prop}`, obj)
        return res
    }

    fetchExpr(expr: string, scope: IDataScope, depends: IDataIDs | undefined) {
        return this.fetchExprFromScope(expr, scope) || this.fetchExprFromDepends(expr, depends)
    }

    filterQuery(query: IDataQuery) {
        return (query.nominationIds.length > 0
            ? this.sections.filter( sect => query.nominationIds.indexOf(sect.nomination.id) >= 0)
            : this.sections
        ).sort((a,b) => sortDocNominations(a.nomination, b.nomination, query.nominationSort))
    }

    setVoteResult(voteData: IVoteData) {
        let voteRules = this.voteRules(voteData.stageId)
        this.sections.forEach(sect => sect.setVoteResult(voteRules, voteData))
    } 

    clearVoteResult() {
        this.sections.forEach(sect => sect.clearVoteResult())
    }

    voteRules(stageId: dbid | undefined) {
        return stageId ? this.contest.stages.find(stg => stg.id === stageId)?.voting : undefined
    }

    // build<T>(filename: string, query: IDataQuery, rules: IVoteRules, voteData: IVoteData | undefined, buildItem: (item: T | null, key: string, scope: IDataScope, depends: IDataIDs, index: number) => T | null): T[] {
    //     const result: T[] = []

    //     if (voteData) {
    //         let voteRules = this.voteRules(query)
    //         this.setVoteResult(voteRules, voteData)
    //     } else {
    //         this.clearVoteResult()
    //     }

    //     const scope = {
    //         filename
    //     } as IDataScope
            
    //     this.filterQuery(query).forEach( (sect, index) => {
    //         let entities = sect.filterQuery(query)
    
    //         const nomScope = {...scope,
    //             index: (sect.nomination.index + 1) + "",
    //             number: index + 1 + "",
    //             count: entities.length,
    //             totalCount: sect.items.length,
    //             entities
    //         }
    //         const nomDepends = {
    //             contest: scopes.fromContestId(this.contest.id),
    //             nomination: sect.nominationScope,
    //         }
    //         let nomKey = sect.nominationScope
    //         const nomItem = buildItem(null, nomKey, nomScope, nomDepends, result.length)
    //         nomItem && result.push(nomItem)
        
    //         entities.forEach( (item, index) => {
    //             let entityScope = {...scope,
    //                 index: (item.index + 1) + "",
    //                 number: index + 1 + "",    
    //                 totalVotes: voteData ? ((item.$votesValue || 0) + "") : "",
    //                 totalVotesPoints: voteData ? ((item.$votesPoints || 0) + "") : "",
    //                 votes: item.$voteResult
    //             }
    //             console.log("Build item " + item.title, entityScope)
    //             let entityDepends = {...nomDepends,
    //                 entity: item.scopeId,
    //             }
    //             let entityKey = `${nomKey}.${item.scopeId}`
    //             let entityItem = buildItem(nomItem, entityKey, entityScope, entityDepends, result.length)
    //             entityItem && result.push(entityItem)
    //         })
    //     })
    //     return result
    // }
}

export function sortDocNominations(a: INomination, b: INomination, kind?: DataNominationSortKind): number {
    switch(kind || DataNominationSortKind.INDEX_ASC) {
        case DataNominationSortKind.INDEX_ASC: 
            return a.index - b.index
        case DataNominationSortKind.INDEX_DESC: 
            return b.index - a.index
        case DataNominationSortKind.TITLE_ASC: 
            return a.title.localeCompare(b.title)
        case DataNominationSortKind.TITLE_DESC: 
            return b.title.localeCompare(a.title)
    }
}

export function sortDocEntities(a: ITocItem, b: ITocItem, kind?: DataEntitySortKind): number {
    switch(kind || DataEntitySortKind.INDEX_ASC) {
        case DataEntitySortKind.INDEX_ASC: 
            return a.index - b.index
        case DataEntitySortKind.INDEX_DESC: 
            return b.index - a.index
        case DataEntitySortKind.TITLE_ASC: 
            return a.title.localeCompare(b.title)
        case DataEntitySortKind.TITLE_DESC: 
            return b.title.localeCompare(a.title)
        case DataEntitySortKind.DATE_ASC:
            return (a.entity.created || 0) - (b.entity.created || 0)
        case DataEntitySortKind.DATE_DESC:
            return (b.entity.created || 0) - (a.entity.created || 0)
        case DataEntitySortKind.VOTE_ASC:
            return (a.$votesPoints || 0) - (b.$votesPoints || 0)
        case DataEntitySortKind.VOTE_DESC:
            return (b.$votesPoints || 0) - (a.$votesPoints || 0)
    }
}