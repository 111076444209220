import { SubmitHandler, useForm } from "react-hook-form"
import { IContestUser, IError, dbid } from "../../model/models"
import DataService from "../../services/DataService"
import { AxiosError } from "axios"
import strings from "../../strings"
import { LabelWith } from "../Label"
import { ErrorMessage } from "../ErrorMessage"
import { dbidValidation, emailValidation } from "./validations"
import { Checkbox } from "../Checkbox"
import { useState } from "react"

interface InviteUserFormProps {
    contestId: dbid
    onInvited: (contest: IContestUser) => void
    onCancel?: () => void
}

interface InviteUserFormFields {
    email: string
}

export function InviteUserForm({ contestId, onInvited, onCancel }: InviteUserFormProps) {
    const [byid, setByid] = useState(false)
    const { register, formState: { errors }, handleSubmit, setError } = useForm<InviteUserFormFields>()
    
    const onSubmit: SubmitHandler<InviteUserFormFields> = async (data) => {
        try {
            const res = await DataService.owningContest(contestId, data.email, false)
            onInvited(res.data);    
        } catch(e) {
            const err = e as AxiosError
            const message = (err.response?.data as IError)?.message || err.message
            setError("root", {
                type: "manual",
                message: message
            })
            // console.error(message)
            console.error(e)
        }
    }
    return (
        <form className='flex flex-col w-full' onSubmit={handleSubmit(onSubmit)}>
            {byid 
            ?<LabelWith text={strings.lab_userid}
                error={errors.email}>
                <input className='textinput' 
                    placeholder={strings.lab_userid}
                    {...register("email", dbidValidation(true))} />
            </LabelWith>
            :<LabelWith text={strings.lab_email}
                error={errors.email}>
                <input className='textinput' 
                    placeholder={strings.lab_email}
                    {...register("email", emailValidation(true))} />
            </LabelWith>
            }

            <Checkbox text={strings.msg_invite_user_by_id} value={byid} setValue={setByid} />

            {errors.root && 
            <ErrorMessage message={errors.root.message || strings.err_server} />
            }

            <div className='flex flex-row justify-end gap-2 pt-4'> 
                <button type='submit' 
                    className='greenbutton'>{strings.button_invite}</button>
                {onCancel &&
                <button type='reset' 
                    className='textbutton' 
                    onClick={onCancel}>{strings.button_cancel}</button>
                }
            </div>
        </form>
    )
}
