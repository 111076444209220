import strings from "../strings"
import { EmptyView } from "./Loading"

interface DescrViewProps {
    body?: string
    className?: string
    placeholder?: string
}

export function DescrView({ body, className, placeholder }: DescrViewProps) {
    const useClassName = "description " + (className || "")
    if (body)
        return (
            <div className={useClassName}
                dangerouslySetInnerHTML={{ __html: body.replaceAll("\n", "<br/>")}}
            ></div>
        )
    else 
        return (
            <EmptyView nopadding={true} 
                className={useClassName}
                text={placeholder || (strings.msg_missing + " " + strings.lab_descr.toLowerCase())}
                align="start"/>
        )
}