
interface LogoViewProps {
    className?: string
    size?: number
    disabled?: boolean
    alt?: string
    // draggable?: boolean
    // text?: string
}

export function LogoView({ className, size, disabled, alt }: LogoViewProps) {
    const width = size || 60
    var icon = disabled ? "/icon2.svg" : "/icon0.svg"
    return (//`w-[100px] ` + 
        <img src={icon} width={width} className={className} alt={alt}/>
    )
}