import { useState } from "react"
import { NominationsSortDir, useNominations } from "../../hooks/useItems"
import { IContestEntity, INomination, dbid } from "../../model/models"
import { Loading } from "../Loading"
import DataService from "../../services/DataService"
import strings from "../../strings"
import { ListUseItems } from "../ListItems"
import { TextInput, TextInputType } from "../TextInput"
import { Checkbox } from "../Checkbox"
import { Icon } from "../Icon"

interface EditNominationsContestEntityProps {
    contestId: dbid
    entityId: dbid
    nominationIds: dbid[]
    onSave: (next: IContestEntity) => void//next: INomination[]
    onCancel?: () => void
}

export function EditNominationsContestEntity({ contestId, entityId, nominationIds, onSave, onCancel }: EditNominationsContestEntityProps) {
    const [loading, setLoading] = useState(false)
    const [ ids, setIds ] = useState(nominationIds) 
    const [ filter, setFilter ] = useState("")
    const nominations = useNominations(contestId, NominationsSortDir.INDEX_ASC)

    function setNomination(n: INomination, set: boolean) {
        if (set) {
            console.log("toggleNomination set", n)
            setIds([...ids, n.id])
        } else {
            console.log("toggleNomination remove", n)
            setIds(ids.filter(id => id !== n.id))
        }
    }
    function toggleNomination(n: INomination) {
        if (n.id === undefined) {
            return
        } else if (ids.indexOf(n.id) >= 0) {
            console.log("toggleNomination remove", n)
            setIds(ids.filter(id => id !== n.id))
        } else {
            console.log("toggleNomination set", n)
            setIds([...ids, n.id])
        }
    }
    async function saveHandler() {
        setLoading(true)
        try {
            const resp = await DataService.updateContesatantNominations(contestId, entityId, ids)
            onSave(resp.data)
        } catch(e) {
            console.error(e)
            // let err = e as AxiosError            
        } finally {
            setLoading(false)
        }
    }
    // console.log("recompose edit nomintations", ids)
    // let s = filter.trim().toLocaleLowerCase()
    // let items = filter ? nominations.items.filter(m => 
    //     m.title.toLocaleLowerCase().indexOf(s.toLocaleLowerCase()) >= 0 
    // ) : nominations.items
    return (
        <div className="flex flex-col">
            
            <ListUseItems useItems={nominations}
                onFilter={ (items, s) => 
                    items.filter(m => 
                        m.title.toLocaleLowerCase().indexOf(s.toLocaleLowerCase()) >= 0 
                    )
                }
                render={(nom) =>
                    <CheckboxRow key={nom.id} text={nom.title}
                        checked={ids.indexOf(nom.id) >= 0}
                        onChange={() => toggleNomination(nom)}/>
                }/>
            {/* <TextInput className="pb-2"
                type={TextInputType.text}
                value={filter} setValue={setFilter} 
                // onChange={m => setFilteredItems(onFilter(m))}
                label={null}
                placeholder={strings.lab_search}/>
            {items.map(nom =>
                <CheckboxRow key={nom.id} text={nom.title}
                    checked={ids.indexOf(nom.id) >= 0}
                    onChange={() => toggleNomination(nom)}/>
            )} */}
            
            {loading && <Loading/>}

            <div className='flex flex-row justify-end gap-2 pt-4'>
                <button type='submit' className='greenbutton'
                    onClick={saveHandler}
                    >{strings.button_save}</button>
                {onCancel &&
                <button type='reset' className='textbutton' 
                    onClick={onCancel}>{strings.button_cancel}</button>
                }
            </div>
        </div>
    )
}

interface CheckboxRowProps {
    checked: boolean
    text: string
    onChange: () => void
}

function CheckboxRow({ checked, text, onChange }: CheckboxRowProps) {
    // console.log("REcompose ", text, checked)
    return (
        <div className="py-2 flex items-center gap-2" onClick={onChange}> 
            {/* <input type="checkbox" className="checkbox"
                checked={checked}
                onChange={onChange}
                /> */}
            <Icon name={checked ? "check_box" : "check_box_outline_blank"} className="cursor-pointer"/>
            <span>{text}</span>
        </div>
    )
}