import { useForm } from "react-hook-form"
import { LabelWith } from "../Label"
import strings from "../../strings"
import { descrValidation, passwordValidation, titleValidation } from "./validations"
import { IContest, IStage, dbid } from "../../model/models"
import { Checkbox } from "../Checkbox"
import { useState } from "react"
import { ListItems } from "../ListItems"
import { IconButton } from "../Icon"
import { StageRow } from "../rows/StageRow"
import { IDataQuery } from "../../model/query"
import { useNominationsAll, usePresentations } from "../../hooks/useItems"
import { Loading } from "../Loading"
import { DataQueryView } from "../../pages/EditPresentationPage/DataQueryView"

export interface IRegisterJuryForm {
    id?: dbid
    fullname: string
    description: string
    password: string
    stage?: dbid
    stages: dbid[]
    presentationId?: dbid
    query?: IDataQuery
    blocked?: boolean
}

interface RegisterJuryFormProps {
    contest: IContest
    title?: string
    data?: IRegisterJuryForm
    onSave: (data: IRegisterJuryForm) => void
    onClose?: () => void
}

export function RegisterJuryForm({ contest, data, onSave, onClose }: RegisterJuryFormProps) {
    const createJury = !data?.id
    const [ changePass, setChangePass ] = useState<boolean>(createJury)
    // const [ currStage, setCurrStage ] = useState<dbid>()
    const [ addStage, setAddStage ] = useState<dbid>()
    const [ presentation, setPresentation ] = useState<dbid>(data?.presentationId || "")
    const [ query, setQuery ] = useState<IDataQuery | undefined>(data?.query)
    const [ stagesIds, setStagesIds ] = useState<dbid[]>(data?.stages || [])
    const { register, formState: { errors }, handleSubmit } = useForm<IRegisterJuryForm>({ defaultValues: data })
    const presentations = usePresentations(contest.id)
    const nominations = useNominationsAll(contest.id)
    
    function addStageIdHandler() {
        if (addStage) {
            setStagesIds([...stagesIds, addStage])
            setAddStage("")
        }
    }
    const onSubmit = (data: IRegisterJuryForm) => {
        data.stages = stagesIds
        data.presentationId = presentation
        data.query = query
        onSave(data)
    } 
    return (
        <form className='flex flex-col space-y-2 mt-2 w-full'
            onSubmit={handleSubmit(onSubmit)}
            onReset={onClose}>
            <LabelWith text={strings.lab_fullname} error={errors.fullname}>
                <input className='textinput' 
                    placeholder={strings.lab_fullname}
                    {...register("fullname", titleValidation())} />
            </LabelWith>
            <LabelWith text={strings.lab_descr} error={errors.description}>
                <textarea className='textinput' 
                    rows={3}
                    placeholder={strings.lab_descr}
                    {...register("description", descrValidation())} />
            </LabelWith>
            <LabelWith text={strings.lab_presentation}>
                {presentations.loading 
                ? <Loading/>
                :<select className="combobox" 
                    // {...register("presentationId")}
                    value={presentation}
                    onChange={e => setPresentation(e.target.value)}
                    >
                    <option key="" value=""></option>
                    {presentations.items.map(pres => 
                    <option key={pres.id} value={pres.id}>{pres.name}</option>
                    )}                                        
                </select>
                }                
            </LabelWith>
            <LabelWith text={strings.lab_query}>
                <>
                <Checkbox text="Просмотр номинантов" left
                    value={!!query} 
                    setValue={next => setQuery(next ? { nominationIds: [], entityQueries: [] } as IDataQuery : undefined ) }
                    />
                { query && (
                    nominations.loading 
                    ? <Loading />
                    : <DataQueryView query={query} 
                        contest={contest}
                        nominations={nominations.items}
                        onChange={setQuery}
                        />
                    )
                }
                </>
            </LabelWith>
            <LabelWith text={strings.lab_stage}>
            <select className="combobox" 
                {...register("stage")}
                // value={currStage}
                // onChange={e => setCurrStage(e.target.value)}
                >
                    <option key="" value=""></option>
                    {contest.stages
                        .filter(stage => stagesIds.indexOf(stage.id) < 0)
                        .map(stage => 
                    <option key={stage.id} value={stage.id}>{stage.title}</option>
                    )}                                        
                </select>
            </LabelWith>
            <LabelWith text={strings.lab_stages}>
                <div className="flex flex-row items-center gap-x-4">
                    <select className="combobox" value={addStage}
                        onChange={e => setAddStage(e.target.value)}
                        >
                        <option key="" value=""></option>
                        {contest.stages
                            .filter(stage => stagesIds.indexOf(stage.id) < 0)
                            .map(stage => 
                        <option key={stage.id} value={stage.id}>{stage.title}</option>
                        )}                                        
                    </select>
                    <IconButton name="add" onClick={addStageIdHandler}
                        disabled={!addStage}/>
                </div>
                <ListItems className="px-8"
                    items={stagesIds} 
                    render={stageId => 
                        <StageItemView stageId={stageId} 
                            stages={contest.stages}
                            onDelete={() => setStagesIds([...stagesIds.filter(m => m !== stageId)])}
                            />
                    }
                    />
            </LabelWith>

            { !createJury &&
            <Checkbox value={changePass} setValue={setChangePass} text={strings.lab_edit_password}/>
            }
            {changePass && 
            <LabelWith text={strings.lab_password} error={errors.password}>
                <input className='textinput' type="password"
                    placeholder={strings.lab_password}
                    {...register("password", passwordValidation())} />
            </LabelWith>
            }
            <div className="flex flex-row justify-end gap-2">
                <button type='submit' 
                    className='greenbutton'>{data?.id ? strings.button_save : strings.button_register}</button>
                {onClose &&
                <button type="reset" 
                    className='textbutton'>{strings.button_close}</button>
                }
            </div>
            
        </form>
    )
}

interface StageItemViewProps {
    stages: IStage[]
    stageId: dbid
    onDelete?: () => void
}

function StageItemView({ stages, stageId, onDelete }: StageItemViewProps) {
    const index = stages.findIndex( m => m.id === stageId)
    if (index >= 0) {
        let stage = stages[index]
        return (
            <StageRow 
                stage={stage} 
                index={index} 
                onDelete={onDelete}/>
        )
    } else 
        return (
            <div className="flex flex-row">
                <span className="flex-auto">Удаленная стадия #{stageId}</span>
                {onDelete &&
                <IconButton name="delete" onClick={onDelete}/>
                }
            </div>
        )
}