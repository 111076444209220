import React, { useState } from "react"
import { TabsView } from "../../../comp/TabsView"
import { IDocCell, IDocCellsTemplate } from "../../../model/xlsx"
import strings from "../../../strings"
import { EditBorderTab } from "./EditBorderTab"
import { EditImageTab } from "./EditImageTab"
import { EditTextFieldTab } from "./EditTextFieldTab"
import { EditPaddingTab } from "./EditPaddingTab"
import { DependsDataContext } from "../../../model/depends"
import { IPoint, ImageScale } from "../../../model/common"
import { Label, LabelWith } from "../../../comp/Label"
import { Checkbox } from "../../../comp/Checkbox"
import { TextInput, TextInputType } from "../../../comp/TextInput"
import { EditCellsTemplateTab } from "./EditCellsTemplateTab"

interface EditDocCellFormProps {
    cell: IDocCell
    size: IPoint
    context?: DependsDataContext
    isTemplate: boolean
    onSubmit: (cell: IDocCell) => void
    onDelete?: (cell: IDocCell) => void
    onClose: () => void
}

export function EditCellForm({ cell, size, context, isTemplate, onSubmit, onDelete, onClose }: EditDocCellFormProps) {
    const [isImage, setIsImage] = useState<boolean>(() => !!cell.image)
    const [data, setData] = useState(cell)
    function typeChangeHandler(e: React.ChangeEvent<HTMLSelectElement>) {
        // e => setIsImage()
        let nextIsImage = e.target.value === "true"
        setIsImage(nextIsImage)
        if (nextIsImage) {
            setData({...data, image: { src: "", scale: ImageScale.cover }, text: undefined })
        } else {
            setData({...data, text: { text: "" }, image: undefined })
        }
    }
    
    function deleteHandler() {
        if (onDelete && global.confirm(strings.confirm_remove_object)) {
            onDelete(data)
        }
    }

    function okHandler() {
        onSubmit(data)
        onClose()
    }
    return (
        <div className="w-full flex flex-col">
            { isTemplate &&
            <EditCellsTemplateTab data={data.templ} 
                context={context}
                onChange={nextData => setData({...data, templ: nextData})}/>
            }
            <LabelWith text="Тип">
                <select className="textfield"
                    value={isImage + ""} onChange={typeChangeHandler}>
                    <option key={0} value={"false"}>{strings.lab_edit_field_text}</option>
                    <option key={1} value={"true"}>{strings.lab_edit_field_image}</option>
                </select>
            </LabelWith>
            <TabsView>
                { data.text && 
                <div title="Text">
                    <EditTextFieldTab data={data.text} 
                        context={context}
                        onChange={nextData => setData({...data, text: nextData})}/>
                </div>
                }
                { data.image && 
                <div title="Image">
                    <EditImageTab size={size}
                        data={data.image} 
                        context={context}
                        isTemplate={isTemplate}
                        onChange={nextData => setData({...data, image: nextData})}/>
                </div>
                }
                <div title="Padding">
                    <EditPaddingTab padding={data.padding} 
                        onChange={(next) => setData({...data, padding: next})}/>
                </div>
                <div title="Border" className="flex flex-col">
                    <EditBorderTab
                        border={data.border}
                        setBorder={(next) => setData({...data, border: next})}/>
                </div>
                {/* {data.templ &&
                <div title="Template">
                <EditCellsTemplateTab data={data.templ} 
                        context={context}
                        onChange={nextData => setData({...data, templ: nextData})}/>
                </div>
                }                 */}
            </TabsView>

            <div className="flex flex-row justify-end gap-x-2 pt-4">
                {onDelete &&
                <button className="redbutton" onClick={deleteHandler}>{strings.mi_delete}</button>
                }
                <div className="flex-auto"></div>
                <button className="textbutton" onClick={onClose}>{strings.button_cancel}</button>
                <button className="textbutton" onClick={okHandler}>{strings.button_save}</button>
            </div>
        </div>
    )
}