import React, { useEffect, useState } from "react"
import { useParams, useSearchParams } from "react-router-dom"
import { ErrorMessage } from "../comp/ErrorMessage"
import { IconButton } from "../comp/Icon"
import { Loading } from "../comp/Loading"
import { VoteContext } from "../context/VoteContext"
import { useTypedSelector } from "../hooks/useTypedSelector"
import { IVoteResult, IVoteRules, IVoteSubmit } from "../model/models"
import { IPresentation, ISlide } from "../model/pptx"
import DataService from "../services/DataService"
import DocService from "../services/DocService"
import VoteService from "../services/VoteService"
import strings from "../strings"
import { SlideEditor } from "./EditPresentationPage/SlideEditor"
import { readParam } from "./PresentationPage"

export function PresentationPage2() {
    const { jury } = useTypedSelector(state => state.auth)
    const presentationId = useParams().id  || jury?.presentationId || ""
    const [ loading, setLoading ] = useState<boolean>(() => true)
    const [ presentation, setPresentation ] = useState<IPresentation>()
    const [ voteRules, setVoteRules ] = useState<IVoteRules>()
    const [ voteResult, setVoteResult ] = useState<IVoteResult>()
    useEffect(() => {
        async function fetch() {
            setLoading(true)
            try {
                const resp = await DocService.getPresentation(presentationId)
                setPresentation(resp.data)
                let stageId = jury?.stage
                console.log("Jury", jury)
                if (stageId) {
                    const resp2 = await DataService.getContest(resp.data.contestId)
                    let stage = resp2.data.stages.find( stg => stg.id === stageId)
                    setVoteRules(stage?.voting)

                    const resp3 = await VoteService.getMyVote(resp.data.contestId)
                    setVoteResult(resp3.data)
                }
            } catch (e) {

            } finally {
                setLoading(false)
            }
        }
        fetch()
    }, [presentationId])
    return (
        <div className="flex flex-col">
            <div className="maincontainer">
                <span className="header">{strings.lab_presentation}</span>
            </div>
            { loading 
            ? <Loading text={strings.msg_presentation_loading} />
            : (presentation) 
            ? <PresentationShow presentation={presentation} 
                    voteRules={voteRules} voteResult={voteResult}/>
            : <ErrorMessage message={strings.err_load_presentation}/>
            }
        </div>
    )
}

const PARAM_SECT = "sect"
const PARAM_CHILD = "child"

interface PresentationShowProps {
    voteRules: IVoteRules | undefined
    voteResult: IVoteResult | undefined
    presentation: IPresentation
}

function PresentationShow({ presentation, voteRules, voteResult }: PresentationShowProps) {
    const [ searchParams, setSearchParams ] = useSearchParams()
    const [ index, setIndex ] = useState<{ slide: number, child: number }>({ 
        slide: readParam(searchParams, PARAM_SECT), 
        child: readParam(searchParams, PARAM_CHILD)
    })

    useEffect(() => 
        setSearchParams({ 
            [PARAM_SECT]: index.slide + "", 
            [PARAM_CHILD]: index.child + "" 
        }), 
        [index]
    )
    function slideClickHanler(e: React.MouseEvent) {
        e.stopPropagation()
        e.preventDefault()
        nextHandler()
    }
    function nextHandler() {
        let slide = presentation.slides[index.slide]
        if (!slide)
            return
        let nextChildN = index.child + 1
        let child = slide.childs && slide.childs[nextChildN]
        
        if (child) {
            return setIndex({...index, child: nextChildN })
        }
        let nextSlideN = index.slide + 1
        if (presentation.slides[nextSlideN]) {
            setIndex({ slide: nextSlideN, child: 0 })
        }
    }
    function prevHandler() {
        let slide = presentation.slides[index.slide]
        if (!slide)
            return
        let prevChildN = index.child - 1
        if (prevChildN >= 0 && slide.childs && slide.childs[prevChildN]) {
            return setIndex({...index, child: prevChildN })
        }
        let prevSlideN = index.slide - 1
        let prevSlide = presentation.slides[prevSlideN]
        if (prevSlide) {
            setIndex({ slide: prevSlideN, child: prevSlide.childs ? prevSlide.childs.length - 1 : 0 })
        }
    }
    function gotToHandler(id: string) {
        // toast("Goto " + id)
        var child: ISlide | undefined
        for(let i = 0; i < presentation.slides.length; i++) {
            let slide = presentation.slides[i]
            child = undefined
            if (slide.depends && Object.values(slide.depends).indexOf(id) >= 0) {
                return setIndex({ slide: i, child: 0 })
            }
            if (slide.childs) {
                for(let j = 0; j < slide.childs.length; j++) { 
                    child = slide.childs[j]
                    if (child.depends && Object.values(child.depends).indexOf(id) >= 0) {
                        return setIndex({ slide: i, child: j })
                    }
                }
            }
        }
    }
    function keyPressHandler(e: React.KeyboardEvent) {
        console.log(e.key, e.altKey)
        if (e.key === "ArrowRight" && (e.altKey || e.ctrlKey)) {
            let slide = presentation.slides[index.slide]
            if (!slide)
                return
            let nextSlideN = index.slide + 1
            if (presentation.slides[nextSlideN]) {
                setIndex({ slide: nextSlideN, child: 0 })
            }
        } else if (e.key === "ArrowLeft" && (e.altKey || e.ctrlKey)) {
            if (index.child === 0) {
                prevHandler()
            } else {
                setIndex({...index, child: 0 })
            }
        } else if (e.key === "ArrowRight") {
            nextHandler()
        } else if (e.key === "ArrowLeft") {
            prevHandler()
        }
    }

    const slide = presentation.slides[index.slide]
    const child = slide?.childs ? slide.childs[index.child] : undefined
    const current = child || slide

    let canPrev = index.slide > 0
    let canNext = presentation.slides[index.slide + 1] || (slide?.childs && slide?.childs[index.child + 1])
    return (
        <div className="flex flex-col"
            tabIndex={-1} onKeyUp={keyPressHandler} >
            <div className="flex flex-row justify-end py-2 gap-2">
                <IconButton name="arrow_back"    onClick={prevHandler} disabled={!canPrev}/>
                <IconButton name="arrow_forward" onClick={nextHandler} disabled={!canNext}/>
            </div>
            { current &&
            <div className="slidewrapper flex flex-col overflow-x-auto justify-center pb-4"
                onClick={slideClickHanler}>
                <VoteContext.Provider value={{ contestId: presentation.contestId, rules: voteRules, result: voteResult }}>
                    <SlideEditor slide={current} 
                        onGoto={gotToHandler}/>
                </VoteContext.Provider>                
            </div>
            }
        </div>
    )
}