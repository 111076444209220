import { Checkbox } from "../../../comp/Checkbox"
import { TextInput, TextInputType } from "../../../comp/TextInput"
import { DependsDataContext } from "../../../model/depends"
import { IDocCellsTemplate } from "../../../model/xlsx"

interface EditCellsTemplateTabProps {
    data?: IDocCellsTemplate
    context?: DependsDataContext
    onChange: (next: IDocCellsTemplate | undefined) => void
}

export function EditCellsTemplateTab({ data, context, onChange }: EditCellsTemplateTabProps) {
    function clickTemplHandler(e: React.MouseEvent, next: boolean) {
        if (next) {
            onChange({ expr: "" })//, prop: "" 
        } else {
            onChange(undefined)
        }
    }

    return (
        <>
        <Checkbox text="Шаблон колонок" value={!!data} onChange={clickTemplHandler} left/>
        { data && 
        <>
        <TextInput className="flex-auto"
            type={TextInputType.text}
            value={data.expr} 
            setValue={next => onChange({...data, expr: next})}
            placeholder="Значения объекта"
            />
        <TextInput className="flex-auto"
            type={TextInputType.multiline}
            value={data.keys?.join("\n") || ""} 
            setValue={next => onChange({...data, keys: next.trim() ? next.split("\n") : undefined})}
            placeholder="Список значений (пустой - все значения)"
            />
        </>
        }
        </>
    )
}