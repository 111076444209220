import { ITemplateField } from "../../model/models";
import { ComboboxOption } from "../Combobox";
import { FieldError } from "react-hook-form";
import { LabelWith } from "../Label";

interface EditEnumFieldProps {
    field:  ITemplateField
    value?: string
    error?: FieldError
    onChange: (e: any) => void
    onBlur?: () => void
}

export function EditEnumField({ value, error, onChange, onBlur, field }: EditEnumFieldProps) {
    let items = [ { text: "" } ] as ComboboxOption[]
    if (!field.required) {
        items.push({ value: null, text: "" } as ComboboxOption)
    }
    field.enums?.forEach( item => {
        items.push({ text: item } as ComboboxOption)
    })

    return (
        <LabelWith text={field.label} error={error}>
            <select className="combobox"
                value={value} onChange={onChange} onBlur={onBlur}>
                {items.map( item => 
                <option key={item.key || item.value || item.text} value={item.value || item.text}>
                    {item.text}
                </option>
                )}
            </select>
        </LabelWith>
    )
}