import { LabelWith } from "../comp/Label"
import { ListUseItems } from "../comp/ListItems"
import { MessageRow } from "../comp/rows/MessageRow"
import { useMessages } from "../hooks/useItems"
import { useTitle } from "../hooks/useTitle"
import strings from "../strings"

export default function MessagesPage() {
    useTitle(strings.nav_messages)
    const messages = useMessages(false)
    
    return (
        <div className="maincontainer flex flex-col w-full">
            <h1 className='header'>{strings.nav_messages}</h1>
            <LabelWith text="Inbox">
                <ListUseItems useItems={messages}
                    render={item =>
                        <MessageRow item={item} key={item.id}/>
                    }
                    />
            </LabelWith>
        </div>
    )
}