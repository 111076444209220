import { useState } from "react";
import { ITemplateField, PhotoSize, dbid } from "../../model/models";
import { LabelWith } from "../Label";
import { ImageEx } from "../ImageFB";
import DataService from "../../services/DataService";
import { useDragReorder } from "../../hooks/useDrag";
import { FieldError } from "react-hook-form";
import strings from "../../strings";
import { IconButton } from "../Icon";
import { useImageFullscreen } from "../forms/ImageFullscreen";
import { ImgUploader } from "../ImgUploader";
import { useDropzone } from "react-dropzone";
import { InfiniteLoading } from "../Loading";
import { OnFieldDragImage } from "./FieldValue";

interface EditImagesFieldProps {
    contestId: dbid
    entityId?: dbid
    // value?: string
    images: string[]
    error?: FieldError
    field:  ITemplateField
    className?: string
    onChange?: (items: string[]) => void
    onDragImage?: OnFieldDragImage
    onBlur?: () => void
}

export function EditImagesField({ className, images, error, field, contestId, entityId, onChange, onBlur, onDragImage }: EditImagesFieldProps) {
    const imgFullscreen = useImageFullscreen()
    const [uploading, setUploading] = useState(false)
    const dragging = useDragReorder(images, updateValues)
    const onDrop = async (acceptedFiles: File[]) => 
        sendImagesFile(acceptedFiles)
    const {getRootProps, getInputProps, isDragActive, isDragAccept} = useDropzone({onDrop, accept: {
        "image/png": [], "image/jpeg": []
    }, })

    function updateValues(nextImages: string[]) {
        onChange && onChange(nextImages)
    }
    
    async function sendImagesFile(files: File[]) {
        if (!onChange || files.length === 0) 
            return
        setUploading(true)
        try {
            let nextImages = images
            for(let file of files) {
                const resp = await DataService.uploadImage(file, contestId, entityId)
                if (resp.statusText === "OK") {
                    nextImages = [...nextImages, resp.data.orig]
                }
            }
            console.log("sendImagesFile", images, nextImages)
            updateValues(nextImages)
        } catch(e) {
            console.error(e)
        } finally {
            setUploading(false)
        }
    }

    async function filesChangeHandler(files: FileList) {
        onBlur && onBlur()
        sendImagesFile(Array.from(files))
    }
    
    function deleteHandler(itemImg: string) {
        if (global.confirm(strings.confirm_delete_photo)) {
            const nextImages = images.filter( img => img !== itemImg)
            updateValues(nextImages)
        }    
    }
    // console.log("EditImagesFile recompose ", images)
    function onDragStart(e: React.DragEvent<HTMLImageElement>, img: string) {
        onDragImage && onDragImage(field.name, img, e, "start")
        onChange && dragging.onStart(img)
    }
    function onDragEnter(e: React.DragEvent<HTMLImageElement>, img: string) {
        onDragImage && onDragImage(field.name, img, e, "enter")
        onChange && dragging.onEnter(img)
    }
    function onDragEnd(e: React.DragEvent<HTMLImageElement>, img: string) {
        onDragImage && onDragImage(field.name, img, e, "end")
        onChange && dragging.onEnd(img)
    }

    return (
        <LabelWith text={`${field.label} (${images.length})`} error={error} className={className}>
            <div {...(onChange ? getRootProps() : {})} 
                className={`w-full min-h-[100px] flex flex-row flex-wrap gap-1 ${isDragActive ? (isDragAccept ? "bg-success" : "bg-error") : ""}`}>
                {onChange && 
                <input {...getInputProps()} />
                }
                { images.map(img => 
                <div className="relative" key={img}>
                    <ImageEx key={img} className="w-24 h-24 rounded"
                        onDragStart={e => onDragStart(e, img)}
                        onDragEnter={e => onDragEnter(e, img)}
                        onDragEnd={e => onDragEnd(e, img)}
                        src={img} 
                        size={PhotoSize.small} 
                        onClick={e => { e.stopPropagation(); imgFullscreen.open(img); }}/>
                    { onChange && 
                    <IconButton className="absolute right-1 top-1" 
                        name="delete"
                        onClick={() => deleteHandler(img)}/>
                    }
                </div>
                )}
                {uploading 
                ? <div className="relative w-24 h-24 bg-white/5 select-none rounded flex flex-row justify-center items-center">
                    <InfiniteLoading className=""/>
                </div>
                : onChange && 
                <ImgUploader 
                    className="relative w-24 h-24 bg-white/5 hover:bg-secondary select-none rounded"
                    buttonClassName="text-2xl absolute-center"
                    text="+"
                    multiple
                    onUpload={filesChangeHandler}
                    />
                }
            </div>
            {imgFullscreen.render()}
        </LabelWith>
    )
}