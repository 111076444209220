import axios from "axios";
import { KEY_TOKEN } from "../store/reducers/authReducer";

let USE_API_SERVER_URL: string
if (process.env.NODE_ENV === "production") {
    // Production!
    USE_API_SERVER_URL = ""
} else {
    // USE_API_SERVER_URL = "http://127.0.0.1:8080"
    // Local web & PRODUCTION server
    USE_API_SERVER_URL = "http://cityawards.ru:8080"
}

export const IMG_URL = USE_API_SERVER_URL
export const API_URL = IMG_URL + "/api"

const api = axios.create({
    withCredentials: true,
    baseURL: API_URL
});

api.interceptors.request.use((config) => {
    let token = localStorage.getItem(KEY_TOKEN);
    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }
    return config;
});

export default api;