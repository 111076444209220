import { loginAction, registerAction } from '../store/actions/auth';
import { Loading } from '../comp/Loading';
import { ErrorMessage } from '../comp/ErrorMessage';
import { useAppDispatch, useTypedSelector } from '../hooks/useTypedSelector';
import { TabsView } from '../comp/TabsView';
import { SubmitHandler } from 'react-hook-form';
import strings from '../strings';
import { ILoginForm, LoginTab } from './views/LoginTab';
import { IRegisterForm, RegisterTab } from './views/RegisterTab';
import { useTitle } from '../hooks/useTitle';
import { JuryLoginTab } from './views/JuryLoginTab';
import { useSearchParams } from 'react-router-dom';
import { AppNameView } from '../comp/Navigation';

export function LoginForm() {
    const { loading, error } = useTypedSelector(state => state.auth)
    const [ params ] = useSearchParams()
    const juryData = params.get("jury")

    const dispatch = useAppDispatch()
    useTitle(strings.nav_login)

    const onLoginSubmit: SubmitHandler<ILoginForm> = async (data) => {
        dispatch(loginAction(data.username, data.password))
    }
    const onRegisterSubmit: SubmitHandler<IRegisterForm> = async (data) => {
        dispatch(registerAction(data.fullname, data.username, data.password))
    }
    if (loading)
        return (<Loading/>)
    return (
        <div className='w-10/12 sm:w-96 mx-8 flex flex-col bg-primary3 rounded-lg drop-shadow-xl overflow-hidden mb-10'
            >
            <div className=''
                style={{
                    height: "150px",
                    overflow: 'hidden',
                }}>
                <div className=''
                    style={{
                    marginTop: "-50px",
                    marginLeft: "-50px",
                    marginRight: "-50px",
                    height: "250px",                    
                    backgroundSize: "120px",
                    backgroundRepeat: "repeat",
                    filter: "opacity(.1)",
                    transform: 'rotate(10deg)',
                    background: "url(/icon0.svg)"
                }}>
                </div>
            </div>
            <div className='absolute bg-black/5 w-full h-[150px]'></div>
            <AppNameView className='absolute-x-center mt-[100px]'/>

            { error && <ErrorMessage message={error}/>}
            <TabsView className='mx-8 md:mx-16 mb-8 md:mb-16' initIndex={juryData ? 2 : 0}>
                <LoginTab title={strings.nav_login} 
                    onSubmit={onLoginSubmit}/>

                <RegisterTab title={strings.nav_register} 
                    onSubmit={onRegisterSubmit}/>
                {juryData && 
                <JuryLoginTab title={strings.nav_jury_login} />
                }                
            </TabsView>
        </div>
    );
}

