import { Label } from "../comp/Label";
import { ListItems } from "../comp/ListItems";
import { useTitle } from "../hooks/useTitle";
import strings from "../strings";

export default function NewsPage() {
    useTitle(strings.nav_news)
    return (
        <div className="flex flex-col maincontainer">
            <h1 className="header">{strings.nav_news}</h1>
            <Label text={strings.nav_news}></Label>
            <ListItems items={[]} 
                render={() => <></>}/>
        </div>
    )
}