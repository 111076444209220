import { useEffect, useState } from "react";
import { CreateContestEntity } from "../comp/forms/CreateContestEntity";
import { EntityState, IAttachmentFile, IContestEntity, TemplateFieldType } from "../model/models";
import { Loading } from "../comp/Loading";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AxiosError } from "axios";
import { ErrorMessage } from "../comp/ErrorMessage";
import { TextField } from "../comp/TextField";
import { Modal } from "../comp/Modal";
import strings from "../strings";
import { EditNominationsContestEntity } from "../comp/forms/EditNominationsContestEntity";
import DataService from "../services/DataService";
import { useModal } from "../hooks/useModal";
import { FieldDragImageKind, FieldValue } from "../comp/fields/FieldValue";
import NominationRow from "../comp/rows/NominationRow";
import { useContest, useNominationsFor } from "../hooks/useItems";
import { LabelWith } from "../comp/Label";
import { ListItems, ListUseItems } from "../comp/ListItems";
import paths from "../paths";
import { useTitle } from "../hooks/useTitle";
import { DescrView } from "../comp/DescrView";
import { ImgUploader } from "../comp/ImgUploader";
import { IMG_URL } from "../api";
import ContextMenuButton from "../comp/ContextMenuButton";
import { IContextMenuItem } from "../comp/ContextMenu";
import menus from "../menus";
import { BackLink } from "../comp/BackLink";
import { useDropzone } from "react-dropzone";
import { EntityHeaderView } from "./EntityForJuryPage";

export function ContestEntityPage() {
    const params = useParams()
    const contestId = params["contestId"] || "0"
    const entityId = params["id"] || "0"
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<string>()
    const { contest, loading: contestLoading, error: contestError } = useContest(contestId)
    const [ contestEntity, setContestEntity ] = useState<IContestEntity | null>(null)
    const editModal = useModal<boolean>()
    const nominateModal = useModal<boolean>()
    const textsModal = useModal<string[]>()
    const nominations = useNominationsFor(contestId, entityId)
    const onDrop = async (acceptedFiles: File[]) => 
        uploadDocHandler(acceptedFiles)
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})
    const [isDragImgActive, setDragImgActive] = useState<boolean>()

    useTitle(strings.lab_contest_entity + (contestEntity ? " - " + contestEntity.title : ""))

    async function fetchData() {
        setLoading(true)
        try {
            const resp = await DataService.getContestEntity(contestId, entityId)
            setContestEntity(resp.data)
        } catch(e) {
            console.error(e)
            const err = e as AxiosError
            setError(err.message)
        } finally {
            setLoading(false)
        }
    }
    useEffect(() => {
        fetchData()
    }, [contestId, entityId])

    async function deleteHandler() {
        if (global.confirm(strings.confirm_delete_contest_entity)) {
            try {
                const resp = await DataService.deleteContestEntity(contestId, entityId)
                if (resp.statusText === "OK") {
                    navigate(paths.contest_by_id(contestId))
                } else {
                    setError("Can't delete contestant")
                }
            } catch(e) {
                console.error(e)
                setError("Can't delete contestant")
            }
        }
    }
    async function submitHandler() {
        if (global.confirm(strings.confirm_submit_contest_entity)) {
            try {
                const resp = await DataService.submitContestEntity(contestId, entityId)
                setContestEntity(resp.data)
            } catch(e) {
                console.error(e)
                setError("Can't delete contestant")
            }
        }
    }
    async function submitResultHandler(state: EntityState) {
        var text = ""
        if (state === EntityState.APPROVED)
            text = strings.confirm_submit_contest_entity
        else if (state === EntityState.CORRECTION_REQUIRED) 
            text = strings.confirm_submit_contest_entity
        else if (state === EntityState.REJECTED) 
            text = strings.confirm_submit_contest_entity
        else return
        const message = global.prompt(text)
        // if (message === "") {
        //     global.alert(strings.alert_message_required)
        //     return
        // }
        if (message === undefined) {
            return
        }
        try {
            const resp = await DataService.submitResultContestEntity(contestId, entityId, state, message || "")
            setContestEntity(resp.data)
        } catch(e) {
            console.error(e)
            setError("Can't delete contestant")
        }
    }
    function saveHandler() {
        fetchData()
        editModal.closeModal()
    }
    function saveNominations(next: IContestEntity) {
        setContestEntity(next)
        // setNominations(next)
        nominations.reload()
        nominateModal.closeModal()
    }
    async function uploadDocHandler(files: File[]) {
        let file = files[0]
        if (!file || !contestEntity)
            return
        try {
            const resp = await DataService.uploadDoc(file, contestId, entityId)
            if (resp.statusText === "OK") {
                setContestEntity({...contestEntity, 
                    attachments: [...(contestEntity.attachments || []), resp.data] 
                })
            }
        } catch(e) {
            console.error(e)
        }
    }
    async function deleteDocHandler(file: IAttachmentFile) {
        if (!contestEntity || !global.confirm(strings.confirm_delete_doc))
            return
        try {
            const resp = await DataService.deleteDoc(file.filename, contestId, entityId)
            if (resp.statusText === "OK") {
                setContestEntity({...contestEntity, 
                    attachments: (contestEntity?.attachments || []).filter( m => m.filename !== file.filename)
                })
            }
        } catch(e) {
            console.error(e)
        }
    }
    async function processDocHandler(file: IAttachmentFile) {
        if (!contestEntity)
            return
        try {
            const resp = await DataService.processEntityAttachment(contestId, entityId, file.filename)
            if (resp.statusText === "OK") {
            //     setContestEntity({...contestEntity, 
            //         attachments: (contestEntity?.attachments || []).filter( m => m.filename !== file.filename)
                // })
            }
        } catch(e) {
            console.error(e)
        }
    }

    async function updateTextsHandler(texts: string[]) {
        if (!contestEntity)
            return
        try {
             const resp = await DataService.updateContestEntity(contestId, entityId, {
                texts: texts
             })
             if (resp.statusText === "OK") {
                setContestEntity(resp.data)
             }
        } catch(e) {
            console.error(e)
        }
    }
    function dragImageHandler(field: string, src: string, e: React.DragEvent, dragKind: FieldDragImageKind) {
        e.dataTransfer.setData(TemplateFieldType.IMAGE, JSON.stringify({ src, field }))
        canEdit && setDragImgActive(dragKind !== "end")
    }
    async function dropImageHandler(e: React.DragEvent) {
        let s = e.dataTransfer.getData(TemplateFieldType.IMAGE)
        if (!s)
            return
        let o = JSON.parse(s)
        let field = o.field
        let src = o.src
        if (!field || !src)
            return

        let filename = global.prompt("Имя файла")
        console.log(filename)
        if (filename === null)
            return
        if (!filename?.trim())
            return global.alert("Некорректное имя файла")
        let n = src.lastIndexOf(".")
        if (n > 0) {
            let ext = src.substring(n)
            filename = filename + ext
        }

        setLoading(true)
        try {
            const resp = await DataService.movePhotoToAttachmentContestEntity(contestId, entityId, field, src, filename)
            if (resp.statusText === "OK") {
                setContestEntity(resp.data)
            }
        } finally {
            setLoading(false)
        }
    }
    function onDragOverHandler(e: React.DragEvent) {
        e.dataTransfer.dropEffect = 'move'
    }

    const canEdit = contest?.isAdmin || contestEntity?.canEdit
    return (
        <div className="maincontainer w-full flex flex-col pb-big">
            <BackLink to={paths.contest_by_id(contestId, 7)}
                    text={strings.lab_contest_entities}/>
            
            { (contest && contestEntity)
            ? <>
                <h1 className="text-3xl font-thin w-full text-center pb-big">{contest.title}</h1>
                <EntityHeaderView contest={contest} entity={contestEntity} />
                <div className="flex flex-row justify-end text-disabled font-thin text-sm gap-4">
                    <span>{strings.lab_created}: {contestEntity.created ? new Date(contestEntity.created).toLocaleDateString() : "-"}</span>
                    <span>{strings.lab_edited}: {contestEntity.edited ? new Date(contestEntity.edited).toLocaleDateString() : "-"}</span>
                </div>
                
                <LabelWith text={strings.lab_descr} >
                    <DescrView body={contestEntity.description} className="textfield"/>
                </LabelWith>
                <TextField label={strings.lab_entity_state}   text={contestEntity.state + ""} />

                <LabelWith text={strings.lab_actions} >
                    <div className="flex flex-wrap gap-2 justify-end">
                        { canEdit &&
                        <button className="textbutton" 
                            onClick={() => editModal.openModal(true)}>{strings.mi_edit}</button>
                        }
                        {contest.isAdmin && 
                        <>
                        <button className="greenbutton" 
                            onClick={() => submitResultHandler(EntityState.APPROVED)}>{strings.mi_approve}</button>
                        <button className="redbutton" 
                            onClick={() => submitResultHandler(EntityState.CORRECTION_REQUIRED)}>{strings.mi_correct_required}</button>
                        <button className="redbutton" 
                            onClick={() => submitResultHandler(EntityState.REJECTED)}>{strings.mi_reject}</button>
                        </>
                        }
                        {contestEntity.canEdit && 
                        <button className="greenbutton" 
                            onClick={submitHandler}>{strings.mi_submit}</button>
                        }

                        { (contest?.isAdmin || contestEntity.canDelete) &&
                        <button className="redbutton" 
                            onClick={deleteHandler}>{strings.mi_delete}</button>
                        }
                    </div>
                </LabelWith>
                
                { contest?.template?.fields?.map(fld =>
                <FieldValue key={fld.name} 
                    contestId={contestId} entityId={contestEntity.id}
                    values={contestEntity.fields} field={fld}
                    onDragImage={dragImageHandler}
                    />
                )}

                <LabelWith text={strings.lab_nominated}>
                    <ListUseItems useItems={nominations} 
                        nobottom
                        render={item =>
                            <NominationRow key={item.id} item={item}/>
                        }
                        />                
                    { canEdit &&
                    <div className="flex flex-row justify-end">
                        <button className="textbutton" 
                            onClick={() => nominateModal.openModal(true)}>{strings.button_entity_change_nominations}</button>
                    </div>
                    }
                </LabelWith>
                
                <LabelWith text={strings.lab_attachments}
                    >
                    <div {...(canEdit ? getRootProps() : {})} 
                        className={`relative flex flex-col pb-2 pr-2 ${isDragActive ? "bg-success" : ""}`}>
                        {canEdit && 
                        <input {...getInputProps()} />
                        }
                        <ListItems items={contestEntity.attachments || []}
                            render={m => 
                                <AttachmentFileRow key={m.filename} file={m} 
                                    onDelete={canEdit ? deleteDocHandler : undefined}
                                    onProcess={canEdit ? processDocHandler : undefined}/>
                            }/>
                        { canEdit &&
                        <div className="flex flex-row justify-end gap-2">
                            <ImgUploader multiple onUpload={files => uploadDocHandler(Array.from(files))} />
                        </div>
                        }
                        {isDragImgActive && 
                        <div className="bg-success/75 absolute left-0 right-0 top-0 bottom-0"
                            onDrop={dropImageHandler}
                            onDragOver={onDragOverHandler}
                            // onDragEnter={onDragEnterHandler}
                            // onDragLeave={onDragLeaveHandler}
                            >
                        </div>
                        }
                    </div>
                </LabelWith>

                { contestEntity.texts && 
                <TextField label={strings.lab_texts_extracted}
                    multiline 
                    text={contestEntity.texts.join("\n")}
                    buttonIcon="edit"
                    buttonOnClick={() => textsModal.openModal(contestEntity.texts || [])}
                    />
                }
            </>
            : (error || contestError)
            ? <ErrorMessage message={error || contestError || ""}/> 
            : <Loading/>
            }

            { contest && contestEntity && editModal.modal &&
            <Modal title={strings.dlg_title_edit_entity} onClose={editModal.closeModal} wide>
                <CreateContestEntity item={contestEntity}
                    contestId={contestId}
                    template={contest.template}
                    onSave={saveHandler}
                    onCancel={editModal.closeModal}
                    />
            </Modal>
            }
            { contestId && nominateModal.modal && 
            <Modal title={strings.dlg_title_edit_entity_nominations} onClose={nominateModal.closeModal} >
                <EditNominationsContestEntity
                    contestId={contestId} 
                    entityId={entityId}
                    nominationIds={nominations?.items?.map( n => n.id || "0" ) || []}
                    onSave={saveNominations}
                    onCancel={nominateModal.closeModal}
                    />
            </Modal>
            }
            { textsModal.modal && 
            <Modal title={strings.lab_texts_extracted} onClose={textsModal.closeModal} wide>
                <EditTextsView 
                    texts={textsModal.modal}
                    onSave={updateTextsHandler}
                    onCancel={textsModal.closeModal}
                    />
            </Modal>
            }
        </div>
    )
}

interface EditTextsViewProps {
    texts: string[]
    onSave: (texts: string[]) => void
    onCancel: () => void
}

function EditTextsView({ texts, onSave, onCancel }: EditTextsViewProps) {
    const [ text, setText ] = useState<string>(() => texts.join("\n"))
    function saveHandler() {
        onSave(text.split("\n").map(m => m.trim()))
        onCancel()
    }
    return (
        <div className="flex flex-col">

            <textarea
                className="textinput h-96"
                placeholder=""
                value={text} 
                onChange={e => setText(e.target.value)}
                // onBlur={e => state.onBlur}
            >{text}</textarea>

            <div className='flex flex-row justify-end gap-2 pt-4'>
                <button type='submit' className='greenbutton'
                    onClick={saveHandler}
                    >{strings.button_save}</button>
                {onCancel &&
                <button type='reset' className='textbutton' 
                    onClick={onCancel}>{strings.button_cancel}</button>
                }
            </div>
        </div>
    )
}

const ATTACHMENT_FILE_MENU = [
    menus.delete,
    { id: menus.PROCESS, title: strings.mi_process_attachement } as IContextMenuItem
]

interface AttachmentFileRowProps {
    file: IAttachmentFile
    onProcess?: (file: IAttachmentFile) => void
    onDelete?: (file: IAttachmentFile) => void
}

export function AttachmentFileRow({ file, onDelete, onProcess }: AttachmentFileRowProps) {
    function onMenuHandler(mi: IContextMenuItem) {
        if (mi.id === menus.DELETE)
            onDelete && onDelete(file)
        else if (mi.id === menus.PROCESS)
            onProcess && onProcess(file)
    }
    return (
        <div key={file.filename} className="itemrow flex flex-row">
            <Link to={IMG_URL + file.url} target="_blank" className="flex-auto" 
                onClick={e => e.stopPropagation()}
                >
                {file.filename}
            </Link>
            <ContextMenuButton
                onClick={onMenuHandler}
                onVisible={mi => (mi.id === menus.DELETE && !!onDelete) || (mi.id === menus.PROCESS && !!onProcess)}
                menu={ATTACHMENT_FILE_MENU}
                />
        </div>
    )
}