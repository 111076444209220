import { Link, useNavigate } from "react-router-dom"
import { IContestEntity, dbid } from "../../model/models"
import paths from "../../paths"
import { DescrView } from "../DescrView"

interface ContestEntityRowProps {
    contestId: dbid
    forJury?: boolean
    item: IContestEntity
}

export default function ContestEntityRow({ contestId, forJury, item }: ContestEntityRowProps) {
    const link = forJury 
        ? paths.contest_entity_by_id_for_jury(item.id || "")
        : paths.contest_entity_by_id(contestId, item.id || "")
    
    return (
        <Link className="itemrow flex flex-col font-thin"
            to={link}>
            <span className="text-lg">{item.title}</span>
            {!forJury &&
            <>
            <span className="debugfield">{item.state}</span>
            <DescrView className="text-sm line-clamp-2" 
                body={item.description}/>
            </>
            }            
        </Link>
    )
}
