import { IPoint, ImageAlign } from "../model/common"

function loadUri(uri: string, onload: (img: HTMLImageElement | undefined) => void) {
    // console.log("Start crop uri")
    // let width = 200
    // let height = 400
    // let ratioDst = width / height
    var image = new Image()// document.createElement("img")
    image.crossOrigin = '*'
    image.onload = function(e) {
        onload(image)
    }
    image.onerror = function() {
        onload(undefined)
    }
    image.src = uri
}

export function coverImgToCanvas(
    image: HTMLImageElement, width: number, height: number, align: ImageAlign, pos: IPoint | undefined
) {
    let ratioDst = width / height
    let ratioImg = image.width / image.height
    // Create an empty canvas element
    var canvas = document.createElement("canvas")
    canvas.width = width
    canvas.height = height

    let dx = 0, dy = 0, dw, dh: number
    if (ratioDst > ratioImg) {
        dw = image.width
        dh = dw/ratioDst
        switch (align) {
            case ImageAlign.top:
            case ImageAlign.topLeft:
            case ImageAlign.topRight:
                dy = 0
                break
            case ImageAlign.center:
            case ImageAlign.left:
            case ImageAlign.right:
                dy = (image.height - dh) / 2
                break
            case ImageAlign.bottom:
            case ImageAlign.bottomLeft:
            case ImageAlign.bottomRight:
                dy = image.height - dh
                break
            case ImageAlign.custom: 
                let percent = (pos?.y || 0) / 100
                let percent100 = image.height - dh
                dy = percent100 * percent
                break
        }
    } else {
        dh = image.height
        dw = dh*ratioDst
        switch (align) {
            case ImageAlign.left:
            case ImageAlign.topLeft:
            case ImageAlign.bottomLeft:
                dx = 0
                break;
            case ImageAlign.top:
            case ImageAlign.center:
            case ImageAlign.bottom:
                dx = (image.width - dw) / 2
                break;
            case ImageAlign.right:
            case ImageAlign.topRight:
            case ImageAlign.bottomRight:
                dx = image.width - dw
                break
            case ImageAlign.custom: 
                let percent = (pos?.x || 0) / 100
                let percent100 = image.width - dw
                dx = percent100 * percent
                break
        }
    }

    // Copy the image contents to the canvas
    var ctx = canvas.getContext("2d")
    if (!ctx) 
        return undefined
    ctx?.drawImage(image, dx, dy, dw, dh, 0, 0, width, height)
    // onresult(canvas)
    return canvas
}

interface CoverProps {
    align?: ImageAlign
    pos?: IPoint
    resultFormat?: "image/png" | "image/jpg"
}

export function coverUri(uri: string, width: number, height: number, props?: CoverProps) {
    let promise = new Promise<string>((resolve, reject) => {
        loadUri(uri, (img) => {
            if (!img) {
                reject()
            } else {
                let canvas = coverImgToCanvas(img, width, height, props?.align || ImageAlign.center, props?.pos)//, function(canvas) {
                if (canvas)
                    resolve(canvas.toDataURL(props?.resultFormat || "image/png"))
                else 
                    reject()
                // })
            }
        })
    })
    return promise
}