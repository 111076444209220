import { UserKind, useContestUsers } from "../../hooks/useItems";
import { useModal } from "../../hooks/useModal";
import { IContest, IContestUser } from "../../model/models";
import strings from "../../strings";
import { Modal } from "../../comp/Modal";
import { InviteUserForm } from "../../comp/forms/InviteUserForm";
import { ContestUserRow } from "../../comp/rows/ContestUserRow";
import DataService from "../../services/DataService";
import { FlowUseItems } from "../../comp/ListItems";
import { SendMessageDialog } from "../../comp/forms/SendMessageForm";

interface UsersViewProps {
    contest: IContest
    kind: UserKind
    // admins: boolean
}

export function UsersView({ contest, kind }: UsersViewProps) {
    const contestId = contest.id || "none"
    const users = useContestUsers(contestId, kind)
    const inviteModal = useModal<boolean>()
    const sendMessageModal = useModal<IContestUser>()
    const isAdmins = kind === UserKind.ADMINS

    async function removeAccessHandler(user: IContestUser) {
        if (!global.confirm(strings.confirm_remove_access_for + user.name + "?"))
            return
        try {
            const resp = await DataService.owningContest(contestId, user.id, true)
            if (resp.statusText === "OK")
                users.removeItem(user)
        } catch(e) {
            console.error(e)
        }
    }


    return (
        <div className="flex flex-col pb-8">
            <div className="flex flex-row">
                { isAdmins && 
                <button className="greenbutton"
                    onClick={() => inviteModal.openModal(true)}>{strings.button_invite}</button>
                }
            </div>

            <FlowUseItems className="pt-4 gap-1"
                useItems={users} 
                render={ user =>
                    <ContestUserRow user={user} key={user.id}
                        onRemoveAccess={isAdmins ? removeAccessHandler : undefined}
                        onSendMessage={sendMessageModal.openModal}
                        />
                }/>

            {inviteModal.modal &&
            <Modal title={strings.button_invite} onClose={inviteModal.closeModal}>
                <InviteUserForm
                    contestId={contestId}
                    onInvited={newUser => { users.addItem(newUser); inviteModal.closeModal() }}
                    />            
            </Modal>
            }
            <SendMessageDialog modal={sendMessageModal} 
                contestId={contestId}
                />
        </div>
    )
}