import { ITemplateField } from "../../model/models"
import { FieldError } from "react-hook-form";
import { LabelWith } from "../Label";

interface EditTextFieldProps {
    field:  ITemplateField
    value?: string
    onChange: (e: any) => void
    onBlur?: () => void
    error?: FieldError
}

export function EditTextField({ value, error, onChange, onBlur, field }: EditTextFieldProps) {
    return (
        <LabelWith text={field.label} error={error}>
            <input className="textinput"
                placeholder={field.label}
                value={value} 
                onChange={onChange}
                onBlur={onBlur}/>
        </LabelWith>
    )
}