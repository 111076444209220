import { IconButton } from "../../comp/Icon"
import { VotePlaceItems } from "../../model/pptx"
import { TocSection } from "../../model/query"
import strings from "../../strings"
import { VoteCheckItems } from "./VoteCheckItems"

interface VoteSlideProps {
    section: TocSection
    initResult?: VotePlaceItems<number>
    places?: number[]
    onGotoSlide?: (index: number) => void
    onChange?: (section: TocSection, result: VotePlaceItems<number>) => void
    onSubmit: (section: TocSection, result: VotePlaceItems<number>) => void
}

const DEFAULT_PLACES = [1, 2, 3]

export function VoteSlide({ section, initResult, places, onGotoSlide, onChange, onSubmit }: VoteSlideProps) {
    function changeVoteHandler(result: VotePlaceItems<number>) {
        onChange && onChange(section, result)
    }
    function submitVoteHandler(result: VotePlaceItems<number>) {
        onSubmit(section, result)
    }
    return (
        <div className="flex flex-col items-center pt-8">
            <div className="absolute text-3xl text-disabled self-end px-8 right-0 bottom-0">
                {strings.lab_presentation_vote}
            </div>
            <div className="text-2xl text-center">
                {section.nomination.title}
            </div>

            <VoteCheckItems className="pt-big"
                items={section.items} 
                placeKinds={places || DEFAULT_PLACES}
                initResult={initResult}
                title={strings.msg_presentation_vote}
                onChange={changeVoteHandler}
                onSubmit={submitVoteHandler}
                renderItem={(m, index) => 
                    <div className="flex flex-row flex-auto gap-x-2 items-center">
                        <div className="flex flex-col flex-auto">
                            <span>{m.title}</span>
                            {/* <div className="text-sm line-clamp-3 text-disabled">{m.description}</div> */}
                        </div>
                        {onGotoSlide &&
                        <IconButton name="newspaper" onClick={() => onGotoSlide(index)}/>
                        }
                    </div>
                }/>
        </div>
    )
}
