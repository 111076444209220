import { Link, useNavigate } from "react-router-dom";
import { useTypedSelector } from '../hooks/useTypedSelector';
import { IUser, PhotoSize } from "../model/models";
import paths from '../paths';
import strings from '../strings';
import { ContextMenuDir, IContextMenuItem } from "./ContextMenu";
import ContextMenuButton from "./ContextMenuButton";
import { LogoView } from './LogoView';
import { AvatarView } from "./AvatarView";

const mainMenu = [
    { id: paths.home, title: strings.nav_main },
    { id: paths.news, title: strings.nav_news },
    { id: paths.admin, title: strings.nav_admin },
    { id: paths.presentation, title: strings.nav_presentation },
    { id: paths.contest_for_jury, title: strings.nav_contest },
    { id: paths.dashboard, title: strings.nav_dashboard },
    { id: paths.messages, title: strings.nav_messages },
    // { id: paths.about, title: strings.nav_about },
] as IContextMenuItem[]

export function Navigation() {
    const state = useTypedSelector(state => state.auth);
    const navigate = useNavigate()

    function onMiVisibe(mi: IContextMenuItem) {
        switch(mi.id) {
            case paths.presentation: return !!state.jury
            case paths.dashboard: return !!state.user 
            case paths.messages: return !!state.user 
            case paths.admin: return !!state.user?.isSiteAdmin
            default: return true
        }
    }

    return (
        <nav className='flex flex-col md:flex-row justify-between items-center0 py-2 px-2 md:px-big bg-primary3 drop-shadow-lg0 text-onPrimary3 gap-x-2'>
            <div className='flex items-center'>
                <Link to={paths.home} className="hidden sm:flex flex-none">
                    <LogoView/>
                </Link>
                <Link to={paths.home}>
                    <AppNameView/>
                </Link>
                <div className="flex-auto"></div>
                <LoginLink className="block md:hidden"/>
                <ContextMenuButton className="ml-2 block md:hidden transparent big"
                    icon="menu" dir={ContextMenuDir.FromRightTop}
                    onVisible={onMiVisibe}
                    menu={mainMenu}
                    onClick={mi => navigate(mi.id)}/>
            </div>

            <div className='hidden md:flex gap-x-4 flex-row flex-wrap justify-end items-center'>
                <Link to={paths.home} className='linkbutton'>{strings.nav_main}</Link>
                <Link to={paths.news} className='linkbutton'>{strings.nav_news}</Link>
                { state.user?.isSiteAdmin &&
                <Link to={paths.admin} className='linkbutton'>{strings.nav_admin}</Link>
                }
                { (state.jury?.presentationId) &&
                <Link to={paths.presentation} className='linkbutton'>{strings.nav_presentation}</Link>
                }
                { (state.jury?.query) &&
                <Link to={paths.contest_for_jury} className='linkbutton'>{strings.nav_contest}</Link>
                }
                { state.user && 
                <>
                <Link to={paths.dashboard} className='linkbutton'>{strings.nav_dashboard}</Link>
                <Link to={paths.messages} className='linkbutton'>{strings.nav_messages} {state.user?.messages || ""}</Link>
                </>
                }                
                <LoginLink className="hidden md:block"/>
                {/* <Link to={paths.about} className='linkbutton'>{strings.nav_about}</Link> */}
            </div>

        </nav>
    );
}

function LoginLink({ className }: { className?: string }) {
    const state = useTypedSelector(state => state.auth);
    if (state.user)
        return <Link to={paths.user} className={'textbutton ' + (className || "")}>
            <UserNameView user={state.user}/>
        </Link>
    else if (state.jury)
        return <Link to={paths.user} className={'textbutton ' + (className || "")}>
            <UserNameView user={state.jury}/>
        </Link>
    else
        return <Link to={paths.login} className={'textbutton ' + (className || "")}>
            {strings.nav_login}
        </Link>    
}

function UserNameView({ user }: { user: IUser }) {
    const text = user.juryContestId ? strings.lab_jury : strings.lab_user 
    return (
        <div className="flex flex-row items-center gap-2 max-w-[150px] ">
            {user.avatar &&
            <AvatarView size={PhotoSize.small} src={user.avatar} sizePx="32px" className="flex-none"/>
            }
            <div className="flex-auto flex flex-col text-end overflow-hidden">
                <span className="leading-tight line-clamp-2 font-thin">{user.fullname || user.username || user.email}</span>
                <span className="leading-none text-[10px] text-disabled">{text}</span>
            </div>
        </div>
    )
}

export function AppNameView({ className }: { className?: string }) {
    return (
        <div className={'flex flex-col sitename pl-2 sm:pl-0 ' + (className ? className : "")}>
            <span className='font-bold text-2xl self-end md:text-3xl big'>{strings.appname}</span>
            <span className='text-xs font-thin md:text-sm self-end small'
            style={{marginTop: "-6px"}}>{strings.appname2}</span>                
        </div>
    )
}