import { SubmitHandler, useForm } from "react-hook-form"
import strings from "../../strings"
import { LabelWith } from "../../comp/Label"
import { emailValidation, passwordValidation } from "../../comp/forms/validations"

export interface ILoginForm {
    username: string
    password: string
}

interface ILoginFormTabProps {
    title: string
    onSubmit: SubmitHandler<ILoginForm>
}

export function LoginTab(props: ILoginFormTabProps) {
    const { register, formState: { errors }, handleSubmit } = useForm<ILoginForm>({ defaultValues: {} as ILoginForm })
    return (
        <form title={props.title} className='flex flex-col space-y-2 mt-2 w-full'
            onSubmit={handleSubmit(props.onSubmit)}>
            <LabelWith text={strings.lab_email} error={errors.username}>
                <input className='textinput' 
                    placeholder={strings.lab_email}
                    {...register("username", emailValidation(true))} />
            </LabelWith>

            <LabelWith text={strings.lab_password} error={errors.password}>
                <input className='textinput' type="password"
                    placeholder={strings.lab_password}
                    {...register("password", passwordValidation())} />
            </LabelWith>

            <button type='submit'className='textbutton'>{strings.button_login}</button>
        </form>
    )
}