import { createContext } from "react";
import { IVoteResult, IVoteRules, dbid } from "../model/models";

// type ThemeContextType = "light" | "dark";
// const ThemeContext = createContext<ThemeContextType>("light");

// export const VoteResultContext = createContext<IVoteResult | undefined>(undefined);
// export const VoteRulesContext = createContext<IVoteRules | undefined>(undefined)
export interface IVoteContextData {
    contestId: dbid
    rules: IVoteRules | undefined
    result: IVoteResult | undefined
}
export const VoteContext = createContext<IVoteContextData | undefined>(undefined)