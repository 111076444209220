import api from "../api"
import { IContestEntity, dbid } from "../model/models"
import { IPresentation, ISlide } from "../model/pptx"
import { IPresentationResponse } from "../model/response"
import { IDocumentHeader, IXlsxDoc } from "../model/xlsx"

export default class DocService {
    static async getToc(contestId: dbid) {
        return api.get<IPresentationResponse>(`/presentations/toc/${contestId}`)
    }

    static async getTocItems(contestId: dbid, nominationId: dbid, offset: number, limit: number) {
        return api.get<IContestEntity[]>(`/presentations/toc/${contestId}/items/${nominationId}?offset=${offset}&limit=${limit}`)
    }

    static async createPresentation(presentation: IPresentation) {
        return api.post<IDocumentHeader>(`/presentations`, presentation)
    }

    static async clonePresentation(fromId: dbid, presentation: IPresentation) {
        return api.post<IDocumentHeader>(`/presentations/clone/${fromId}`, presentation)
    }

    static async updatePresentation(presentation: IPresentation) {
        return api.post(`/presentations/${presentation.id || "unknown"}`, presentation)
    }

    static async deletePresentation(presentationId: dbid) {
        return api.delete(`/presentations/${presentationId || "unknown"}`)
    }

    static async getPresentation(id: dbid) {
        return api.get<IPresentation>(`/presentations/${id}`)
    }

    static async createTable(table: IXlsxDoc) {
        return api.post<IDocumentHeader>(`/tables`, table)
    }

    static async updateTable(table: IXlsxDoc) {
        return api.post(`/tables/${table.id || "unknown"}`, table)
    }

    static async deleteTable(tableId: dbid) {
        return api.delete(`/tables/${tableId || "unknown"}`)
    }

    static async getTable(id: dbid) {
        return api.get<IXlsxDoc>(`/tables/${id}`)
    }

    static async createSlideTemplate(slide: ISlide) {
        return api.post<ISlide>(`/slide_templates`, slide)
    }

    static async updateSlideTemplate(slide: ISlide) {
        return api.post<ISlide>(`/slide_templates/${slide.id}`, slide)
    }

    static async deleteSlideTemplate(slideId: dbid) {
        return api.delete(`/slide_templates/${slideId}`)
    }

    static async getSlideTemplate(id: dbid) {
        return api.get<ISlide>(`/slide_templates/${id}`)
    }

}