import { UserKind, useContestUsers } from "../../hooks/useItems"
import { useModal } from "../../hooks/useModal"
import { IContest, IContestUser, dbid } from "../../model/models"
import strings from "../../strings"
import { IRegisterJuryForm, RegisterJuryForm } from "../../comp/forms/RegisterJuryForm"
import { ListUseItems } from "../../comp/ListItems"
import { Modal } from "../../comp/Modal"
import JuryService from "../../services/JuryService"
import { JuryRow } from "../../comp/rows/JuryRow"
import { searchInFields } from "../../extends/string"
import { useState } from "react"

interface JuryItemsViewProps {
    contest: IContest
}

export function JuryItemsView({ contest }: JuryItemsViewProps) {
    const contestId = contest.id || "none"
    const users = useContestUsers(contestId, UserKind.JURY)
    const createJuryModal = useModal<IRegisterJuryForm>()
    const [stageId, setStageId] = useState<dbid>()

    async function removeJuryHandler(user: IContestUser) {
        if (!global.confirm(strings.confirm_remove_jury + user.name + "?"))
            return
        try {
            const resp = await JuryService.deleteJuryContest(contestId, user.id)
            if (resp.statusText === "OK")
                users.removeItem(user)
        } catch(e) {
            console.error(e)
        }
    }
    async function setPasswordHandler(user: IContestUser) {
        createJuryModal.openModal({ 
            id: user.id,
            fullname: user.name, 
            description: user.description,
            password: "", 
            stages: user.stages,
            stage: user.stage,
            presentationId: user.presentationId,
            query: user.query
        } as IRegisterJuryForm)
    }
    async function onRegisterJurySubmit(data: IRegisterJuryForm) {
        createJuryModal.closeModal()
        try {
            if (data.id) {
                const user = users.items.find( m => m.id === data.id)
                if (!user)
                    return
                const resp = await JuryService.updateJuryContest(data.id, contestId, 
                    data.fullname, data.description, data.password, data.stage, data.stages, data.presentationId, data.query
                )
                users.replaceItem(user, resp.data)
            } else {
                const resp = await JuryService.createJuryContest(contestId, 
                    data.fullname, data.description, data.password, data.stage, data.stages,
                    data.presentationId, data.query
                )
                users.addItem(resp.data)
            }
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <div className="flex flex-col pb-8">
            <div className="flex flex-row justify-end">
                <button className="greenbutton"
                    onClick={() => createJuryModal.openModal({ } as IRegisterJuryForm)}
                    >{strings.button_add_jury}</button>
            </div>
            <ListUseItems className="pt-4"
                useItems={users} 
                filters={stageId}
                renderTools={ () =>
                    <select className="textfield" value={stageId} onChange={e => setStageId(e.target.value)}>
                        <option value="">Все стадии</option>
                        {contest.stages?.map( stg => 
                        <option key={stg.id} value={stg.id}>{stg.title}</option>
                        )}
                    </select>
                }
                onFilter={(items, s, stageId) => 
                    items.filter( u => 
                        searchInFields(s, u.name, u.description) && (!stageId || u.stage === stageId)
                    )
                }
                render={ user =>
                    <JuryRow key={user.id} user={user} contest={contest} 
                        onRemove={removeJuryHandler}
                        onSetPassword={setPasswordHandler}/>
                }/>

            {createJuryModal.modal &&
            <Modal title={strings.button_add_jury} onClose={createJuryModal.closeModal} wide>
                <RegisterJuryForm title="" 
                    contest={contest}
                    data={createJuryModal.modal} 
                    onSave={onRegisterJurySubmit}
                    onClose={createJuryModal.closeModal}/>                
            </Modal>
            }
        </div>
    )
}