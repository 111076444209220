import React from 'react';
import { IconButton } from './Icon';

interface ModalProps {
    title?: string
    className?: string
    fullscreen?: boolean
    wide?: boolean
    onClose: () => void
    children?: React.ReactNode
}

export function Modal(props: ModalProps) {
    // function keyUpHandler(e: React.KeyboardEvent<HTMLDivElement>) {
    //     console.log(e)
    //     if (e.key === "Escape")
    //         props.onClose()
    // }
    return (
        <div className="fixed bg-black/50 left-0 top-0 right-0 bottom-0 flex justify-center overflow-auto z-10" 
            onDoubleClick={props.onClose}
            // onKeyUp={keyUpHandler}
            // onClick={props.onClose}
            // onMouseDown={props.onClose}
            >
            <div className={'absolute p-4 rounded bg-primary flex flex-col m-5 ' + (props.fullscreen ? "" : props.wide ? "md:w-[700px] w-[350px]" : "w-[350px] md:w-[400px]")}
                onClick={e => e.stopPropagation()}
                onDoubleClick={e => e.stopPropagation()}>
                {props.title && 
                <div className='flex flex-row items-center'>
                    <h1 className='text-center pb-2 text-lg flex-auto'>{ props.title }</h1> 
                    <IconButton name='close' onClick={props.onClose}/>
                </div>
                }
                {props.children}
            </div>
        </div>
    );
}