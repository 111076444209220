import { useState } from "react"
import { Label } from "../../../comp/Label"
import { IPadding } from "../../../model/common"

interface EditPaddingTabProps {
    padding: IPadding | undefined
    onChange?: (nextPadding: IPadding) => void
}

export function EditPaddingTab({ padding, onChange }: EditPaddingTabProps) {
    const [ value, setValue ] = useState<IPadding>(padding || {})
    function setPadding(nextPadding: IPadding) {
        setValue(nextPadding)
        onChange && onChange(nextPadding)
    }
    return (
        <div title="Padding" 
            className="flex flex-row justify-center items-center p-4">
            <div className="flex flex-col">
                <Label text="Left"/>
                <input type="number" className="textfield w-[70px] "
                    value={value.left || 0} 
                    onChange={e => setPadding({...value, left: parseInt(e.target.value) || 0})}
                    />
            </div>
            <div className="flex flex-col items-center">
                <Label text="Top"/>
                <input type="number" className="textfield w-[70px] "
                        value={value.top || 0} 
                        onChange={e => setPadding({...value, top: parseInt(e.target.value) || 0})}
                        />
                <Label text="Bottom"/>
                <input type="number" className="textfield w-[70px] "
                    value={value.bottom || 0} 
                    onChange={e => setPadding({...value, bottom: parseInt(e.target.value) || 0})}
                    />
            </div>
            <div className="flex flex-col items-end">
                <Label text="Right" className="justify-end"/>
                <input type="number" className="textfield w-[70px] "
                    value={value.right || 0} 
                    onChange={e => setPadding({...value, right: parseInt(e.target.value) || 0})}
                    />
            </div>
        </div>
    )
}
