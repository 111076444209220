import { EmptyView } from "../../../../comp/Loading"
import { IVoteSubmit, dbid } from "../../../../model/models"
import { IVoteItem } from "../../../../model/pptx"
import strings from "../../../../strings"
import { VoteCheckBox } from "../../../views/VoteCheckItems"
import { preventClickPropagation } from "./VotePlacesView"

interface VoteSingleViewProps {
    items: IVoteItem[]
    votes: IVoteSubmit
    setVotes: (next: IVoteSubmit) => void
    onSubmit?: (value: IVoteSubmit) => void
}

export function VoteSingleView({ items, votes, setVotes, onSubmit }: VoteSingleViewProps) {

    function toggleVote(itemId: dbid) {
        if (votes[itemId] === undefined) {
            setVotes({ [itemId]: 1 })
        } else {
            setVotes({})
        }
    }

    return (
        
        <div className="w-full h-full flex flex-col" onClick={preventClickPropagation}>
            {items.length === 0
            ? <EmptyView/> 
            : <div className="flex-auto flex flex-col overflow-y-auto ">
            {items.map(item =>
                <div key={item.id} 
                    className="flex-none flex flex-row items-center gap-x-2 overflow-hidden w-full"
                    >
                    <VoteCheckBox className="flex-none"
                        itemId={item.id!!}
                        place={votes[item.id] !== undefined ? " " : undefined} 
                        placeKinds={[]}
                        onClick={() => toggleVote(item.id)}
                        onPlace={() => {}}/>
                    <span className="text-ellipsis line-clamp-1">{item.title}</span>
                </div>                                
            )}
            </div>
            }
            <div className="flex flex-row justify-center">
                <button disabled={!onSubmit}
                    className="greenbutton mt-8 min-w-1/2" 
                    onClick={() => onSubmit && onSubmit(votes)}>{strings.button_vote}</button>
            </div>
        </div>
    )
}