import { toast } from "react-toastify"
import menus from "../../menus"
import { IContest, IContestUser } from "../../model/models"
import paths from "../../paths"
import JuryService from "../../services/JuryService"
import strings from "../../strings"
import { ContextMenuDir, IContextMenuItem } from "../ContextMenu"
import ContextMenuButton from "../ContextMenuButton"
import { IconButton } from "../Icon"

const menu = [menus.setPassword, menus.remove]

interface JuryRowProps {
    user: IContestUser
    contest: IContest
    className?: string
    onClick?: (user: IContestUser) => void
    onRemove?: (user: IContestUser) => void
    onSetPassword?: (user: IContestUser) => void
}

export function JuryRow({ user, contest, className, onClick, onRemove, onSetPassword }: JuryRowProps) {
    function menuClickHandler(mi: IContextMenuItem) {
        switch (mi.id) {
            case menus.REMOVE: 
                onRemove && onRemove(user)
                return
            case menus.SET_PASSWORD:
                onSetPassword && onSetPassword(user)
                return
        }
    }
    function copyAccessUrlHandler() {
        let data = JuryService.encodeObject({ 
            contestId: contest.id, 
            contestName: contest.title,
            fullname: user.name,
            username: user.username || user.name
        })
        let copyUrl = global.location.origin + paths.login + `?jury=${data}`
        
        if (user.presentationId)
            copyUrl += "&redirect=" + paths.presentation
        else if (user.query)
            copyUrl += "&redirect=" + paths.contest_for_jury

        navigator.clipboard.writeText(copyUrl)
        toast(`Скопирован адрес ссылки для входа члена жюри "${user.name}"`, { 
            autoClose: 3000,
            className: "gb-success text-onError"
        });
    }
    async function copyPasswordHandler() {
        try {
            const resp = await JuryService.getJuryPassword(contest.id, user.id)
            navigator.clipboard.writeText(resp.data)
            toast(`Скопирован пароль для входа члена жюри "${user.name}"`, { 
                autoClose: 3000,
                className: "gb-success text-onError"
            });
        } catch(e) {
            console.error(e)
        }
    }

    return (
        <div className={"h-[60px] pr-2 pl-2 flex flex-row items-center gap-x-2 rounded-lg bg-primary3 select-none" + (onClick ? "itemrow " : "") + (className || "")}
            onClick={() => onClick && onClick(user)}>
            {/* <div className="h-[60px] flex flex-row items-center overflow-hidden rounded-lg">
                <AvatarView className="ml-[-15px]" sizePx="100px"
                    src={user.avatar} size={PhotoSize.medium} />
            </div> */}
            <div className="flex flex-col flex-auto">
                <div className="">{user.name}</div>
                <span className="text-sm text-disabled line-clamp-3">{user.description}</span>
                {/* <span className="text-sm text-disabled">[{user.stages.join(",")}]</span> */}
            </div>
            
            <IconButton name="share"
                alt={strings.alt_jury_copy_url}
                onClick={copyAccessUrlHandler}/>
            <IconButton name="password"
                alt={strings.alt_jury_copy_password}
                onClick={copyPasswordHandler}/>

            <ContextMenuButton menu={menu} className=""
                onClick={menuClickHandler} dir={ContextMenuDir.FromRightBottom}
                onVisible={mi => 
                    (mi.id === menus.REMOVE && !!onRemove) || (mi.id === menus.SET_PASSWORD && !!onSetPassword)
                }
                />
            {/* <ToastContainer2/> */}
        </div>
    )
}

// interface ToastContainer2Props {
//     message?: string
//     className?: string
// }

// function ToastContainer2({ className }: ToastContainer2Props) {
//     return (
//         <div className={"absolute bottom-2 " + (className ? className: "bg-success p-2 ")}>Toast test</div>
//     )
// }