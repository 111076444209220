import { IDataIDs, IDataScope, TocData } from "./query"

const START_TOKEN = /(^|[^\\]){([^}]+)}/g

export function buildTemplateString(s: string, toc: TocData, scope: IDataScope, depends: IDataIDs | undefined): string {
    let res = s.replaceAll(START_TOKEN, m => {
        let n0 = m.indexOf("{")
        if (n0 < 0)
            return m
        let pref = m.substring(0, n0)
        let expr = m.substring(n0 + 1, m.length - 1).trim()
        
        return pref + fetchExpression(expr, toc, scope, depends)
        // let index: number | undefined
        // let prop: string | undefined
        // let n1 = expr.indexOf("[")
        // if (n1 >= 0) {
        //     let n2 = expr.indexOf("]", n1)
        //     if (n2 >= 0) {
        //         prop = expr.substring(n1 + 1, n2)
        //         index = parseInt(prop)
        //         expr = expr.substring(0, n1)
        //     }
        // }
        // let res = toc.fetchExpr(expr, scope, depends)
        // // console.log(expr, res)
        // if (res) {
        //     if (index !== undefined && !Number.isNaN(index)) {
        //         if (typeof res === "string") {
        //             res = res.split("\n")[index]
        //         } else if (Array.isArray(res)) {
        //             res = res[index]
        //         }
        //     } else if (prop) {
        //         res = res[prop]
        //     }
        //     return pref + res
        // } else 
        //     return pref
    })
    return res
}

export function fetchExpression(expr: string, toc: TocData, scope: IDataScope, depends: IDataIDs | undefined): string {
    let index: number | undefined
    let prop: string | undefined
    let n1 = expr.indexOf("[")
    if (n1 >= 0) {
        let n2 = expr.indexOf("]", n1)
        if (n2 >= 0) {
            prop = expr.substring(n1 + 1, n2)
            index = parseInt(prop)
            expr = expr.substring(0, n1)
        }
    }
    let res = toc.fetchExpr(expr, scope, depends)
    // console.log(expr, res)
    if (res) {
        if (index !== undefined && !Number.isNaN(index)) {
            if (typeof res === "string") {
                res = res.split("\n")[index]
            } else if (Array.isArray(res)) {
                res = res[index]
            }
        } else if (prop) {
            res = res[prop]
        }
        return res
    } else 
        return ""
}