import { FieldError } from "react-hook-form"
import { ITemplateField, TemplateFieldType, dbid } from "../../model/models"
import { LabelWith } from "../Label"
import { EmptyView } from "../Loading"
import { EditEnumField } from "./EditEnumField"
import { EditImageField } from "./EditImageField"
import { EditImagesField } from "./EditImagesField"
import { EditTextField } from "./EditTextField"
import strings from "../../strings"

interface EditFieldProps {
    contestId: dbid
    entityId?: dbid
    field:  ITemplateField
    value?: string
    error?: FieldError
    onChange: (e: any) => void
    onBlur?: () => void
}

export function EditField({ value, error, onChange, onBlur, field, contestId, entityId }: EditFieldProps) {
    switch (field.type) {
        case TemplateFieldType.TEXT:
            return (
                <EditTextField 
                    value={value} error={error}
                    onChange={onChange} onBlur={onBlur}
                    field={field}
                    />
            )
        case TemplateFieldType.ENUM:
            return (
                <EditEnumField 
                    value={value} error={error}
                    onChange={onChange} onBlur={onBlur}
                    field={field}
                    />
            )
        case TemplateFieldType.BOOL:
            return (
                <LabelWith text={field.label}>
                    <EmptyView text="Not implemented yet!"/>
                </LabelWith>
            )            
        case TemplateFieldType.IMAGE:
            return (
                <EditImageField 
                    contestId={contestId}
                    entityId={entityId}
                    value={value} error={error}
                    onChange={onChange} onBlur={onBlur}
                    label={field.label}
                    required={field.required}/>
            )
        case TemplateFieldType.IMAGES:
            return (
                <EditImagesField 
                    contestId={contestId}
                    entityId={entityId}
                    images={value?.split("\n") || []} error={error}
                    onChange={images => onChange(images.join("\n"))} 
                    onBlur={onBlur}
                    field={field}/>                
            )
        case TemplateFieldType.FILE:
        case TemplateFieldType.FILES:
            return (
                <LabelWith text={field.label}>
                    <EmptyView text="Not implemented yet!"/>
                </LabelWith>
            )
    
        case TemplateFieldType.VIDEO:
            return (
                <LabelWith text={field.label}>
                    <EmptyView text="Not implemented yet!"/>
                </LabelWith>
            )
        default:
            return (
                <LabelWith text={field.label}>
                    <EmptyView text={`${strings.msg_unknown_field} ${field.type} name=${field.name}`}/>
                </LabelWith>
            )
    }
}