import { useState } from "react"
import { IContextMenuItem } from "./ContextMenu"
import ContextMenuButton from "./ContextMenuButton"

export interface ITagItem {
    name: string
    value: any
    text: string
    group: any
}
  
interface TagsViewProps {
    items: ITagItem[]
    className?: string
    onChange?: (items: ITagItem[]) => void
}
  
export function TagsView({ items, className, onChange }: TagsViewProps) {
    const [tags, setTags] = useState<ITagItem[]>([])

    function removeTag(tag: ITagItem) {
        let nextTags = tags.filter(item => tag !== item)
        setTags(nextTags)
        onChange && onChange(nextTags)
    }
    function addTag(tag?: ITagItem) {
        if (!tag) 
            return
        console.log("addTag", tag)
        let nextTags = [...tags.filter( m => !tag.group || m.group !== tag.group), tag]
        console.log("addTag", tag, nextTags)
        setTags(nextTags)
        onChange && onChange(nextTags)
    }
    function menuClickHandler(mi: IContextMenuItem) {
        console.log(mi)
        let ss = mi.id.split("=")
        let name = ss[0]
        let value = ss[1]
        addTag(items.find( item => item.name === name && JSON.stringify(item.value) === value))
    }
    return (
        <div className={"flex flex-row gap-2 items-center " + (className ? className : "") }>
            <div className={"flex-auto flex flex-wrap flex-row gap-2"}>
            {tags?.map( tag => 
                <TagView key={`${tag.name}=${JSON.stringify(tag.value)}`}
                    name={tag.text} 
                    onClick={() => removeTag(tag)}  />
            )}
            </div>
            <ContextMenuButton 
                menu={items.filter( item => tags.indexOf(item) < 0 ).map( item => {
                    return { id: `${item.name}=${JSON.stringify(item.value)}`, title: item.text } as IContextMenuItem
                })}
                onClick={menuClickHandler}
                />
        </div>
    )
}

interface TagViewProps {
    name: string
    className?: string
    onClick: () => void
}

function TagView({ name, className, onClick }: TagViewProps) {
    return (
        <div className={"textbutton" + (className ? className : "")} onClick={onClick}>{name}</div>
    )
}