import { useCallback, useState } from "react"
import { ContextMenu2, IContextMenu, getContextMenuPtFromEvent } from "../../../comp/ContextMenu"
import { Icon } from "../../../comp/Icon"
import useResizeObserver from "../../../hooks/useResizeObserver"
import { IDocCell, IDocRow } from "../../../model/xlsx"
import { DocCellView } from "./DocCellView"
import { IPoint } from "../../../model/common"

interface DocRowViewProps {
    row: IDocRow
    maxColumn: number
    firstRow?: boolean
    rowMenu?: IContextMenu<IDocRow>
    cellMenu?: IContextMenu<IDocCell>
    onCellDblClick?: (cell: IDocCell) => void
    onResize?: (row: IDocRow, height: number) => void
}

export function DocRowView({ 
    row, firstRow, maxColumn, rowMenu, cellMenu, onResize, onCellDblClick 
}: DocRowViewProps) {
    const [ showMenuPt, setShowMenuPt ] = useState<IPoint>()
    const onResizeCallback = useCallback((target: HTMLTableCellElement) => {
        row.height = target.offsetHeight
        // console.log("Resize row", row)
        onResize && onResize(row, target.offsetHeight)
      }, []);
    const ref = useResizeObserver(onResizeCallback)
    function contextMenuHandler(e: React.MouseEvent<HTMLTableCellElement>) {
        console.log(`contextmenu for row ${row.index}`)
        e.stopPropagation()
        e.preventDefault()
        let pt = getContextMenuPtFromEvent(e)
        setShowMenuPt(pt)
    }
    return (
        <>
        <tr >
            <td ref={ref} className="excel-td-fixed excel-other-row excel-first-col text-center box-border relative"
                style={{ height: row.height }}
                onContextMenu={contextMenuHandler}
                >
                {row.index + 1}
                {row.template &&
                <Icon name="link" size={16} className="absolute top-0 right-1 " 
                    hint="Связана с шаблоном"/>
                }
            </td>
            {row.columns.map( (cell, index) => 
            <DocCellView key={`${row}:${index}`}
                cell={cell} 
                firstCol={index === 0} 
                firstRow={firstRow}
                lastCol={index === row.columns.length - 1}
                maxColumn={maxColumn}
                menu={cellMenu}
                onDblClick={onCellDblClick}/>
            )}
        </tr>
        { rowMenu && showMenuPt && 
        <ContextMenu2 menu={rowMenu} item={row} pt={showMenuPt}
            onClose={() => setShowMenuPt(undefined)}/>
        }
        </>
    )
}

