import { useEffect, useState } from "react"
import { ColorView } from "../../../comp/ColorView"
import ContextMenuButton from "../../../comp/ContextMenuButton"
import { ToggleButton } from "../../../comp/Icon"
import { LabelWith } from "../../../comp/Label"
import { TextInput, TextInputType } from "../../../comp/TextInput"
import { AlignTextField, VerticalAlignField } from "../../../model/common"
import { DependsDataContext } from "../../../model/depends"
import { ISlideTextField, TextListKind } from "../../../model/pptx"
import strings from "../../../strings"
import { getEnum0 } from "../DataQueryView"

type ListKind = [kind: string, text: string]

const LIST_KIDS: ListKind[] = [
    ["", "Нет"],
    [TextListKind.NUMBERED, "Нумерованный"],
    [TextListKind.BULLETED, "Точки"],
    // [TextListKind.DASHED,   "Тире"],
]

interface EditTextFieldTabProps {
    data: ISlideTextField
    context?: DependsDataContext
    onChange: (nextData: ISlideTextField) => void
}

export function EditTextFieldTab({ data, context, onChange }: EditTextFieldTabProps) {
    const [ text, setText ] = useState(data.text)
    const [ fontSize, setFontSize ] = useState(data.style?.fontSize)
    const [ color, setColor ] = useState(data.style?.color)
    const [ background, setBackground ] = useState(data.style?.background)
    const [ bold, setBold ] = useState(data.style?.bold || false)
    const [ italic, setItalic ] = useState(data.style?.italic || false)
    const [ underline, setUnderline ] = useState(data.style?.underline || false)
    const [ strike, setStrike ] = useState(data.style?.strike || false)

    const [ align, setAlign ] = useState(data.align || AlignTextField.left)
    const [ verticalAlign, setVerticalAlign ] = useState(data.verticalAlign || VerticalAlignField.start)
    const [ listKind, setListKind ] = useState(data.list)

    useEffect(() => {
        function update() {
            const style = {...data.style || {}, 
                fontSize, color, background,
                bold: bold ? true : undefined,
                italic: italic ? true : undefined,
                underline: underline ? true : undefined,
                strike: strike ? true : undefined,
            }
            onChange({...data, text, align, verticalAlign, style, list: listKind })        
        }
        update()
    }, [text, fontSize, color, background, bold, italic, underline, strike, align, verticalAlign, listKind])

    function changeListKindHandler(e: React.ChangeEvent<HTMLSelectElement>) {
        let next = getEnum0(Object.values(TextListKind), e.target.value)
        setListKind(next)
    }

    return (
        <>
            <div className="flex flex-row items-center gap-2">
                <TextInput className="flex-auto"
                    type={TextInputType.multiline}
                    value={text} setValue={setText}
                    placeholder={strings.lab_body}
                    />
                { context &&
                <ContextMenuButton 
                    menu={context.toMenu(context.text)}
                    onClick={mi => setText(text + mi.id)}
                    />
                }
            </div>
            <div className="">
                <LabelWith text="Список">
                    <select className="textfield"
                        value={listKind} onChange={changeListKindHandler}
                        >
                        {LIST_KIDS.map(kind =>
                        <option key={kind[0]} value={kind[0]}>{kind[1]}</option>
                        )}
                    </select>
                </LabelWith>
            </div>
            <div className="flex flex-row justify-around ">
                <LabelWith text="Font size" className="flex flex-col">
                    <input type="number" className="textfield w-[70px] flex-auto"
                        value={fontSize} onChange={e => setFontSize(parseInt(e.target.value) || 12)}/>
                </LabelWith>
                <LabelWith text="Font color" className="flex flex-col">
                    <ColorView color={color} setColor={setColor} className="flex-auto"/>
                </LabelWith>
                <LabelWith text="Background" className="flex flex-col">
                    <ColorView color={background} setColor={setBackground} className="flex-auto"/>
                </LabelWith>
            </div>

            <div className="flex flex-row justify-center pt-4">
                <LabelWith text="Format">
                    <div className="flex flex-row gap-x-2">
                        <ToggleButton name="format_bold"
                            active={bold}
                            onClick={() => setBold(prev => !prev)}/>
                        <ToggleButton name="format_italic"
                            active={italic}
                            onClick={() => setItalic(prev => !prev)}/>
                        <ToggleButton name="format_underlined"
                            active={underline}
                            onClick={() => setUnderline(prev => !prev)}/>
                        <ToggleButton name="format_strikethrough"
                            active={strike}
                            onClick={() => setStrike(prev => !prev)}/>
                    </div>
                </LabelWith>
            </div>

            <div className="flex flex-row flex-wrap justify-around">
                <LabelWith text="Text align">
                    <div className="flex flex-row py-1 justify-center">
                        {Object.values(AlignTextField).map( align0 => 
                        <ToggleButton key={align0}
                            name={"format_align_" + align0} 
                            active={ align0 === align }
                            onClick={() => setAlign(align0)}/>
                        )}
                    </div>
                </LabelWith>

                <LabelWith text="Vertical align">
                    <div className="flex flex-row py-1 justify-center">
                        <ToggleButton name="align_vertical_top"
                            active={VerticalAlignField.start === verticalAlign}
                            onClick={() => setVerticalAlign(VerticalAlignField.start)}/>
                        <ToggleButton name="align_vertical_center"
                            active={VerticalAlignField.center === verticalAlign}
                            onClick={() => setVerticalAlign(VerticalAlignField.center)}/>
                        <ToggleButton name="align_vertical_bottom"
                            active={VerticalAlignField.end === verticalAlign}
                            onClick={() => setVerticalAlign(VerticalAlignField.end)}/>
                    </div>
                </LabelWith>
            </div>
        </>
    )
}
